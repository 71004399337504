import axios, { AxiosError } from 'axios'
import {
  AxiosMiddlewareConfig,
  createAxiosMiddleware as ramCreateAxiosMiddleware,
} from '@talentinc/redux-axios-middleware'
import { parseISO } from 'date-fns'
import { AppState } from 'store'

export interface AppErrorRespose {
  error: string
}

export interface AppErrorsRespose {
  errors: AppErrorRespose[]
}

export type APIError = AxiosError<AppErrorRespose | AppErrorsRespose>

const dateFormat = Object.freeze(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)
const commonDateKeys = Object.freeze([
  'updated_at',
  'created_at',
  'deleted_at',
  'viewed_at',
  'started_at',
  'due_at',
  'ended_at',
  'date_purchased',
  'completed_at',
  'expected_at',
  'sent',
  'due_date',
  'scheduled_start',
  'session_start',
  'session_end',
  'customer_joined',
  'expert_joined',
])

export function getApiClient() {
  return axios.create({
    baseURL: `${process.env.REACT_APP_PORTAL_API_URL}/api`,
    transformResponse: (data) => {
      // Handle no content gracefully
      if (!data) {
        return data
      }

      // Automatically cast datetime strings to Date objects to more easily work
      // with date-fns
      return JSON.parse(data, (key, value) => {
        if (
          typeof value === 'string' &&
          (commonDateKeys.includes(key) || dateFormat.test(value))
        ) {
          return parseISO(value)
        }

        return value
      })
    },
  })
}

// In here is where want to setup authentication and what not
export function getAxiosMiddlewareOptions(): AxiosMiddlewareConfig<AppState> {
  return {
    returnRejectedPromiseOnError: true,
    interceptors: {
      request: [
        // Include the Authorization header, if possible
        {
          success: ({ getState }, request) => {
            const { jwt } = getState().user.auth

            if (jwt) {
              if (!request.headers) {
                request.headers = {}
              }

              request.headers['Authorization'] = `Bearer ${jwt}`
            }

            return request
          },
        },
        // Handle ghosting
        {
          success: (_, request) => {
            return request
          },
        },
      ],
    },
  }
}

export function createAxiosMiddleware() {
  return ramCreateAxiosMiddleware(getApiClient(), getAxiosMiddlewareOptions())
}
