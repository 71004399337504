import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useAlerts from 'hooks/useAlerts'
import ConfirmationModal from 'components/shared/ConfirmationModal'
import { deleteDiscountTokenThunk } from 'store/discounts/thunk'

interface DeleteDiscountTokenProps {
  discountToken: string | null
  onClose: () => void
}
const DeleteDiscountConfirmationModal: React.FC<DeleteDiscountTokenProps> = ({
  discountToken,
  onClose,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tRef = 'components.Discounts.DiscountsTable'
  const { infoAlert, errorAlert } = useAlerts()

  const onConfirm = useCallback(async () => {
    if (discountToken) {
      const resp = await dispatch(deleteDiscountTokenThunk(discountToken))
      if (!resp) {
        infoAlert('Discount token deleted!')
        onClose()
        return
      }
      errorAlert('Failed to delete discount token')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountToken, infoAlert, errorAlert, onClose])

  const onCancel = useCallback(() => {
    onClose()
  }, [onClose])

  if (!discountToken) return null

  return (
    <ConfirmationModal
      displayText={t(`${tRef}.deleteConfirmation`)}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default DeleteDiscountConfirmationModal
