import produce from 'immer'
import { setSuccessState, setErrorState } from '@talentinc/state-utils'

import { PreloadState, initialState } from './types'
import { PreloadAction, PreloadActions } from './actions'

export default function preloadReducer(
  state: PreloadState = initialState,
  action: PreloadAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case PreloadActions.PRELOAD_FINISHED:
        draft.preloadStatus = setSuccessState(state.preloadStatus)
        break
      case PreloadActions.PRELOAD_FAILED:
        draft.preloadStatus = setErrorState(state.preloadStatus, action.error)
        break
      case PreloadActions.PAT_PRELOAD_FINISHED:
        draft.preloadStatus = setSuccessState(state.patPreloadStatus)
        break
      case PreloadActions.PAT_PRELOAD_FAILED:
        draft.patPreloadStatus = setErrorState(
          state.patPreloadStatus,
          action.error
        )
        break
    }
  })
}
