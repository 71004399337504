import React, { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import DiscountsTable from './DiscountsTable'
import DiscountModalContainer from './DiscountModalContainer'

import { AppState } from 'store'
import { DiscountToken } from 'store/discounts/types'
import {
  fetchDiscounts,
  fetchDiscountUsageStats,
} from 'store/discounts/actions'
import {
  allDiscounts,
  discountsNeedLoading,
  usageStatsLoaded,
} from 'store/discounts/selectors'
import DeleteDiscountConfirmationModal from './DeleteDiscountConfirmationModal'
import AddUserTokenModalContainer from './AddUserTokenModalContainer'
import TableWrapper from 'components/shared/TableWrapper'

const DiscountsContainer: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'components.Discounts.DiscountsTable'

  const [formOpen, setFormOpen] = useState(false)
  const [selectedDiscointToken, setSelectedDiscointToken] = useState<
    string | null
  >(null)
  const [discountToDelete, setDiscountToDelete] = useState<string | null>(null)
  const [discountToAddUser, setDiscountToAddUser] = useState<string | null>(
    null
  )

  const discountsLoaded = useSelector<AppState, boolean>((state) =>
    discountsNeedLoading(state.discounts)
  )

  const discounts = useSelector<AppState, DiscountToken[]>(
    (state) => allDiscounts(state.discounts),
    isEqual
  )

  const statsLoaded = useSelector<AppState, boolean>((state) =>
    usageStatsLoaded(state.discounts)
  )

  const showLoader = !discountsLoaded || !statsLoaded

  const closeForm = useCallback(async () => {
    setFormOpen(false)
    setSelectedDiscointToken(null)
  }, [])

  const onAddClick = useCallback(async () => {
    setFormOpen(true)
  }, [])

  const onEditClick = useCallback(async (token: string) => {
    setSelectedDiscointToken(token)
    setFormOpen(true)
  }, [])

  const onDeleteClick = useCallback(async (token: string) => {
    setDiscountToDelete(token)
  }, [])

  const onDeleteModalClose = useCallback(async () => {
    setDiscountToDelete(null)
  }, [])

  const onAddUserTokenClick = useCallback(async (token: string) => {
    setDiscountToAddUser(token)
  }, [])

  const onAddUserTokenModalClose = useCallback(async () => {
    setDiscountToAddUser(null)
  }, [])

  useEffect(() => {
    if (!discountsLoaded) {
      dispatch(fetchDiscounts())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [discountsLoaded])

  useEffect(() => {
    if (!statsLoaded) {
      dispatch(fetchDiscountUsageStats())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [statsLoaded])

  return (
    <>
      <TableWrapper
        isLoading={showLoader}
        onAddClick={onAddClick}
        title={t(`${tRef}.title`)}
      >
        <>
          {discounts.length > 0 && (
            <DiscountsTable
              discounts={discounts}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onAddUserTokenClick={onAddUserTokenClick}
            />
          )}
          {discounts.length === 0 && (
            <Container className={classes.emptyContainer}>
              <Typography variant="body2">{t(`${tRef}.empty`)}</Typography>
            </Container>
          )}
        </>
      </TableWrapper>
      <DiscountModalContainer
        open={formOpen}
        discountToken={selectedDiscointToken}
        onClose={closeForm}
      />
      <DeleteDiscountConfirmationModal
        discountToken={discountToDelete}
        onClose={onDeleteModalClose}
      />
      <AddUserTokenModalContainer
        discountToken={discountToAddUser}
        onClose={onAddUserTokenModalClose}
      />
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem',
    },
    sectionHeader: {
      marginLeft: '1rem',
      flexBasis: '80%',
    },
    container: {
      paddingBottom: '.5rem',
      paddingTop: '0.5rem',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

export default DiscountsContainer
