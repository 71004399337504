import produce from 'immer'
import jwtDecode from 'jwt-decode'
import * as stateUtils from '@talentinc/state-utils'

import { UserState, initialUserState } from './types'
import { UserAction, UserActions } from './actions'

function userReducer(
  state: UserState = initialUserState,
  action: UserAction
): UserState {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserActions.LOGIN_SUCCESS:
        draft.auth.loggedIn = true
        draft.auth.checkingSession = stateUtils.setSuccessState(
          state.auth.checkingSession
        )
        draft.auth.identity = action.idTokenPayload
        draft.auth.jwt = action.jwt
        break

      case UserActions.LOGIN_FAIL:
        draft.auth.error = action.error
        break

      case UserActions.CHECKING_SESSION:
        draft.auth.checkingSession = action.checking
          ? stateUtils.setStartState(state.auth.checkingSession)
          : stateUtils.setSuccessState(state.auth.checkingSession)
        break

      case UserActions.LOGOUT:
        draft.auth.loggedIn = false
        draft.auth.checkingSession = stateUtils.setSuccessState(
          state.auth.checkingSession
        )
        draft.auth.identity = null
        draft.auth.jwt = null
        break

      case UserActions.FETCH_USER:
      case UserActions.UPDATE_USER:
      case UserActions.SIGN_UP:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break

      case UserActions.FETCH_USER_SUCCESS:
      case UserActions.UPDATE_USER_SUCCESS:
      case UserActions.SIGN_UP_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.currentUser = action.payload.data
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        break
      }

      case UserActions.FETCH_USER_FAIL:
      case UserActions.UPDATE_USER_FAIL:
      case UserActions.SIGN_UP_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case UserActions.PASSWORD_GRANT_LOGIN_SUCCESS:
        draft.auth.loggedIn = true
        draft.auth.checkingSession = stateUtils.setSuccessState(
          state.auth.checkingSession
        )
        draft.auth.jwt = action.payload.data.id_token
        draft.auth.identity = jwtDecode(action.payload.data.id_token)
        break
    }
  })
}

export default userReducer
