import produce from 'immer'

import { ConfigState, initialConfigState } from './types'
import { ConfigAction, ConfigActions } from './actions'

function configReducer(
  state: ConfigState = initialConfigState,
  action: ConfigAction
): ConfigState {
  return produce(state, (draft) => {
    switch (action.type) {
      case ConfigActions.FETCH_CONFIG_SUCCESS:
      case ConfigActions.UPDATE_CONFIG_SUCCESS:
        draft.config = action.payload.data

        if (action.type === ConfigActions.FETCH_CONFIG_SUCCESS) {
          draft.runningVersion = action.payload.data.api_version
        } else {
          draft.needsUpdate =
            state.runningVersion !== action.payload.data.api_version
        }
        break
    }
  })
}

export default configReducer
