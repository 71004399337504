import { useSelector } from 'react-redux'

import { AppState } from 'store'
import { User } from 'store/user/types'

function useUser() {
  const user = useSelector<AppState, Partial<User>>(
    (state) => state.user.currentUser || {}
  )
  // When dealing with timezone in this app, use the timezone provided by the
  // browser in all cases, falling back to the one on the user record, which
  // isn't very accurate, but it's the only thing available for very old
  // browsers, falling back to NYC time if that isn't available.
  const timezone =
    Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone ||
    'America/New_York'

  return {
    ...user,
    timezone,
  }
}

export default useUser
