import { Theme, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { colors } from 'styles'

const ErrorToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: colors.red[600],
    color: colors.white[100],
    boxShadow: theme.shadows[1],
    fontSize: '0.9rem',
  },
}))(Tooltip)

export default ErrorToolTip
