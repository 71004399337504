import produce from 'immer'
import * as stateUtils from '@talentinc/state-utils'
import { BrandState, initialBrandState } from './types'
import { BrandAction, BrandActions } from './actions'

export default function brandsReducer(
  state: BrandState = initialBrandState,
  action: BrandAction
): BrandState {
  return produce(state, (draft) => {
    switch (action.type) {
      case BrandActions.FETCH_MAILCAMP_SEQ:
      case BrandActions.FETCH_BRANDS:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break
      case BrandActions.FETCH_MAILCAMP_SEQ_FAIL:
      case BrandActions.FETCH_BRANDS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }
      case BrandActions.FETCH_BRANDS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((brand) => {
          draft.brands[brand.site_id] = brand
        })
        break
      }
      case BrandActions.FETCH_MAILCAMP_SEQ_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((seq) => {
          draft.mailCampaignSequences[seq.id] = seq
        })
        break
      }
    }
  })
}
