import { HashMap } from '@talentinc/state-utils'
import {
  ActionTypes,
  AudienceTypes,
  Behavior,
  Campaign,
  CampaignDeliveryStat,
  CampaignPayload,
  CampaignSendStat,
  CampaignStatues,
  Cohort,
  CohortPayload,
  CohortSources,
  EngagementTypes,
  EventSystems,
  ExclusionList,
  Promo,
  PromoFilterFields,
  PromoState,
  PromoStatuses,
  PromoWithCohortsAndCampaigns,
  UserListEstimate,
} from './types'
import { format as dateFormat } from 'date-fns'

export const promos = (state: PromoState): Promo[] =>
  Object.values(state.promos).sort((a, b) => b.id - a.id)

export const promoStatus = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return null
  if (!state.promos[promoID]) return null
  if (!state.promos[promoID].statuses) return null

  //filter out Campaign Approvals and Cancellations from list
  const statuses = state.promos[promoID].statuses
    .slice()
    .filter(
      (status) =>
        status.status !== CampaignStatues.Cancelled &&
        status.status !== CampaignStatues.Approved
    )
  if (statuses.length === 0) return null

  const pending = statuses.filter((status) => !status.ended_at)
  const completed = statuses.filter((status) => status.ended_at)

  //if there are no pending statuses return the last completed status
  if (pending.length === 0) {
    return completed.sort((a, b) =>
      a.ended_at && b.ended_at
        ? b.ended_at?.getTime() - a.ended_at?.getTime()
        : 0
    )[0]
  }

  //ohterwise return the status that was started last
  return pending.sort((a, b) => {
    const aDate = a.started_at || a.created_at
    const bDate = b.started_at || b.created_at
    return bDate.getTime() - aDate.getTime()
  })[0]
}

export const promoIsApproved = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return false
  if (!state.promos[promoID]) return false
  if (!state.promos[promoID].statuses) return false
  return (
    state.promos[promoID].statuses.filter(
      (s) => s.status === PromoStatuses.Approved
    ).length > 0
  )
}

export const promoIsCancelled = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return false
  if (!state.promos[promoID]) return false
  if (!state.promos[promoID].statuses) return false
  return (
    state.promos[promoID].statuses.filter(
      (s) => s.status === PromoStatuses.Cancelled
    ).length > 0
  )
}

export const canAddCampaignsToPromo = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return false
  if (!state.promos[promoID]) return false
  if (!state.promos[promoID].statuses) return false
  return (
    state.promos[promoID].statuses.filter(
      (s) => s.status === PromoStatuses.Cancelled
    ).length === 0
  )
}

export const promosNeedLoading = (state: PromoState) =>
  !state.meta.FETCH_PROMOS.loaded

export const promosLoaded = (state: PromoState) =>
  state.meta.FETCH_PROMOS.loaded

function payloadToCohort(payload: CohortPayload): Cohort {
  const cohort = {
    cohortID: payload.id,
    behaviors: [],
    source: CohortSources.Database,
    name: payload.name,
  } as Cohort
  payload.filters.forEach((filter) => {
    switch (filter.type) {
      case EngagementTypes.CustomerCreated:
        cohort.audienceType = AudienceTypes.Customer
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.StartDate:
              if (!cohort.startDate && setting.value) {
                cohort.startDate = new Date(setting.value)
              }
              break
            case PromoFilterFields.EndDate:
              if (!cohort.endDate && setting.value) {
                cohort.endDate = new Date(setting.value)
              }
              break
          }
        })
        break
      case EngagementTypes.LeadCreated:
        if (!cohort.audienceType) {
          cohort.audienceType = AudienceTypes.Lead
          filter.settings.forEach((setting) => {
            switch (setting.field) {
              case PromoFilterFields.StartDate:
                if (!cohort.startDate && setting.value) {
                  cohort.startDate = new Date(setting.value)
                }
                break
              case PromoFilterFields.EndDate:
                if (!cohort.endDate && setting.value) {
                  cohort.endDate = new Date(setting.value)
                }
                break
            }
          })
        }
        break
      case EngagementTypes.CSVUpload:
        cohort.source = CohortSources.CSV
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.S3Location:
              if (setting.value) {
                cohort.s3Location = setting.value
              }
              break
          }
        })
        break
      case EngagementTypes.ExcludePurchasers:
        cohort.excludePurchasers = filter.include
        break
      case EngagementTypes.MailCampaignSequence:
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.MailCampaignSequenceID:
              if (setting.value) {
                cohort.mailCampaignSequence = Number(setting.value)
              }
          }
        })
        break
      case EngagementTypes.LeadScore:
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.MinLeadScore:
              if (setting.value) {
                cohort.leadScoreStart = Number(setting.value)
              }
              break
            case PromoFilterFields.MaxLeadScore:
              if (setting.value) {
                cohort.leadScoreEnd = Number(setting.value)
              }
              break
          }
        })
        break
      case EngagementTypes.DeliveredPromoEmail:
      case EngagementTypes.ClickedPromoEmail:
      case EngagementTypes.OpenedPromoEmail: {
        const behavior: Behavior = {
          behaviorID: filter.id,
          actionType: filter.include ? ActionTypes.Did : ActionTypes.DidNot,
          engagementType: filter.type,
        }
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.PromoID:
              if (setting.value) {
                behavior.engagementKey = setting.value
              }
              break
          }
        })
        cohort.behaviors.push(behavior)
        break
      }
      case EngagementTypes.AramisEvent: {
        const behavior: Behavior = {
          behaviorID: filter.id,
          actionType: filter.include ? ActionTypes.Did : ActionTypes.DidNot,
          engagementType: filter.type,
        }
        filter.settings.forEach((setting) => {
          switch (setting.field) {
            case PromoFilterFields.EventTypeID:
              if (setting.value) {
                behavior.engagementKey = setting.value
              }
              break
            case PromoFilterFields.System:
              if (setting.value) {
                behavior.system = setting.value as EventSystems
              }
              break
            case PromoFilterFields.StartDate:
              if (setting.value) {
                behavior.startDate = new Date(setting.value)
              }
              break
            case PromoFilterFields.EndDate:
              if (setting.value) {
                behavior.endDate = new Date(setting.value)
              }
              break
          }
        })
        cohort.behaviors.push(behavior)
        break
      }
    }
  })
  return cohort
}

function payloadToCampaign(payload: CampaignPayload): Campaign {
  return {
    campaignID: payload.id,
    emailTemplateID: payload.templates[0]?.email_template_id,
    sendAtDate: payload.send_at
      ? dateFormat(payload.send_at, 'yyyy-MM-dd')
      : '',
    sendAtTime: payload.send_at ? dateFormat(payload.send_at, 'HH:mm') : '',
    sendAtTimezone: '',
    statuses: payload.statuses,
    templates: payload.templates,
  } as Campaign
}

export const promoWithCohortsAndCampaigns = (
  state: PromoState,
  promoID: number | null
): PromoWithCohortsAndCampaigns | null => {
  if (!promoID) return null
  return {
    ...state.promos[promoID],
    cohorts: promoCohorts(state, promoID),
    campaigns: promoCampaigns(state, promoID),
  }
}

export const promoCohorts = (state: PromoState, promoID: number) => {
  if (!state.meta.FETCH_PROMOS.loaded || !state.cohortsByPromoID[promoID])
    return []
  return Array.from(state.cohortsByPromoID[promoID]).map((cID) =>
    payloadToCohort(state.cohorts[cID])
  )
}

export const cohortsLoaded = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return false
  if (!state.meta.FETCH_PROMO_COHORTS[promoID]) return false
  return state.meta.FETCH_PROMO_COHORTS[promoID].loaded
}

export const cohortsLoadedOnce = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID || !state.meta.FETCH_PROMO_COHORTS[promoID]) return false
  return (
    !!state.cohortsByPromoID[promoID] ||
    state.meta.FETCH_PROMO_COHORTS[promoID].loaded
  )
}

export const cohortsNeedLoading = (
  state: PromoState,
  promoID: number | null
) => {
  if (!promoID) return false
  if (!state.meta.FETCH_PROMOS.loaded) return false
  return (
    !state.cohortsByPromoID[promoID] ||
    state.cohortsByPromoID[promoID].size === 0
  )
}

export const cohortByID = (state: PromoState, cohortID: number | null) => {
  if (!cohortID) return null
  return state.cohorts[cohortID]
}

export const emailTemplatesNeedLoading = (state: PromoState) =>
  !state.meta.FETCH_EMAIL_TEMPLATES.loaded

export const emailTemplates = (state: PromoState) =>
  Object.values(state.emailTemplates)

const activeCampaigns = (state: PromoState, promoID: number) => {
  if (!state.campaignsByPromoID[promoID]) return []
  return Array.from(state.campaignsByPromoID[promoID])
    .map((cID) => state.campaigns[cID])
    .filter(
      (campaign) =>
        !campaign.statuses ||
        (campaign.statuses &&
          campaign.statuses.filter(
            (s) => s.status === CampaignStatues.Cancelled
          ).length === 0)
    )
}

const approvedCampaigns = (state: PromoState, promoID: number) => {
  if (!state.campaignsByPromoID[promoID]) return []
  return Array.from(state.campaignsByPromoID[promoID])
    .map((cID) => state.campaigns[cID])
    .filter(
      (campaign) =>
        !campaign.statuses ||
        (campaign.statuses &&
          campaign.statuses.filter(
            (s) => s.status === CampaignStatues.Cancelled
          ).length === 0 &&
          campaign.statuses &&
          campaign.statuses.filter((s) => s.status === CampaignStatues.Approved)
            .length > 0)
    )
}

export const promoCampaigns = (
  state: PromoState,
  promoID: number
): Campaign[] => {
  if (
    !state.meta.FETCH_CAMPAIGNS[promoID] ||
    !state.campaignsByPromoID[promoID]
  )
    return []
  return activeCampaigns(state, promoID).map((campaign) =>
    payloadToCampaign(campaign)
  )
}

export const campaignsNeedingApproval = (
  state: PromoState,
  promoID: number
) => {
  if (
    !state.meta.FETCH_CAMPAIGNS[promoID] ||
    !state.campaignsByPromoID[promoID]
  )
    return []
  return Array.from(state.campaignsByPromoID[promoID])
    .map((cID) => state.campaigns[cID])
    .filter(
      (campaign) =>
        !campaign.statuses ||
        campaign.statuses.length === 0 ||
        (campaign.statuses &&
          campaign.statuses.filter(
            (s) =>
              s.status === CampaignStatues.Cancelled ||
              s.status === CampaignStatues.Approved
          ).length === 0)
    )
}

export const campaignsLoaded = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID) return false
  if (!state.meta.FETCH_CAMPAIGNS[promoID]) return false
  return state.meta.FETCH_CAMPAIGNS[promoID].loaded
}

export const campaignsLoadedOnce = (
  state: PromoState,
  promoID: number | null | undefined
) => {
  if (!promoID || !state.meta.FETCH_CAMPAIGNS[promoID]) return false
  return (
    !!state.campaignsByPromoID[promoID] ||
    state.meta.FETCH_CAMPAIGNS[promoID].loaded
  )
}

export const campaignsNeedLoading = (
  state: PromoState,
  promoID: number | null
) => {
  if (!promoID) return false
  if (!state.meta.FETCH_PROMOS.loaded) return false
  return (
    !state.campaignsByPromoID[promoID] ||
    state.campaignsByPromoID[promoID].size === 0
  )
}

export const campaignIsApproved = (
  state: PromoState,
  campaignID: number | null | undefined
) => {
  if (!campaignID) return false
  if (!state.campaigns[campaignID]) return false
  if (!state.campaigns[campaignID].statuses) return false
  const statuses = state.campaigns[campaignID].statuses
  if (statuses) {
    return (
      statuses.filter((s) => s.status === CampaignStatues.Approved).length > 0
    )
  }
  return false
}

export const campaignApprovalsByID = (state: PromoState, promoID: number) => {
  const approvals: HashMap<boolean> = {}
  const campaignsSet = state.campaignsByPromoID[promoID]
  if (campaignsSet) {
    Array.from(campaignsSet).forEach((campaignID) => {
      approvals[campaignID] = campaignIsApproved(state, campaignID)
    })
  }

  return approvals
}

export const nextCampaignDate = (
  state: PromoState,
  promoID: number
): CampaignPayload | null => {
  const upcommingCampaigns = activeCampaigns(state, promoID)
    .filter(
      (campaign) =>
        campaign.send_at && campaign.send_at.getTime() > new Date().getTime()
    )
    .sort((a, b) => {
      if (a.send_at && b.send_at) {
        return a.send_at.getTime() - b.send_at.getTime()
      }
      return 0
    })
  if (upcommingCampaigns.length === 0) return null
  return upcommingCampaigns[0]
}

export const lastCampaignDate = (
  state: PromoState,
  promoID: number
): CampaignPayload | null => {
  const pastOrCurrentCampaigns = approvedCampaigns(state, promoID)
    .filter(
      (campaign) =>
        campaign.send_at && campaign.send_at.getTime() <= new Date().getTime()
    )
    .sort((a, b) => {
      if (a.send_at && b.send_at) {
        return b.send_at.getTime() - a.send_at.getTime()
      }
      return 0
    })
  if (pastOrCurrentCampaigns.length === 0) return null
  return pastOrCurrentCampaigns[0]
}

export const approvedPromos = (state: PromoState): Promo[] =>
  Object.values(state.promos).filter(
    (promo) =>
      promo.statuses &&
      promo.statuses.filter(
        (status) => status.status === PromoStatuses.Cancelled
      ).length === 0 &&
      promo.statuses.filter(
        (status) => status.status === PromoStatuses.Approved
      ).length > 0
  )

export const runningCampaignIDsForPromo = (
  state: PromoState,
  promoID: number
): number[] => {
  const hoursAgo24 = new Date()
  hoursAgo24.setDate(hoursAgo24.getDate() - 1)
  const hoursAhead24 = new Date()
  hoursAhead24.setDate(hoursAhead24.getDate() + 1)
  return activeCampaigns(state, promoID)
    .filter(
      (campaign) =>
        campaign.send_at &&
        campaign.send_at.getTime() > hoursAgo24.getTime() &&
        campaign.send_at.getTime() < hoursAhead24.getTime()
    )
    .map((campaign) => campaign.id)
}

export const latestCampaignIDsForPromo = (
  state: PromoState,
  promoID: number
): number[] => {
  const hoursAgo24 = new Date()
  hoursAgo24.setDate(hoursAgo24.getDate() - 1)
  const hoursAhead24 = new Date()
  hoursAhead24.setDate(hoursAhead24.getDate() + 1)
  const active = activeCampaigns(state, promoID)
    .filter(
      (campaign) =>
        campaign.send_at &&
        campaign.send_at.getTime() > hoursAgo24.getTime() &&
        campaign.send_at.getTime() < hoursAhead24.getTime()
    )
    .map((campaign) => campaign.id)
  const latest = lastCampaignDate(state, promoID)
  if (active.length > 0) return active
  return latest ? [latest.id] : []
}

export const currentSendStatsByPromo = (
  state: PromoState,
  promoID: number
): CampaignSendStat[] =>
  runningCampaignIDsForPromo(state, promoID).map((cID) => {
    let templateName = ''
    const campaign = state.campaigns[cID]
    if (campaign && campaign.templates.length > 0) {
      templateName =
        state.emailTemplates[campaign.templates[0].email_template_id]
          ?.template || ''
    }
    return { ...state.campaignSendStats[cID], templateName }
  })

export const promoSizeEstimateLoaded = (
  state: PromoState,
  promoID: number
): boolean =>
  state.meta.FETCH_PROMO_SIZE_ESTIMATE[promoID] &&
  state.meta.FETCH_PROMO_SIZE_ESTIMATE[promoID].loaded

export const promoSizeEstimateLoadedOnce = (
  state: PromoState,
  promoID: number
): boolean =>
  !!state.promoSizeEstimates[promoID] || promoSizeEstimateLoaded(state, promoID)

export const promoSizeEstimate = (
  state: PromoState,
  promoID: number
): UserListEstimate | null => state.promoSizeEstimates[promoID]

export const hasSavedCohorts = (
  state: PromoState,
  promoID: number | undefined
) => {
  if (!promoID) return false
  return promoCohorts(state, promoID).length > 0
}

export const cohortSizeEstimateLoaded = (
  state: PromoState,
  cohortID: number
): boolean =>
  state.meta.FETCH_COHORT_SIZE_ESTIMATE[cohortID] &&
  state.meta.FETCH_COHORT_SIZE_ESTIMATE[cohortID].loaded

export const cohortSizeEstimateLoadedOnce = (
  state: PromoState,
  cohortID: number
): boolean =>
  !!state.cohortSizeEstimates[cohortID] ||
  cohortSizeEstimateLoaded(state, cohortID)

export const cohortSizeEstimate = (
  state: PromoState,
  cohortID: number
): UserListEstimate | null => state.cohortSizeEstimates[cohortID]

export const exclusionListsLoadedOnce = (
  state: PromoState,
  promoID: number
): boolean =>
  (state.meta.FETCH_PROMO_EXCLUSION_LISTS[promoID] &&
    state.meta.FETCH_PROMO_EXCLUSION_LISTS[promoID].loaded) ||
  !!state.exclusionLists[promoID]

export const exclusionListsForPromo = (
  state: PromoState,
  promoID: number
): ExclusionList[] => {
  const exListSet = state.exclusionLists[promoID]
  if (!exListSet) return []
  return exListSet
}

export const deliveryStatsLoadedOnce = (
  state: PromoState,
  campaignID: number | null | undefined
): boolean => {
  if (!campaignID) return true
  return (
    (state.meta.FETCH_CAMPAIGN_DELIVERY_STATS[campaignID] &&
      state.meta.FETCH_CAMPAIGN_DELIVERY_STATS[campaignID].loaded) ||
    !!state.campaignDeliveryStats[campaignID]
  )
}

export const campaignDeliveryStats = (
  state: PromoState,
  campaignID: number | null | undefined
): CampaignDeliveryStat | null => {
  if (!campaignID) return null
  return state.campaignDeliveryStats[campaignID]
}

export const campaignDeliveryStatsByPromo = (
  state: PromoState,
  promoID: number
): CampaignDeliveryStat[] =>
  latestCampaignIDsForPromo(state, promoID)
    .map((cID) => {
      let templateName = ''
      const campaign = state.campaigns[cID]
      if (campaign && campaign.templates.length > 0) {
        templateName =
          state.emailTemplates[campaign.templates[0].email_template_id]
            ?.template || ''
      }
      return { ...state.campaignDeliveryStats[cID], templateName }
    })
    .filter((result) => !!result.promo_date_stats)

export const latestCampaignStatsLoading = (
  state: PromoState,
  promoID: number
) =>
  latestCampaignIDsForPromo(state, promoID)
    .map((cID) => state.meta.FETCH_CAMPAIGN_DELIVERY_STATS[cID])
    .filter((cState) => cState && cState.isLoading).length > 0

export const latestCampaignStatsLoadedOnce = (
  state: PromoState,
  promoID: number
) =>
  latestCampaignIDsForPromo(state, promoID)
    .map((cID) => state.meta.FETCH_CAMPAIGN_DELIVERY_STATS[cID])
    .filter((cState) => !cState || !cState.loaded).length === 0
