import * as ram from '@talentinc/redux-axios-middleware'
import {
  DiscountTokenPayload,
  DiscountToken,
  PatchDiscountTokenPayload,
  StickerPrice,
  DiscountTokenPayloadBase,
  DeleteDiscountTokenPayload,
  UserDiscountToken,
  DiscountTokenUsage,
  DiscountBrand,
} from './types'

export enum DiscountActions {
  FETCH_DISCOUNTS = 'FETCH_DISCOUNTS',
  FETCH_DISCOUNTS_SUCCESS = 'FETCH_DISCOUNTS_SUCCESS',
  FETCH_DISCOUNTS_FAIL = 'FETCH_DISCOUNTS_FAIL',
  CREATE_DISCOUNT = 'CREATE_DISCOUNT',
  CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS',
  CREATE_DISCOUNT_FAIL = 'CREATE_DISCOUNT_FAIL',
  FETCH_STICKER_PRICES = 'FETCH_STICKER_PRICES',
  FETCH_STICKER_PRICES_SUCCESS = 'FETCH_STICKER_PRICES_SUCCESS',
  FETCH_STICKER_PRICES_FAIL = 'FETCH_STICKER_PRICES_FAIL',
  FETCH_DISCOUNT = 'FETCH_DISCOUNT',
  FETCH_DISCOUNT_SUCCESS = 'FETCH_DISCOUNT_SUCCESS',
  FETCH_DISCOUNT_FAIL = 'FETCH_DISCOUNT_FAIL',
  UPDATE_DISCOUNT_INFO = 'UPDATE_DISCOUNT_INFO',
  UPDATE_DISCOUNT_INFO_SUCCESS = 'UPDATE_DISCOUNT_INFO_SUCCESS',
  UPDATE_DISCOUNT_INFO_FAIL = 'UPDATE_DISCOUNT_INFO_FAIL',
  ADD_DISCOUNT_PLAN_CODES = 'ADD_DISCOUNT_PLAN_CODES',
  ADD_DISCOUNT_PLAN_CODES_SUCCESS = 'ADD_DISCOUNT_PLAN_CODES_SUCCESS',
  ADD_DISCOUNT_PLAN_CODES_FAIL = 'ADD_DISCOUNT_PLAN_CODES_FAIL',
  REMOVE_DSICOUNT_PLAN_CODES = 'REMOVE_DSICOUNT_PLAN_CODES',
  REMOVE_DSICOUNT_PLAN_CODES_SUCCESS = 'REMOVE_DSICOUNT_PLAN_CODES_SUCCESS',
  REMOVE_DSICOUNT_PLAN_CODES_FAIL = 'REMOVE_DSICOUNT_PLAN_CODES_FAIL',
  DELETE_DISCOUNT_TOKEN = 'DELETE_DISCOUNT_TOKEN',
  DELETE_DISCOUNT_TOKEN_SUCCESS = 'DELETE_DISCOUNT_TOKEN_SUCCESS',
  DELETE_DISCOUNT_TOKEN_FAIL = 'DELETE_DISCOUNT_TOKEN_FAIL',
  CREATE_USER_DISCOUNT = 'CREATE_USER_DISCOUNT',
  CREATE_USER_DISCOUNT_SUCCESS = 'CREATE_USER_DISCOUNT_SUCCESS',
  CREATE_USER_DISCOUNT_FAIL = 'CREATE_USER_DISCOUNT_FAIL',
  DELETE_USER_DISCOUNT = 'DELETE_USER_DISCOUNT',
  DELETE_USER_DISCOUNT_SUCCESS = 'DELETE_USER_DISCOUNT_SUCCESS',
  DELETE_USER_DISCOUNT_FAIL = 'DELETE_USER_DISCOUNT_FAIL',
  FETCH_DISCOUNT_USAGE_STATS = 'FETCH_DISCOUNT_USAGE_STATS',
  FETCH_DISCOUNT_USAGE_STATS_SUCCESS = 'FETCH_DISCOUNT_USAGE_STATS_SUCCESS',
  FETCH_DISCOUNT_USAGE_STATS_FAIL = 'FETCH_DISCOUNT_USAGE_STATS_FAIL',
  DELETE_STICKER_PRICE = 'DELETE_STICKER_PRICE',
  DELETE_STICKER_PRICE_SUCCESS = 'DELETE_STICKER_PRICE_SUCCESS',
  DELETE_STICKER_PRICE_FAIL = 'DELETE_STICKER_PRICE_FAIL',
  FETCH_DISCOUNT_BRANDS = 'FETCH_DISCOUNT_BRANDS',
  FETCH_DISCOUNT_BRANDS_SUCCESS = 'FETCH_DISCOUNT_BRANDS_SUCCESS',
  FETCH_DISCOUNT_BRANDS_FAIL = 'FETCH_DISCOUNT_BRANDS_FAIL',
  ADD_STICKER_PRICES = 'ADD_STICKER_PRICES',
  ADD_STICKER_PRICES_SUCCESS = 'ADD_STICKER_PRICES_SUCCESS',
  ADD_STICKER_PRICES_FAIL = 'ADD_STICKER_PRICES_FAIL',
}

interface FetchDiscounts extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.FETCH_DISCOUNTS
}

interface FetchDiscountsSuccess
  extends ram.AxiosMiddlewareActionSuccess<DiscountToken[], FetchDiscounts> {
  type: DiscountActions.FETCH_DISCOUNTS_SUCCESS
}

interface FetchDiscountsFail
  extends ram.AxiosMiddlewareActionFail<FetchDiscounts> {
  type: DiscountActions.FETCH_DISCOUNTS_FAIL
}

interface FetchDiscount extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.FETCH_DISCOUNT
  discountToken: string
}

interface FetchDiscountSuccess
  extends ram.AxiosMiddlewareActionSuccess<DiscountToken, FetchDiscount> {
  type: DiscountActions.FETCH_DISCOUNT_SUCCESS
}

interface FetchDiscountFail
  extends ram.AxiosMiddlewareActionFail<FetchDiscount> {
  type: DiscountActions.FETCH_DISCOUNT_FAIL
}

interface CreateDiscount extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.CREATE_DISCOUNT
  tokens: DiscountTokenPayload[]
}

interface CreateDiscountSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, CreateDiscount> {
  type: DiscountActions.CREATE_DISCOUNT_SUCCESS
}

interface CreateDiscountFail
  extends ram.AxiosMiddlewareActionFail<CreateDiscount> {
  type: DiscountActions.CREATE_DISCOUNT_FAIL
}

interface FetchStickerPrices extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.FETCH_STICKER_PRICES
}

interface FetchStickerPricesSuccess
  extends ram.AxiosMiddlewareActionSuccess<StickerPrice[], FetchStickerPrices> {
  type: DiscountActions.FETCH_STICKER_PRICES_SUCCESS
}

interface FetchStickerPricesFail
  extends ram.AxiosMiddlewareActionFail<FetchStickerPrices> {
  type: DiscountActions.FETCH_STICKER_PRICES_FAIL
}

interface UpdateDiscountInfo extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.UPDATE_DISCOUNT_INFO
  discountToken: string
  data: PatchDiscountTokenPayload[]
}

interface UpdateDiscountInfoSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, UpdateDiscountInfo> {
  type: DiscountActions.UPDATE_DISCOUNT_INFO_SUCCESS
}

interface UpdateDiscountInfoFail
  extends ram.AxiosMiddlewareActionFail<UpdateDiscountInfo> {
  type: DiscountActions.UPDATE_DISCOUNT_INFO_FAIL
}

interface RemoveDiscountPlanCodes extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.REMOVE_DSICOUNT_PLAN_CODES
  discountToken: string
  data: DiscountTokenPayloadBase[]
}

interface RemoveDiscountPlanCodesSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, RemoveDiscountPlanCodes> {
  type: DiscountActions.REMOVE_DSICOUNT_PLAN_CODES_SUCCESS
}

interface RemoveDiscountPlanCodesFail
  extends ram.AxiosMiddlewareActionFail<RemoveDiscountPlanCodes> {
  type: DiscountActions.REMOVE_DSICOUNT_PLAN_CODES_FAIL
}

interface DeleteDiscountToken extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.DELETE_DISCOUNT_TOKEN
  discountToken: string
  data: DeleteDiscountTokenPayload[]
}

interface DeleteDiscountTokenSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, DeleteDiscountToken> {
  type: DiscountActions.DELETE_DISCOUNT_TOKEN_SUCCESS
}

interface DeleteDiscountTokenFail
  extends ram.AxiosMiddlewareActionFail<DeleteDiscountToken> {
  type: DiscountActions.DELETE_DISCOUNT_TOKEN_FAIL
}

interface CreateUserDiscountToken extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.CREATE_USER_DISCOUNT
  discountToken: string
}

export interface CreateUserDiscountTokenSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    UserDiscountToken[],
    CreateUserDiscountToken
  > {
  type: DiscountActions.CREATE_USER_DISCOUNT_SUCCESS
}

interface CreateUserDiscountTokenFail
  extends ram.AxiosMiddlewareActionFail<CreateUserDiscountToken> {
  type: DiscountActions.CREATE_USER_DISCOUNT_FAIL
}

interface DeleteUserDiscountToken extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.DELETE_USER_DISCOUNT
  discountToken: string
  userDiscountToken: string
}

export interface DeleteUserDiscountTokenSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, DeleteUserDiscountToken> {
  type: DiscountActions.DELETE_USER_DISCOUNT_SUCCESS
}

interface DeleteUserDiscountTokenFail
  extends ram.AxiosMiddlewareActionFail<DeleteUserDiscountToken> {
  type: DiscountActions.DELETE_USER_DISCOUNT_FAIL
}

interface FetchDiscountUsageStats extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.FETCH_DISCOUNT_USAGE_STATS
}

interface FetchDiscountUsageStatsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    DiscountTokenUsage[],
    FetchDiscountUsageStats
  > {
  type: DiscountActions.FETCH_DISCOUNT_USAGE_STATS_SUCCESS
}

interface FetchDiscountUsageStatsFail
  extends ram.AxiosMiddlewareActionFail<FetchDiscountUsageStats> {
  type: DiscountActions.FETCH_DISCOUNT_USAGE_STATS_FAIL
}

interface DeleteStickerPrice extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.DELETE_STICKER_PRICE
  planCode: string
}

interface DeleteStickerPriceSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, DeleteStickerPrice> {
  type: DiscountActions.DELETE_STICKER_PRICE_SUCCESS
}

interface DeleteStickerPriceFail
  extends ram.AxiosMiddlewareActionFail<DeleteStickerPrice> {
  type: DiscountActions.DELETE_STICKER_PRICE_FAIL
}

interface FetchDiscountBrands extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.FETCH_DISCOUNT_BRANDS
  discountToken: string
}

interface FetchDiscountBrandsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    DiscountBrand[],
    FetchDiscountBrands
  > {
  type: DiscountActions.FETCH_DISCOUNT_BRANDS_SUCCESS
}

interface FetchDiscountBrandsFail
  extends ram.AxiosMiddlewareActionFail<FetchDiscountBrands> {
  type: DiscountActions.FETCH_DISCOUNT_BRANDS_FAIL
}

interface AddStickerPrices extends ram.AxiosMiddlewareActionCreator {
  type: DiscountActions.ADD_STICKER_PRICES
  data: StickerPrice[]
}

interface AddStickerPricesSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, AddStickerPrices> {
  type: DiscountActions.ADD_STICKER_PRICES_SUCCESS
}

interface AddStickerPricesFail
  extends ram.AxiosMiddlewareActionFail<AddStickerPrices> {
  type: DiscountActions.ADD_STICKER_PRICES_FAIL
}

//Retreives all discount tokes without the associated payment plans
export const fetchDiscounts = (): FetchDiscounts => ({
  type: DiscountActions.FETCH_DISCOUNTS,
  payload: {
    request: {
      url: '/v2/discounts/tokens',
    },
  },
})

//Retreives a single discount token with the associated Payment Plans
export const fetchDiscount = (discountToken: string): FetchDiscount => ({
  type: DiscountActions.FETCH_DISCOUNT,
  discountToken,
  payload: {
    request: {
      url: `/v2/discounts/tokens/${encodeURIComponent(discountToken)}`,
    },
  },
})

export const createOrUpdateDiscount = (
  data: DiscountTokenPayload[]
): CreateDiscount => ({
  type: DiscountActions.CREATE_DISCOUNT,
  tokens: data,
  payload: {
    request: {
      url: '/v2/discounts/tokens',
      method: 'POST',
      data: data,
    },
  },
})

export const fetchStickerPrices = (): FetchStickerPrices => ({
  type: DiscountActions.FETCH_STICKER_PRICES,
  payload: {
    request: {
      url: '/v2/discounts/stickerprices',
    },
  },
})

export const updateDiscountInfo = (
  data: PatchDiscountTokenPayload[],
  discountToken: string
): UpdateDiscountInfo => ({
  type: DiscountActions.UPDATE_DISCOUNT_INFO,
  data,
  discountToken,
  payload: {
    request: {
      url: '/v2/discounts/tokens',
      method: 'PATCH',
      data: data,
    },
  },
})

export const removeDiscountPlanCodes = (
  discountToken: string,
  data: DiscountTokenPayloadBase[]
): RemoveDiscountPlanCodes => ({
  type: DiscountActions.REMOVE_DSICOUNT_PLAN_CODES,
  data,
  discountToken: discountToken,
  payload: {
    request: {
      url: '/v2/discounts/tokens',
      method: 'DELETE',
      data: data,
    },
  },
})

export const deleteDiscountToken = (
  discountToken: string,
  data: DeleteDiscountTokenPayload[]
): DeleteDiscountToken => ({
  type: DiscountActions.DELETE_DISCOUNT_TOKEN,
  data,
  discountToken: discountToken,
  payload: {
    request: {
      url: '/v2/discounts/tokens',
      method: 'DELETE',
      data: data,
    },
  },
})

export const createUserDiscountToken = (
  discountToken: string,
  data: DiscountTokenPayloadBase[]
): CreateUserDiscountToken => ({
  type: DiscountActions.CREATE_USER_DISCOUNT,
  discountToken: discountToken,
  payload: {
    request: {
      url: '/v2/discounts/single-use',
      method: 'POST',
      data: data,
    },
  },
})

export const deleteUserDiscountToken = (
  discountToken: string,
  userDiscountToken: string
): DeleteUserDiscountToken => ({
  type: DiscountActions.DELETE_USER_DISCOUNT,
  discountToken,
  userDiscountToken,
  payload: {
    request: {
      url: `/v2/discounts/single-use/${encodeURIComponent(userDiscountToken)}`,
      method: 'DELETE',
    },
  },
})

export const fetchDiscountUsageStats = (): FetchDiscountUsageStats => ({
  type: DiscountActions.FETCH_DISCOUNT_USAGE_STATS,
  payload: {
    request: {
      url: '/v2/discounts/usage-stats',
    },
  },
})

export const deleteStickerPrice = (planCode: string): DeleteStickerPrice => ({
  type: DiscountActions.DELETE_STICKER_PRICE,
  planCode,
  payload: {
    request: {
      url: `/v2/discounts/stickerprices/${planCode}`,
      method: 'DELETE',
    },
  },
})

export const addStickerPrices = (data: StickerPrice[]): AddStickerPrices => ({
  type: DiscountActions.ADD_STICKER_PRICES,
  data,
  payload: {
    request: {
      url: `/v2/discounts/stickerprices`,
      method: 'POST',
      data,
    },
  },
})

/**
 * Fetches a list of brands for a given list of plan codes
 *
 * @param {string} discountToken The discount token the plan codes belong to
 * @return {FetchDiscountBrands} The redux action.
 */
export const fetchDiscountBrands = (
  discountToken: string
): FetchDiscountBrands => ({
  type: DiscountActions.FETCH_DISCOUNT_BRANDS,
  discountToken,
  payload: {
    request: {
      url: `/v2/discounts/brands?discount_token=${encodeURIComponent(
        discountToken
      )}`,
    },
  },
})

export type DiscountAction =
  | FetchDiscounts
  | FetchDiscountsSuccess
  | FetchDiscountsFail
  | CreateDiscount
  | CreateDiscountSuccess
  | CreateDiscountFail
  | FetchStickerPrices
  | FetchStickerPricesSuccess
  | FetchStickerPricesFail
  | FetchDiscount
  | FetchDiscountSuccess
  | FetchDiscountFail
  | UpdateDiscountInfo
  | UpdateDiscountInfoSuccess
  | UpdateDiscountInfoFail
  | RemoveDiscountPlanCodes
  | RemoveDiscountPlanCodesSuccess
  | RemoveDiscountPlanCodesFail
  | DeleteDiscountToken
  | DeleteDiscountTokenSuccess
  | DeleteDiscountTokenFail
  | CreateUserDiscountToken
  | CreateUserDiscountTokenSuccess
  | CreateUserDiscountTokenFail
  | DeleteUserDiscountToken
  | DeleteUserDiscountTokenSuccess
  | DeleteUserDiscountTokenFail
  | FetchDiscountUsageStats
  | FetchDiscountUsageStatsSuccess
  | FetchDiscountUsageStatsFail
  | DeleteStickerPrice
  | DeleteStickerPriceSuccess
  | DeleteStickerPriceFail
  | FetchDiscountBrands
  | FetchDiscountBrandsSuccess
  | FetchDiscountBrandsFail
  | AddStickerPrices
  | AddStickerPricesSuccess
  | AddStickerPricesFail
