import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface Props {
  titleText?: string
  displayText: string
  confirmButtonText?: string
  cancelText?: string
  onConfirm: () => void
  onCancel?: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  displayText,
  confirmButtonText,
  cancelText,
  onConfirm,
  onCancel,
  titleText,
}) => {
  const tRef = 'components.ConfirmationModal'
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(true)

  const handleConfirm = () => {
    setOpen(false)
    onConfirm()
  }

  const handleCancel = () => {
    setOpen(false)
    if (onCancel) {
      onCancel()
    }
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {displayText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelText || t(`${tRef}.cancel`)}
        </Button>
        <Button onClick={handleConfirm} color="secondary" autoFocus>
          {confirmButtonText || t(`${tRef}.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
