import {
  HashMap,
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils'
import { EventActions } from './actions'

export interface EventType {
  id: number
  event: string
}

export interface EventState {
  eventTypes: HashMap<EventType>
  meta: {
    [EventActions.FETCH_EVENT_TYPES]: LoadingLoadedErrorState
  }
}

export const initalEventState: EventState = {
  eventTypes: {},
  meta: {
    [EventActions.FETCH_EVENT_TYPES]: { ...initialLoadedLoadingErrorState },
  },
}
