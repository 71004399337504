import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Redirect } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import LockForm from './LockForm'
import useAuth0 from './useAuth0'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      fontSize: '1.13em',
      color: '#052a4d',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingBottom: '1em',
    },
    logo: {
      fontSize: '1em',
      width: '6.9em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '1.2em',

      '& img': {
        maxHeight: '1.2em',
        margin: '0 auto',
      },
    },
    paper: {
      padding: '1.5em',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '36em',
      },
    },
    root: {
      alignItems: 'flex-start',
      alignContent: 'center',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '2.5em',
    },
  })
)

const Login: React.FC = () => {
  const { lock, loggedIn } = useAuth0()
  const classes = useStyles()

  if (loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        {/*
            lock.js has a weird API in a React sense. You only want to call
            lock.show once, otherwise it renders a few times. I used to do this
            in a useLayoutEffect, but those run whenever variables change. Also,
            the html element it binds to will re-render, so lock.js will jitter
            when that happens. The way around it? Check that we can render
            before actually rendering.
        */}
        {lock && <LockForm />}
      </Paper>
    </Box>
  )
}

export default React.memo(Login, () => true)
