import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isEqual } from 'lodash'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import CohortFormContainer from './CohortFormContainer'

import {
  ActionTypes,
  Cohort,
  CohortSources,
  PromoWithCohortsAndCampaigns,
} from 'store/promo/types'
import {
  cohortsLoaded,
  promoCohorts,
  promoIsApproved,
} from 'store/promo/selector'
import { AppState } from 'store'
import { uploadInclusionListAndUpdateCohorts } from 'store/promo/thunks'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import useAlerts from 'hooks/useAlerts'
import LinearProgress from '@material-ui/core/LinearProgress'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import LoaderButton from 'components/shared/LoaderButton'
import { tryGetErrorMessage } from 'utils/errors'

interface Props {
  promo: PromoWithCohortsAndCampaigns
}

const CohortsContainer: React.FC<Props> = ({ promo }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tRef = 'pages.Campaigns.sections.promos.form.audience'
  const defaultCohorts =
    promo.cohorts.length > 0
      ? []
      : [
          {
            cohortID: null,
            behaviors: [{ actionType: ActionTypes.Did }],
            source: CohortSources.Database,
          },
        ]

  const cohorts = useSelector<AppState, Cohort[]>(
    (state) => promoCohorts(state.promos, promo.id),
    isEqual
  )

  const cohortsReady = useSelector<AppState, boolean>((state) =>
    cohortsLoaded(state.promos, promo?.id)
  )

  const promoApproved = useSelector<AppState, boolean>((state) =>
    promoIsApproved(state.promos, promo?.id)
  )

  const canEdit = !promoApproved

  const [newCohorts, setNewCohorts] = useState<Cohort[]>(defaultCohorts)
  const removeCohort = useCallback(
    (index: number) => {
      if (promo) {
        setNewCohorts((c) => [...c.slice(0, index), ...c.slice(index + 1)])
      }
    },
    [promo]
  )

  const addCohort = useCallback(() => {
    setNewCohorts((c) => [
      ...c,
      {
        cohortID: null,
        behaviors: [{ actionType: ActionTypes.Did }],
        source: CohortSources.Database,
      },
    ])
  }, [])

  if (!promo) return null
  return (
    <>
      {!cohortsReady && <LinearProgress className={classes.progress} />}
      {cohorts &&
        cohortsReady &&
        cohorts.map((cohort, index) => (
          <CohortFormContainer
            cohort={cohort}
            index={index}
            promoID={promo.id}
            key={cohort.cohortID}
            removeCohort={removeCohort}
          />
        ))}
      {cohortsReady &&
        newCohorts &&
        newCohorts.map((cohort, index) => (
          <CohortFormContainer
            cohort={cohort}
            index={index}
            promoID={promo.id}
            removeCohort={removeCohort}
            key={index}
          />
        ))}
      {canEdit && (
        <Container disableGutters className={classes.row}>
          <Button
            variant="contained"
            className={classes.leftButton}
            onClick={addCohort}
          >
            {t(`${tRef}.add`)}
          </Button>
          <AddCSVCohortForm promoID={promo.id} />
        </Container>
      )}
    </>
  )
}

type CSVUploadTypes = {
  file: File
  promoID: number
  exclude_purchasers: boolean
}

interface PromoIDProps {
  promoID: number
}

const AddCSVCohortForm: React.FC<PromoIDProps> = ({ promoID }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const tRef = 'pages.Campaigns.sections.promos.form.audience'
  const { register, handleSubmit, watch, reset } = useForm<CSVUploadTypes>()
  const fileList = watch('file') as unknown as FileList
  const { infoAlert, errorAlert } = useAlerts()

  const onSubmit = useCallback(
    async (data) => {
      const files = data.file as FileList
      if (files && files.length > 0) {
        const error = await dispatch(
          uploadInclusionListAndUpdateCohorts(
            files[0],
            promoID,
            data.exclude_purchasers as boolean
          )
        )
        if (!error) {
          reset()
          infoAlert('Changes saved!')
          return
        }
        errorAlert('Upload failed: ' + tryGetErrorMessage(error))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promoID, infoAlert, errorAlert]
  )

  const [submissionState, submitForm] = useAsyncFn(onSubmit, [onSubmit])

  return (
    <Box>
      <form onSubmit={handleSubmit(submitForm)}>
        <Container disableGutters className={classes.row}>
          <input type="hidden" name="promoID" value={promoID} ref={register} />
          <Button
            variant="outlined"
            component="label"
            className={classes.leftButton}
          >
            {!(fileList && fileList.length > 0) && t(`${tRef}.addViaCSV`)}
            {fileList && fileList.length > 0 && t(`${tRef}.changeFile`)}
            <input type="file" name="file" hidden ref={register} />
          </Button>
          {fileList && fileList.length > 0 && (
            <Box className={classes.row}>
              <Typography variant="body2">
                {t(`${tRef}.excludePurchasers`)}
              </Typography>
              <Typography variant="body2" color="secondary">
                <Switch
                  name="exclude_purchasers"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                  inputRef={register}
                />
              </Typography>
              <LoaderButton
                variant="contained"
                type="submit"
                loading={submissionState.loading}
              >
                {t(`${tRef}.uploadFileName`, { fileName: fileList[0].name })}
              </LoaderButton>
            </Box>
          )}
        </Container>
      </form>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    leftButton: {
      marginRight: '1rem',
    },
    progress: {
      margin: '2rem 2rem 2rem 2rem',
    },
  })
)

export default CohortsContainer
