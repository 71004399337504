import React from 'react'
import { useTranslation } from 'react-i18next'
import { format as dateFormat } from 'date-fns'
import { ArrayField, useFormContext } from 'react-hook-form'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import ValidationSelect from 'components/shared/ValidationSelect'
import ValidationTextField from 'components/shared/ValidationTextField'

import {
  ActionTypes,
  BehaviorInputs,
  CohortInputs,
  EngagementTypes,
  EventSystems,
  GenericEngagementTypes,
  Promo,
} from 'store/promo/types'
import { EventType } from 'store/events/types'

interface BehaviorProps {
  behavior: Partial<ArrayField<BehaviorInputs, 'id'>>
  index: number
  removeBehavior: (index: number) => void
  promos: Promo[]
  eventTypes: EventType[]
  canEdit: boolean
}

const CohortBehaviorForm: React.FC<BehaviorProps> = ({
  behavior,
  index,
  removeBehavior,
  promos,
  eventTypes,
  canEdit,
}) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.audience.behavior'
  const { t } = useTranslation()

  //grabbing the register and control hooks from the parent form
  const parentFormHooks = useFormContext<CohortInputs>()

  //tracking the engagementType so we can change the engagement key dropdown contents on change
  const engagementType: string = parentFormHooks.watch(
    `behaviors[${index}].engagementType`
  )

  const dateFormatStr = 'yyyy-MM-dd'
  const defaultValues: BehaviorInputs = {
    behaviorID: behavior.behaviorID || null,
    actionType: behavior.actionType || ActionTypes.Did,
    engagementType: behavior.engagementType || EngagementTypes.OpenedPromoEmail,
    engagementKey: behavior.engagementKey,
    startDate: behavior.startDate || dateFormat(new Date(), dateFormatStr),
    endDate: behavior.endDate || dateFormat(new Date(), dateFormatStr),
    system: behavior.system || '',
  }

  return (
    <Box>
      <Container disableGutters className={classes.formGroup}>
        <input
          type="hidden"
          value={behavior.behaviorID || 0}
          name={`behaviors[${index}].behaviorID`}
          ref={parentFormHooks.register}
        />
        <Typography variant="body2" className={classes.sentenceText}>
          {t(`${tRef}.track`)}
        </Typography>
        <ValidationSelect
          name={`behaviors[${index}].actionType`}
          inputRef={parentFormHooks.register({ required: true })}
          defaultValue={defaultValues.actionType}
          isValid={
            !(
              parentFormHooks.errors.behaviors &&
              parentFormHooks.errors.behaviors[index]?.actionType
            )
          }
          validationMessage={t(`${tRef}.validation.required`)}
          disabled={!canEdit}
        >
          <option value={ActionTypes.Did} key={ActionTypes.Did}>
            {t(`${tRef}.actionTypes.${ActionTypes.Did}`)}
          </option>
          <option value={ActionTypes.DidNot} key={ActionTypes.DidNot}>
            {t(`${tRef}.actionTypes.${ActionTypes.DidNot}`)}
          </option>
        </ValidationSelect>
        <ValidationSelect
          name={`behaviors[${index}].engagementType`}
          inputRef={parentFormHooks.register({ required: true })}
          defaultValue={defaultValues.engagementType}
          isValid={
            !(
              parentFormHooks.errors.behaviors &&
              parentFormHooks.errors.behaviors[index]?.engagementType
            )
          }
          validationMessage={t(`${tRef}.validation.required`)}
          disabled={!canEdit}
        >
          {GenericEngagementTypes.map((e) => (
            <option value={e} key={e}>
              {t(`${tRef}.engagementTypes.${e}`)}
            </option>
          ))}
        </ValidationSelect>
        {engagementType === EngagementTypes.AramisEvent && (
          <>
            <ValidationSelect
              name={`behaviors[${index}].system`}
              inputRef={parentFormHooks.register({ required: true })}
              defaultValue={defaultValues.system}
              isValid={
                !(
                  parentFormHooks.errors.behaviors &&
                  parentFormHooks.errors.behaviors[index]?.system
                )
              }
              validationMessage={t(`${tRef}.validation.required`)}
              disabled={!canEdit}
            >
              <option value={''} key={''}>
                {t(`${tRef}.selectSystem`)}
              </option>
              {Object.values(EventSystems).map((system) => (
                <option value={system} key={system}>
                  {t(`${tRef}.eventSystems.${system}`)}
                </option>
              ))}
            </ValidationSelect>
            <ValidationSelect
              name={`behaviors[${index}].engagementKey`}
              inputRef={parentFormHooks.register({ required: true, min: 1 })}
              defaultValue={defaultValues.engagementKey}
              isValid={
                !(
                  parentFormHooks.errors.behaviors &&
                  parentFormHooks.errors.behaviors[index]?.engagementKey
                )
              }
              validationMessage={t(`${tRef}.validation.required`)}
              disabled={!canEdit}
            >
              <option value={0} key={0}>
                {t(`${tRef}.selectBehavior`)}
              </option>
              {eventTypes.map((et) => (
                <option value={et.id} key={et.id}>
                  {et.id} - {et.event}
                </option>
              ))}
            </ValidationSelect>
          </>
        )}
        {engagementType !== EngagementTypes.AramisEvent && (
          <>
            <ValidationSelect
              name={`behaviors[${index}].engagementKey`}
              inputRef={parentFormHooks.register({ required: true, min: 1 })}
              defaultValue={defaultValues.engagementKey}
              isValid={
                !(
                  parentFormHooks.errors.behaviors &&
                  parentFormHooks.errors.behaviors[index]?.engagementKey
                )
              }
              validationMessage={t(`${tRef}.validation.required`)}
              disabled={!canEdit}
            >
              <option value={0} key={0}>
                {t(`${tRef}.selectBehavior`)}
              </option>
              {promos.map((promo) => (
                <option value={promo.id} key={promo.id}>
                  {promo.id} - {promo.name}
                </option>
              ))}
            </ValidationSelect>
          </>
        )}

        {index > 0 && canEdit && (
          <IconButton
            className={classes.removeButton}
            onClick={() => removeBehavior(index)}
          >
            <RemoveCircleIcon className={classes.removeIcon} />
          </IconButton>
        )}
      </Container>
      <Container disableGutters className={classes.formGroup}>
        <Typography variant="body2" className={classes.sentenceText}>
          {t(`${tRef}.between`)}
        </Typography>
        <ValidationTextField
          className={classes.date}
          name={`behaviors[${index}].startDate`}
          inputRef={parentFormHooks.register({ required: true })}
          defaultValue={defaultValues.startDate}
          isValid={
            !(
              parentFormHooks.errors.behaviors &&
              parentFormHooks.errors.behaviors[index]?.startDate
            )
          }
          validationMessage={t(`${tRef}.validation.required`)}
          disabled={!canEdit}
          type="date"
        />
        <Typography variant="body2" className={classes.sentenceText}>
          {t(`${tRef}.and`)}
        </Typography>
        <ValidationTextField
          className={classes.date}
          name={`behaviors[${index}].endDate`}
          inputRef={parentFormHooks.register({ required: true })}
          defaultValue={defaultValues.endDate}
          isValid={
            !(
              parentFormHooks.errors.behaviors &&
              parentFormHooks.errors.behaviors[index]?.endDate
            )
          }
          validationMessage={t(`${tRef}.validation.required`)}
          disabled={!canEdit}
          type="date"
        />
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    button: {
      marginLeft: '1rem',
    },
    date: {
      width: '12.5rem',
    },
    time: {
      width: '10rem',
    },
    removeIcon: {
      color: colors.red[600],
      width: '1.2rem',
      height: '1.2rem',
    },
    removeButton: {
      marginLeft: '1rem',
      width: '1.2rem',
      height: '1.2rem',
    },
    sentenceText: {
      marginRight: '1rem',
    },
  })
)

export default CohortBehaviorForm
