import { useCallback, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) =>
  createStyles({
    pagerContainer: {
      marginTop: '1rem',
    },
  })
)

//A resusable hook for managing paginated table state
function usePagination(tableSize: number, itemsPerPage: number) {
  const classes = useStyles()

  const [page, setPage] = useState(1)

  const numPages =
    Math.floor(tableSize / itemsPerPage) +
    (tableSize % itemsPerPage > 0 ? 1 : 0)

  const onPageSelected = useCallback((event, pageNum: number) => {
    setPage(pageNum)
  }, [])

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const pager = (
    <Container disableGutters className={classes.pagerContainer}>
      <Pagination count={numPages} page={page} onChange={onPageSelected} />
    </Container>
  )

  return { pager, startIndex, endIndex }
}

export default usePagination
