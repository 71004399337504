import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import { AppState } from 'store'
import { fetchCohortSizeEstimate } from 'store/promo/actions'
import {
  cohortSizeEstimate,
  cohortSizeEstimateLoaded,
  cohortSizeEstimateLoadedOnce,
} from 'store/promo/selector'
import { UserListEstimate } from 'store/promo/types'

interface Props {
  promoID: number
  cohortID: number
  className?: string
}

const CohortSizeEstimateContainer: React.FC<Props> = ({
  promoID,
  cohortID,
  className,
}) => {
  const dispatch = useDispatch()
  const estimateLoadedOnce = useSelector<AppState, boolean>((state) =>
    cohortSizeEstimateLoadedOnce(state.promos, cohortID)
  )
  const estimateLoaded = useSelector<AppState, boolean>((state) =>
    cohortSizeEstimateLoaded(state.promos, cohortID)
  )

  const estimateLoading = useSelector<AppState, boolean>(
    (state) =>
      !!state.promos.meta.FETCH_COHORT_SIZE_ESTIMATE[cohortID]?.isLoading
  )

  const estimate = useSelector<AppState, UserListEstimate | null>((state) =>
    cohortSizeEstimate(state.promos, cohortID)
  )
  useEffect(() => {
    if (!estimateLoadedOnce && !estimateLoading) {
      dispatch(fetchCohortSizeEstimate(promoID, cohortID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [estimateLoadedOnce, estimateLoading, promoID, cohortID])
  if (!estimateLoaded) return <LinearProgress />
  return (
    <Typography variant="body2" className={className}>
      {estimate?.estimate || '--'}
    </Typography>
  )
}

export default CohortSizeEstimateContainer
