import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { colors } from 'styles'
import DeliveryStatsTooltip from '../Tooltips/DeliveryStatsTooltip'
import BarChartIcon from '@material-ui/icons/BarChart'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { AppState } from 'store'
import {
  campaignDeliveryStats,
  campaignDeliveryStatsByPromo,
  campaignsLoadedOnce,
  deliveryStatsLoadedOnce,
  latestCampaignStatsLoadedOnce,
  latestCampaignStatsLoading,
} from 'store/promo/selector'
import { CampaignDeliveryStat } from 'store/promo/types'
import { fetchCampaignDeliveryStats } from 'store/promo/actions'
import { fetchRunningCampaignDeliveryStatsThunk } from 'store/promo/thunks'

interface Props {
  promoID: number
  campaignID?: number
  promoApproved: boolean
  showLoader: boolean
}

const DeliveryStatsContainer: React.FC<Props> = ({
  promoID,
  campaignID,
  promoApproved,
  showLoader,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const tRef = 'pages.Campaigns.sections.promos.DeliveryStatsToolTip'

  const loadedOnceCampaignStats = useSelector<AppState, boolean>((state) =>
    deliveryStatsLoadedOnce(state.promos, campaignID)
  )
  const loadedOnceCampaigns = useSelector<AppState, boolean>((state) =>
    campaignsLoadedOnce(state.promos, promoID)
  )

  const latestStatsLoadedOnce = useSelector<AppState, boolean>((state) =>
    latestCampaignStatsLoadedOnce(state.promos, promoID)
  )

  const campaignStatLoading = useSelector<AppState, boolean>((state) =>
    latestCampaignStatsLoading(state.promos, promoID)
  )

  const activeStats = useSelector<AppState, CampaignDeliveryStat[] | null>(
    (state) => campaignDeliveryStatsByPromo(state.promos, promoID),
    isEqual
  )

  const campaignStat = useSelector<AppState, CampaignDeliveryStat | null>(
    (state) => campaignDeliveryStats(state.promos, campaignID)
  )

  const getCampaignStats = (
    cID: number | undefined,
    cStat: CampaignDeliveryStat | null,
    aStats: CampaignDeliveryStat[] | null
  ) => {
    if (!cID) {
      return aStats
    }
    return cStat ? [cStat] : []
  }

  const stats = getCampaignStats(campaignID, campaignStat, activeStats)
  const loading = campaignID ? !loadedOnceCampaignStats : campaignStatLoading

  useEffect(() => {
    if (
      promoApproved &&
      loadedOnceCampaigns &&
      !campaignID &&
      !latestStatsLoadedOnce
    ) {
      dispatch(fetchRunningCampaignDeliveryStatsThunk(promoID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedOnceCampaigns, promoID, campaignID, latestStatsLoadedOnce])

  useEffect(() => {
    if (campaignID && loadedOnceCampaigns && !loadedOnceCampaignStats) {
      dispatch(fetchCampaignDeliveryStats(promoID, campaignID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedOnceCampaigns, promoID, campaignID, loadedOnceCampaignStats])

  if (showLoader && loading) {
    return (
      <Tooltip title={t(`${tRef}.loading`) || ''}>
        <LinearProgress className={classes.linearProgress} />
      </Tooltip>
    )
  }
  if (!stats || stats.length === 0) return null
  return (
    <DeliveryStatsTooltip
      campaignStats={stats}
      title={t(`${tRef}.title`) || ''}
    >
      <IconButton className={classes.statusButton}>
        <BarChartIcon className={classes.statusIcon} />
      </IconButton>
    </DeliveryStatsTooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    statusButton: {
      padding: '0.1rem',
      marginLeft: '0.5rem',
    },
    statusIcon: {
      color: colors.gray.pa100,
    },
    loader: {
      width: '1rem',
      height: '1rem',
    },
    loaderText: {
      marginLeft: '1rem',
    },
    linearProgress: {
      width: '2rem',
    },
  })
)

export default DeliveryStatsContainer
