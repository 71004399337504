import { AppThunkAction } from 'store'
import { APIError } from './axios'

export function tryGetErrorMessage(
  response: AppThunkAction<null | APIError>
): string {
  let message = ''
  try {
    const error = response as any
    message = error?.error?.response?.data?.error || error?.error?.data
  } catch (e: any) {
    //do nothing
  }

  //limit error to 200 chars so we don't show a full stack trace
  return message.substring(0, 200)
}
