import CampaignsContainer from 'components/Campaigns/CampaignsContainer'
import PageShell from 'pages/PageShell'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CampaignsPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <PageShell pageTitle={t('pages.Campaigns.pageTitle')}>
      <CampaignsContainer />
    </PageShell>
  )
}

export default CampaignsPage
