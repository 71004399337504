import * as ram from '@talentinc/redux-axios-middleware'
import { Banner } from './types'

export enum BannersActions {
  FETCH_BANNERS = 'FETCH_BANNERS',
  FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS',
  FETCH_BANNERS_FAIL = 'FETCH_BANNERS_FAIL',
  UPDATE_SELECTED_BANNERS = 'UPDATE_SELECTED_BANNERS',
  UPDATE_SELECTED_BANNERS_SUCCESS = 'UPDATE_SELECTED_BANNERS_SUCCESS',
  UPDATE_SELECTED_BANNERS_FAIL = 'UPDATE_SELECTED_BANNERS_FAIL',
}

interface FetchBanners extends ram.AxiosMiddlewareActionCreator {
  type: BannersActions.FETCH_BANNERS
}

interface FetchBannersSuccess
  extends ram.AxiosMiddlewareActionSuccess<Banner[], FetchBanners> {
  type: BannersActions.FETCH_BANNERS_SUCCESS
}

interface FetchBannersFail extends ram.AxiosMiddlewareActionFail<FetchBanners> {
  type: BannersActions.FETCH_BANNERS_FAIL
}

interface UpdateBanners extends ram.AxiosMiddlewareActionCreator {
  type: BannersActions.UPDATE_SELECTED_BANNERS
  data: Banner[]
}

interface UpdateBannerSuccess
  extends ram.AxiosMiddlewareActionSuccess<Banner, UpdateBanners> {
  type: BannersActions.UPDATE_SELECTED_BANNERS_SUCCESS
}

interface UpdateBannerFail
  extends ram.AxiosMiddlewareActionFail<UpdateBanners> {
  type: BannersActions.UPDATE_SELECTED_BANNERS_FAIL
}

export const fetchBanners = (): FetchBanners => ({
  type: BannersActions.FETCH_BANNERS,
  payload: {
    request: {
      url: `/v2/banners`,
    },
  },
})

export const updateSelectedBanners = (data: Banner[]): UpdateBanners => ({
  type: BannersActions.UPDATE_SELECTED_BANNERS,
  data,
  payload: {
    request: {
      url: '/v2/banners',
      method: 'PATCH',
      data: data,
    },
  },
})

export type BannersAction =
  | FetchBanners
  | FetchBannersSuccess
  | FetchBannersFail
  | UpdateBanners
  | UpdateBannerSuccess
  | UpdateBannerFail
