import React, { useEffect } from 'react'
import Controller from 'pages'
import useEffectOnce from 'react-use/lib/useEffectOnce'
import { useDispatch, useSelector } from 'react-redux'
import { preloadApp, preloadUser } from 'store/preload/actions'
import { AppState } from 'store'
import Loader from './shared/Loader'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const loggedIn = useSelector<AppState, boolean>(
    (state) => state.user.auth.loggedIn
  )
  const preloadFinished = useSelector<AppState, boolean>(
    (state) => state.preload.preloadStatus.loaded
  )
  // preload the application
  useEffectOnce(() => {
    dispatch(preloadApp())
  })

  // if the user is logged in, grab preload the user data
  useEffect(() => {
    if (loggedIn) {
      dispatch(preloadUser())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loggedIn])

  if (!preloadFinished) {
    return <Loader />
  }

  return <Controller />
}

export default App
