import { Auth0Error } from 'auth0-js'
import {
  LoadingLoadedErrorState,
  initialLoadedLoadingErrorState,
} from '@talentinc/state-utils'

import { createAuthStateFromCookies } from 'components/Auth/useAuth0'
import { UserActions } from './actions'

export type UserIdentifier = string | number | 'me'

export enum Groups {
  Admin = 'Admin',
  Developer = 'Developer',
}

export interface CustomerJWT {
  nickname: string
  picture: string | null
  updated_at: string
  email: string
  email_verified: boolean
  // Hard coding these so that we can do type discrimination to differentate
  // among the various Auth0 tenants
  iss:
    | 'https://login.admin.talentinc.com/'
    | 'https://login.dev.admin.talentinc.com/'
  sub: string
  aud: string
  exp: number
  nonce: string
  'https://products.talentinc.com/groups': Groups[]
  'https://products.talentinc.com/user_id': number | null
  'https://products.talentinc.com/admin_user_id': number | null
  'https://products.talentinc.com/user_pat': string | null
}

export interface AuthState {
  loggedIn: boolean
  identity: CustomerJWT | null
  jwt: string | null
  checkingSession: LoadingLoadedErrorState
  error: Auth0Error | null
}

interface EmailSignUpPayload {
  login_method: 'email'
  email: string
  password: string
}

interface SocialSignUpPayload {
  login_method: 'linkedin'
  email: string
  social_login_token: string
}

export type SignUpPayload = EmailSignUpPayload | SocialSignUpPayload

export interface UserState {
  auth: AuthState
  currentUser: User | null
  meta: {
    [UserActions.FETCH_USER]: LoadingLoadedErrorState
    [UserActions.UPDATE_USER]: LoadingLoadedErrorState
    [UserActions.SIGN_UP]: LoadingLoadedErrorState
  }
}

export const initialUserState: UserState = {
  auth: createAuthStateFromCookies(),
  currentUser: null,
  meta: {
    [UserActions.FETCH_USER]: { ...initialLoadedLoadingErrorState },
    [UserActions.UPDATE_USER]: { ...initialLoadedLoadingErrorState },
    [UserActions.SIGN_UP]: { ...initialLoadedLoadingErrorState },
  },
}

export interface User {
  id: number
  email: string | null
  first_name: string | null
  last_name: string | null
}

export interface UserUpdatePayload
  extends Partial<
    Omit<User, 'id' | 'login_method' | 'admin' | 'brand' | 'is_legacy'>
  > {
  current_password?: string
  new_password?: string
  new_password_verification?: string
}

export interface PasswordGrantResponse {
  id_token: string
}
