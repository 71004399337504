import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import NavDropdownMenu from 'components/shared/NavDropdownMenu'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import logo from 'assets/images/ti-logo.svg'
import AccountMenu from 'components/Nav/AccountMenu'
import { Link } from 'react-router-dom'

interface Props {
  pageTitle?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    navDropdownMenu: {
      marginRight: '1rem',
    },
    logo: {
      width: '2rem',
      height: '2rem',
      marginLeft: '0.5rem',
      marginRight: '2rem',
    },
    link: {
      textDecoration: 'none',
      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
    container: {
      marginLeft: '2.25rem',
    },
    title: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

const PageShell: React.FC<Props> = ({ pageTitle, children }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.navContainer}>
          <Box className={classes.optionsContainer}>
            <img src={logo} className={classes.logo} alt="logo" />
            <NavDropdownMenu
              text={t('pages.Shell.Marketing')}
              className={classes.navDropdownMenu}
            >
              <Link to={`${t('routes.campaigns')}`} className={classes.link}>
                <MenuItem>{t('pages.Shell.Campaigns')}</MenuItem>
              </Link>
              <Link to={`${t('routes.discounts')}`} className={classes.link}>
                <MenuItem>{t('pages.Shell.Discounts')}</MenuItem>
              </Link>
              <Link to={`${t('routes.banners')}`} className={classes.link}>
                <MenuItem>{t('pages.Shell.Banners')}</MenuItem>
              </Link>
            </NavDropdownMenu>
            <NavDropdownMenu
              text={t('pages.Shell.Commerce')}
              className={classes.navDropdownMenu}
            >
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
            </NavDropdownMenu>
            <NavDropdownMenu
              text={t('pages.Shell.Partner')}
              className={classes.navDropdownMenu}
            >
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
            </NavDropdownMenu>
            <NavDropdownMenu
              text={t('pages.Shell.Content')}
              className={classes.navDropdownMenu}
            >
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
            </NavDropdownMenu>
            <NavDropdownMenu
              text={t('pages.Shell.PR')}
              className={classes.navDropdownMenu}
            >
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
            </NavDropdownMenu>
            <NavDropdownMenu
              text={t('pages.Shell.Reporting')}
              className={classes.navDropdownMenu}
            >
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
            </NavDropdownMenu>
          </Box>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      {children && (
        <Container className={classes.container}>
          <Typography variant="h1" className={classes.title}>
            {pageTitle}
          </Typography>
          <Container disableGutters>{children}</Container>
        </Container>
      )}
    </>
  )
}

export default PageShell
