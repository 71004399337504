import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import CampaignForm from './CampaignForm'

import { AppState } from 'store'
import {
  campaignApprovalsByID,
  campaignsLoaded,
  canAddCampaignsToPromo,
  emailTemplates,
  promoCampaigns,
  promoIsApproved,
} from 'store/promo/selector'
import {
  Campaign,
  EmailTemplate,
  PromoWithCohortsAndCampaigns,
} from 'store/promo/types'
import { HashMap } from '@talentinc/state-utils'

interface Props {
  promo: PromoWithCohortsAndCampaigns
}

const CampaignFormContainer: React.FC<Props> = ({ promo }) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.campaign'
  const { t } = useTranslation()

  const defaultCampaigns = promo.campaigns.length > 0 ? [] : [{}]

  const templates = useSelector<AppState, EmailTemplate[]>(
    (state) => emailTemplates(state.promos),
    isEqual
  )

  const campaigns = useSelector<AppState, Campaign[]>(
    (state) => promoCampaigns(state.promos, promo.id),
    isEqual
  )

  const campaignsReady = useSelector<AppState, boolean>((state) =>
    campaignsLoaded(state.promos, promo?.id)
  )

  const promoApproved = useSelector<AppState, boolean>((state) =>
    promoIsApproved(state.promos, promo.id)
  )

  const canAddCampaigns = useSelector<AppState, boolean>((state) =>
    canAddCampaignsToPromo(state.promos, promo.id)
  )

  const campaignApprovals = useSelector<AppState, HashMap<boolean>>((state) =>
    campaignApprovalsByID(state.promos, promo.id)
  )

  const [newCampaigns, setNewCampaigns] = useState<Campaign[]>(defaultCampaigns)
  const removeCampaign = useCallback(
    (index) => {
      if (promo) {
        setNewCampaigns((c) => [...c.slice(0, index), ...c.slice(index + 1)])
      }
    },
    [promo]
  )

  const addCampaign = useCallback(() => {
    setNewCampaigns((c) => [...c, {}])
  }, [])

  if (!promo) return null

  return (
    <>
      {!campaignsReady && <LinearProgress className={classes.linearProgress} />}
      {campaigns &&
        campaignsReady &&
        campaigns.map((campaign, index) => (
          <CampaignForm
            promoID={promo.id}
            canEdit={
              !promoApproved || !campaignApprovals[campaign.campaignID || 0]
            }
            promoApproved={promoApproved}
            campaign={campaign}
            index={index}
            key={campaign.campaignID || 0}
            templates={templates}
            removeCampaign={removeCampaign}
          />
        ))}
      {newCampaigns &&
        newCampaigns.map((campaign, index) => (
          <CampaignForm
            promoID={promo.id}
            canEdit={canAddCampaigns}
            campaign={campaign}
            index={index}
            removeCampaign={removeCampaign}
            key={index}
            templates={templates}
          />
        ))}
      {canAddCampaigns && (
        <Box className={classes.rowPadded}>
          <Button
            variant="contained"
            className={classes.leftButton}
            onClick={addCampaign}
          >
            {t(`${tRef}.add`)}
          </Button>
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowPadded: {
      marginTop: '1rem',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginLeft: '1rem',
    },
    leftButton: {
      marginRight: '1rem',
    },
    date: {
      width: '12.5rem',
    },
    time: {
      width: '10rem',
    },
    dash: {
      marginRight: '1rem',
    },
    sentenceText: {
      marginRight: '1rem',
    },
    largeDropdown: {
      width: '15rem',
    },
    xlDropdown: {
      width: '30rem',
    },
    previewContainer: {
      width: '8rem',
      height: '14rem',
      border: `solid 2px ${colors.blue.pa100}`,
      marginRight: '2rem',
    },
    campaignFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    campaignContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    subjectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linearProgress: {
      margin: '2rem 2rem 2rem 2rem',
    },
  })
)

export default CampaignFormContainer
