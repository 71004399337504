import * as Sentry from '@sentry/browser'
import * as ram from '@talentinc/redux-axios-middleware'

import { AppThunkAction } from 'store'
import { Config } from './types'
import { ResettableInterval, CronSignature } from 'store/crons/types'

export enum ConfigActions {
  FETCH_CONFIG = 'FETCH_CONFIG',
  FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS',
  FETCH_CONFIG_FAIL = 'FETCH_CONFIG_FAIL',
  UPDATE_CONFIG = 'UPDATE_CONFIG',
  UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAIL = 'UPDATE_CONFIG_FAIL',
}

interface FetchConfig extends ram.AxiosMiddlewareActionCreator {
  type: ConfigActions.FETCH_CONFIG | ConfigActions.UPDATE_CONFIG
}

interface FetchConfigSuccess
  extends ram.AxiosMiddlewareActionSuccess<Config, FetchConfig> {
  type: ConfigActions.FETCH_CONFIG_SUCCESS | ConfigActions.UPDATE_CONFIG_SUCCESS
}

interface FetchConfigFail extends ram.AxiosMiddlewareActionFail<FetchConfig> {
  type: ConfigActions.FETCH_CONFIG_FAIL | ConfigActions.UPDATE_CONFIG_FAIL
}

export function fetchConfig(update = false): FetchConfig {
  return {
    type: !update ? ConfigActions.FETCH_CONFIG : ConfigActions.UPDATE_CONFIG,
    payload: {
      request: {
        url: '/v2/config',
        baseURL: process.env.REACT_APP_PORTAL_API_URL,
      },
    },
  }
}

export const fetchConfigAndConfigure =
  (): AppThunkAction => async (dispatch, getState) => {
    await dispatch(fetchConfig())

    const { config } = getState().config

    if (!config) {
      return
    }

    Sentry.init({
      ...config.sentry,
      ignoreErrors: [
        /Network Error/,
        'Non-Error promise rejection captured with keys: error, meta, type',
        'ResizeObserver loop limit exceeded',
        /Wrong email or password/,
      ],
    })

    const { currentUser } = getState().user

    if (currentUser) {
      Sentry.setUser({ id: currentUser.id.toString() })
    }
  }

export const updateConfigTimed =
  ({
    interval,
    initialize,
  }: CronSignature): AppThunkAction<ResettableInterval> =>
  async (dispatch) => {
    if (!initialize) {
      dispatch(fetchConfig(true))
    }

    return {
      interval,
      intervalID: window.setInterval(
        () => dispatch(fetchConfig(true)),
        interval
      ),
    }
  }

export type ConfigAction = FetchConfig | FetchConfigSuccess | FetchConfigFail
