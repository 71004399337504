import {
  DiscountBrand,
  DiscountPaymentPlan,
  DiscountState,
  DiscountToken,
  StickerPrice,
} from './types'

export const allDiscounts = (state: DiscountState): DiscountToken[] =>
  Object.values(state.discounts)
    .sort((a, b) => {
      if (!a.starts_at || !b.starts_at) return 0
      return b.starts_at.getTime() - a.starts_at.getTime()
    })
    .map((token) => ({
      ...token,
      usage_stats: state.usageStats[token.discount_token],
    }))

export const discountsNeedLoading = (state: DiscountState): boolean =>
  state.meta.FETCH_DISCOUNTS.loaded

export const stickerPricesNeedLoading = (state: DiscountState): boolean =>
  !state.meta.FETCH_STICKER_PRICES.loaded

const paymentPlansSort = (a: StickerPrice, b: StickerPrice): number => {
  if (a.plan_name < b.plan_name) {
    return -1
  }
  if (a.plan_name > b.plan_name) {
    return 1
  }
  return 0
}

export const allStickerPrices = (
  state: DiscountState,
  validStickerPricesOnly: boolean
): StickerPrice[] => {
  const prices = Object.values(state.stickerPrices)
  return prices
}

export const availablePaymentPlans = (state: DiscountState): StickerPrice[] => {
  const prices = Object.values(state.stickerPrices).filter(
    (p) => !p.is_sticker_price
  )
  prices.sort(paymentPlansSort)

  return prices
}

export const unSelectedStickerPrices = (
  state: DiscountState,
  discountToken: string | null
): StickerPrice[] => {
  if (!discountToken) return []
  const token = state.discounts[discountToken]
  if (!token) return []
  const selectedPlans = token.discount_plan_codes
  if (!selectedPlans) return []
  const selectedPlanCodes = new Set(selectedPlans.map((plan) => plan.plan_code))
  return allStickerPrices(state, true).filter(
    (plan) => !selectedPlanCodes.has(plan.plan_code)
  )
}

export const discountLoaded = (
  state: DiscountState,
  discountToken: string | null
) => {
  if (!discountToken) return false
  if (!state.meta.FETCH_DISCOUNT[discountToken]) return false
  return state.meta.FETCH_DISCOUNT[discountToken].loaded
}

export const discountNeedLoading = (
  state: DiscountState,
  discountToken: string | null
) => {
  if (!discountToken) return false
  return !(
    state.discounts[discountToken] && state.discounts[discountToken].starts_at
  )
}

export const discountLoadedOnce = (
  state: DiscountState,
  discountToken: string | null
) => {
  if (
    !discountToken ||
    !state.meta.FETCH_DISCOUNT[discountToken] ||
    !state.discounts[discountToken]
  )
    return false
  return (
    state.meta.FETCH_DISCOUNT[discountToken] &&
    state.meta.FETCH_DISCOUNT[discountToken].loaded
  )
}

export const discountPaymentPlans = (
  state: DiscountState,
  discountToken: string | null
): DiscountPaymentPlan[] => {
  if (!discountToken) return []
  const discount = state.discounts[discountToken]
  if (!discount || !discount.discount_plan_codes) return []
  return discount.discount_plan_codes.map((planCode) => ({
    ...state.stickerPrices[planCode.plan_code],
    discount_value: planCode.discount_value,
  }))
}

export const discountTokenWithPaymentPlans = (
  state: DiscountState,
  discountToken: string | null
): DiscountToken | null => {
  if (!discountToken) return null
  if (!state.discounts[discountToken]) return null
  return {
    ...state.discounts[discountToken],
    discount_payment_plans: discountPaymentPlans(state, discountToken),
    usage_stats: state.usageStats[discountToken],
  } as DiscountToken
}

export const availableCurrencyCodes = (state: DiscountState) =>
  Array.from(
    new Set<string>(
      Object.values(state.stickerPrices).map((p) => p.currency_code)
    )
  )

export const generatedUserTokens = (
  state: DiscountState,
  discountToken: string | null
): string[] => {
  if (!discountToken) return []
  const userTokensSet = state.userDiscountTokens[discountToken]
  if (!userTokensSet) return []
  return Array.from(userTokensSet)
}

export const usageStatsLoaded = (state: DiscountState) =>
  state.meta.FETCH_DISCOUNT_USAGE_STATS.loaded

export const discountBrandsLoaded = (
  state: DiscountState,
  discountToken: string
) =>
  state.meta.FETCH_DISCOUNT_BRANDS[discountToken] &&
  state.meta.FETCH_DISCOUNT_BRANDS[discountToken].loaded

export const discountBrands = (
  state: DiscountState,
  discountToken: string
): DiscountBrand[] =>
  state.brands[discountToken]
    ? Object.values(state.brands[discountToken]).sort((a, b) => {
        if (a.brand_name < b.brand_name) {
          return -1
        }
        if (a.brand_name > b.brand_name) {
          return 1
        }
        return 0
      })
    : []
