import {
  HashMap,
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils'
import { BrandActions } from './actions'

export interface UserBrand {
  id: number
  name: string
  logo: string
  favicon: string
  home_url: string
  portal_url: string
  checkout_url: string
  colors: (string | null)[]
  default_support_email: string | null
  default_phone_number: string | null
  default_resume_product: string | null
  site_id: number
}

export interface MailCampaignSequence {
  id: number
  sequence: string
}

export interface BrandState {
  currentBrand: UserBrand | null
  brands: HashMap<UserBrand>
  mailCampaignSequences: HashMap<MailCampaignSequence>
  meta: {
    [BrandActions.FETCH_BRANDS]: LoadingLoadedErrorState
    [BrandActions.FETCH_MAILCAMP_SEQ]: LoadingLoadedErrorState
  }
}

export const initialBrandState: BrandState = {
  currentBrand: null,
  brands: {},
  mailCampaignSequences: {},
  meta: {
    [BrandActions.FETCH_BRANDS]: { ...initialLoadedLoadingErrorState },
    [BrandActions.FETCH_MAILCAMP_SEQ]: {
      ...initialLoadedLoadingErrorState,
    },
  },
}
