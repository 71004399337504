import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { format as dateFormat } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useDispatch } from 'react-redux'
import useAlerts from 'hooks/useAlerts'
import useAsyncFn from 'react-use/lib/useAsyncFn'

import clsx from 'clsx'
import { colors } from 'styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'

import ValidationTextField from 'components/shared/ValidationTextField'
import ValidationSelect from 'components/shared/ValidationSelect'
import CohortSizeEstimateContainer from './SizeEstimates/CohortSizeEstimateContainer'
import LoaderButton from 'components/shared/LoaderButton'
import CohortBehaviorForm from './CohortBehaviorForm'

import { MailCampaignSequence } from 'store/brand/types'
import { EventType } from 'store/events/types'
import { createCohortPayload, updatePayloadWithCohort } from 'store/promo/utils'
import { createCohortThunk, updateCohortThunk } from 'store/promo/thunks'
import {
  ActionTypes,
  AudienceTypes,
  Behavior,
  BehaviorInputs,
  Cohort,
  CohortInputs,
  CohortPayload,
  CohortSources,
  Promo,
} from 'store/promo/types'
import { tryGetErrorMessage } from 'utils/errors'

interface DatabaseCohortProps {
  cohort: Cohort
  promoID: number
  canEdit: boolean
  campaignSequences: MailCampaignSequence[]
  promos: Promo[]
  eventTypes: EventType[]
  index: number
  removeCohort: (index: number) => void
  existingCohort: CohortPayload | null
}

const DatabaseCohort: React.FC<DatabaseCohortProps> = ({
  cohort,
  promoID,
  canEdit,
  campaignSequences,
  promos,
  eventTypes,
  index,
  removeCohort,
  existingCohort,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tRef = 'pages.Campaigns.sections.promos.form.audience'

  const dateFormatStr = 'yyyy-MM-dd'

  const defaultValues: CohortInputs = {
    promoID: promoID,
    cohortID: null,
    name: cohort.name || '',
    mailCampaignSequence: cohort.mailCampaignSequence || 0,
    audienceType: cohort.audienceType || AudienceTypes.Lead,
    leadScoreStart:
      cohort.leadScoreStart !== undefined ? cohort.leadScoreStart : 100,
    leadScoreEnd: cohort.leadScoreEnd !== undefined ? cohort.leadScoreEnd : 500,
    startDate: dateFormat(cohort.startDate || new Date(), dateFormatStr),
    endDate: dateFormat(cohort.endDate || new Date(), dateFormatStr),
    behaviors: cohort.behaviors.map((b) => ({
      startDate: dateFormat(b.startDate || new Date(), dateFormatStr),
      endDate: dateFormat(b.endDate || new Date(), dateFormatStr),
      engagementType: b.engagementType,
      engagementKey: b.engagementKey,
      actionType: b.actionType,
      behaviorID: b.behaviorID || null,
      system: b.system || '',
    })) || [{ actionType: ActionTypes.Did }],
  }

  const { infoAlert, errorAlert } = useAlerts()

  const methods = useForm<CohortInputs>({
    defaultValues: defaultValues,
  })

  const {
    fields: behaviorFields,
    append: behaviorAppend,
    remove: behaviorRemove,
  } = useFieldArray<BehaviorInputs>({
    name: `behaviors`,
    control: methods.control,
  })

  const addBehavior = useCallback(() => {
    behaviorAppend({ actionType: ActionTypes.Did })
  }, [behaviorAppend])

  const removeBehaviour = useCallback(
    (behaviorIndex) => {
      behaviorRemove(behaviorIndex)
    },
    [behaviorRemove]
  )

  const onSubmit = useCallback(
    async (data: CohortInputs) => {
      const cohortID = cohort.cohortID
      const timezone = 'America/New_York'
      if (cohortID && existingCohort) {
        const updatedCohort: Cohort = {
          cohortID: cohortID,
          name: `Promo ${promoID} Cohort`,
          source: CohortSources.Database,
          mailCampaignSequence: data.mailCampaignSequence,
          audienceType: data.audienceType,
          leadScoreStart: data.leadScoreStart,
          leadScoreEnd: data.leadScoreEnd,
          startDate: zonedTimeToUtc(data.startDate, timezone),
          endDate: zonedTimeToUtc(data.endDate, timezone),
          behaviors: data.behaviors.map(
            (b) =>
              ({
                behaviorID: Number(b.behaviorID),
                startDate: zonedTimeToUtc(b.startDate, timezone),
                endDate: zonedTimeToUtc(b.endDate, timezone),
                actionType: Number(b.actionType),
                engagementType: b.engagementType,
                engagementKey: b.engagementKey
                  ? b.engagementKey.toString()
                  : '',
                system: b.system,
              } as Behavior)
          ),
        }
        const payload = updatePayloadWithCohort(updatedCohort, existingCohort)
        const result = await dispatch(
          updateCohortThunk(promoID, cohortID, payload)
        )
        if (!result) {
          infoAlert('Changes saved!')
          return
        }
        errorAlert('Failed to save Audience: ' + tryGetErrorMessage(result))
      } else {
        const newCohort: Cohort = {
          cohortID: null,
          name: `Promo ${promoID} Cohort`,
          source: CohortSources.Database,
          mailCampaignSequence: data.mailCampaignSequence,
          audienceType: data.audienceType,
          leadScoreStart: data.leadScoreStart,
          leadScoreEnd: data.leadScoreEnd,
          startDate: zonedTimeToUtc(data.startDate, timezone),
          endDate: zonedTimeToUtc(data.endDate, timezone),
          behaviors: data.behaviors.map(
            (b) =>
              ({
                startDate: zonedTimeToUtc(b.startDate, timezone),
                endDate: zonedTimeToUtc(b.endDate, timezone),
                actionType: Number(b.actionType),
                engagementType: b.engagementType,
                engagementKey: b.engagementKey
                  ? b.engagementKey.toString()
                  : '',
                system: b.system,
              } as Behavior)
          ),
        }
        const payload = createCohortPayload(newCohort, promoID)
        const result = await dispatch(createCohortThunk(promoID, payload))
        if (!result) {
          removeCohort(index)
          infoAlert('Changes saved!')
          return
        }
        errorAlert('Failed to save Audience: ' + tryGetErrorMessage(result))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoAlert, errorAlert, promoID, cohort.cohortID]
  )

  const [submissionState, submitForm] = useAsyncFn(onSubmit, [onSubmit])

  return (
    <form onSubmit={methods.handleSubmit(submitForm)}>
      <input
        type="hidden"
        name="promoID"
        value={promoID}
        ref={methods.register}
      />
      <Container disableGutters className={classes.formGroup}>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.mailCampaignSequence`)}</InputLabel>
          <ValidationSelect
            name="mailCampaignSequence"
            inputRef={methods.register({ min: 1 })}
            defaultValue={defaultValues.mailCampaignSequence}
            isValid={!methods.errors.mailCampaignSequence}
            validationMessage={t(`${tRef}.validation.required`)}
            disabled={!canEdit}
          >
            <option value={0} key={0}>
              {t(`${tRef}.selectMailCampaignSequence`)}
            </option>
            {campaignSequences &&
              campaignSequences.map((campSeq) => (
                <option value={campSeq.id} key={campSeq.id}>
                  {campSeq.id} - {campSeq.sequence}
                </option>
              ))}
          </ValidationSelect>
        </Box>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.attribute`)}</InputLabel>
          <Controller
            as={
              <RadioGroup row aria-label={`audienceType`}>
                <FormControlLabel
                  value={AudienceTypes.Lead}
                  control={<Radio disabled={!canEdit} />}
                  label={t(`${tRef}.lead`)}
                  className={classes.radio}
                />
                <FormControlLabel
                  value={AudienceTypes.Customer}
                  control={<Radio disabled={!canEdit} />}
                  label={t(`${tRef}.customer`)}
                  className={classes.radio}
                />
              </RadioGroup>
            }
            name={`audienceType`}
            control={methods.control}
            rules={{ required: true }}
            defaultValue={defaultValues.audienceType}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.leadScoreRange`)}</InputLabel>
          <Box className={classes.row}>
            <ValidationTextField
              className={classes.leadScore}
              name="leadScoreStart"
              inputRef={methods.register({ min: 1, required: true })}
              defaultValue={defaultValues.leadScoreStart}
              isValid={!methods.errors.leadScoreStart}
              validationMessage={t(`${tRef}.validation.greaterThanZero`)}
              disabled={!canEdit}
              type="number"
            />
            <Typography variant="body1" className={classes.dash}>
              -
            </Typography>
            <ValidationTextField
              className={classes.leadScore}
              name="leadScoreEnd"
              inputRef={methods.register({ min: 1, required: true })}
              defaultValue={defaultValues.leadScoreEnd}
              isValid={!methods.errors.leadScoreEnd}
              validationMessage={t(`${tRef}.validation.greaterThanZero`)}
              disabled={!canEdit}
              type="number"
            />
          </Box>
        </Box>

        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.estimatedListSize`)}</InputLabel>
          {!cohort.cohortID && (
            <Typography variant="body2" className={classes.readOnly}>
              --
            </Typography>
          )}
          {cohort.cohortID && (
            <Box className={clsx(classes.loaderContainer, classes.readOnly)}>
              <CohortSizeEstimateContainer
                promoID={promoID}
                cohortID={cohort.cohortID}
              />
            </Box>
          )}
        </Box>
      </Container>
      <Container disableGutters className={classes.formGroup}>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.dateRange`)}</InputLabel>
          <Box className={classes.row}>
            <ValidationTextField
              className={classes.date}
              name="startDate"
              inputRef={methods.register({ required: true })}
              defaultValue={defaultValues.startDate}
              isValid={!methods.errors.startDate}
              validationMessage={t(`${tRef}.validation.required`)}
              disabled={!canEdit}
              type="date"
            />
            <Typography variant="body1" className={classes.dash}>
              -
            </Typography>
            <ValidationTextField
              className={classes.date}
              name="endDate"
              inputRef={methods.register({ required: true })}
              defaultValue={defaultValues.endDate}
              isValid={!methods.errors.endDate}
              validationMessage={t(`${tRef}.validation.required`)}
              disabled={!canEdit}
              type="date"
            />
          </Box>
        </Box>
      </Container>
      <Box>
        <Container disableGutters className={classes.formGroup}>
          <Typography variant="body2" className={classes.formLabel}>
            {t(`${tRef}.behavior.description`)}
          </Typography>
        </Container>
        <FormProvider {...methods}>
          {behaviorFields.map((behavior, behaviorIndex) => (
            <CohortBehaviorForm
              index={behaviorIndex}
              behavior={behavior}
              removeBehavior={removeBehaviour}
              key={behavior.id}
              promos={promos}
              eventTypes={eventTypes}
              canEdit={!cohort.cohortID}
            />
          ))}
        </FormProvider>

        {!cohort.cohortID && (
          <Button variant="text" color="secondary" onClick={addBehavior}>
            {t(`${tRef}.behavior.add`)}
          </Button>
        )}
      </Box>
      {canEdit && (
        <LoaderButton
          variant="contained"
          type="submit"
          loading={submissionState.loading}
        >
          {t(`${tRef}.save`)}
        </LoaderButton>
      )}
    </form>
  )
}

interface CohortProps {
  cohort: Cohort
  index: number
  promoID: number
  canEdit: boolean
  removeCohort: (index: number) => void
  campaignSequences: MailCampaignSequence[]
  promos: Promo[]
  eventTypes: EventType[]
  existingCohort: CohortPayload | null
}

interface CSVCohortProps {
  cohort: Cohort
  index: number
  promoID: number
  canEdit: boolean
  removeCohort: (index: number) => void
}

const CSVCohort: React.FC<CSVCohortProps> = ({ cohort, promoID }) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.csvAudience'
  const { t } = useTranslation()
  return (
    <Container disableGutters className={classes.formGroup}>
      <Box className={clsx(classes.fieldContainer, classes.csvFieldContainer)}>
        <Typography
          variant="body2"
          className={clsx(classes.formLabel, classes.csvNameLabel)}
        >
          {t(`${tRef}.name`)}
        </Typography>
        <Typography
          variant="body2"
          color="secondary"
          className={classes.csvName}
        >
          {cohort.name}
        </Typography>
      </Box>
      <Box className={clsx(classes.fieldContainer, classes.csvFieldContainer)}>
        <Typography variant="body2" className={classes.formLabel}>
          {t(`${tRef}.exclude`)}
        </Typography>
        <Typography
          variant="body2"
          color="secondary"
          className={classes.csvName}
        >
          <Switch
            name="checkedA"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color="primary"
            checked={!cohort.excludePurchasers}
            disabled
          />
        </Typography>
      </Box>
      <Box className={clsx(classes.fieldContainer, classes.csvFieldContainer)}>
        <Typography
          variant="body2"
          className={clsx(classes.formLabel, classes.csvSizeLabel)}
        >
          {t(`${tRef}.listSize`)}
        </Typography>
        {cohort.cohortID && (
          <CohortSizeEstimateContainer
            promoID={promoID}
            cohortID={cohort.cohortID}
            className={classes.csvSize}
          />
        )}
      </Box>
    </Container>
  )
}

const CohortForm: React.FC<CohortProps> = ({
  cohort,
  index,
  promoID,
  canEdit,
  removeCohort,
  campaignSequences,
  promos,
  eventTypes,
  existingCohort,
}) => {
  if (cohort.source === CohortSources.CSV)
    return (
      <CSVCohort
        cohort={cohort}
        index={index}
        promoID={promoID}
        canEdit={canEdit}
        removeCohort={removeCohort}
      />
    )
  return (
    <DatabaseCohort
      cohort={cohort}
      promoID={promoID}
      canEdit={canEdit}
      campaignSequences={campaignSequences}
      promos={promos}
      eventTypes={eventTypes}
      index={index}
      removeCohort={removeCohort}
      existingCohort={existingCohort}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowPadded: {
      marginTop: '1rem',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonsContainer: {
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: '1rem',
    },
    leftButton: {
      marginRight: '1rem',
    },
    formControl: {
      flexShrink: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    readOnly: {
      height: '2.3rem',
    },
    formLabel: {
      fontSize: '0.9rem',
      color: colors.blue.pa100,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    paddedHeader: {
      marginBottom: '1rem',
    },
    audienceContainer: {
      borderLeft: `solid 3px ${colors.blue.pa100}`,
      paddingLeft: '1rem',
      marginBottom: '1rem',
    },
    leadScore: {
      width: '6rem',
    },
    date: {
      width: '12.5rem',
    },
    time: {
      width: '10rem',
    },
    dash: {
      marginRight: '1rem',
    },
    removeIcon: {
      color: colors.red[600],
      width: '1.2rem',
      height: '1.2rem',
    },
    removeButton: {
      marginLeft: '1rem',
      width: '1.2rem',
      height: '1.2rem',
    },
    sentenceText: {
      marginRight: '1rem',
    },
    mediumDropdown: {
      width: '10rem',
    },
    largeDropdown: {
      width: '15rem',
    },
    xlDropdown: {
      width: '30rem',
    },
    previewContainer: {
      width: '8rem',
      height: '14rem',
      border: `solid 2px ${colors.blue.pa100}`,
      marginRight: '2rem',
    },
    campaignFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    campaignContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    subjectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mailSubject: {
      width: '40rem',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    csvName: {
      fontSize: '1.2rem',
    },
    csvNameLabel: {
      marginBottom: '0.5rem',
    },
    csvSizeLabel: {
      marginBottom: '1.05rem',
    },
    csvFieldContainer: {
      marginRight: '1rem',
    },
    radio: {
      fontSize: '0.95rem',
    },
    loaderContainer: {
      marginRight: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '0.2rem',
    },
    csvSize: {
      height: '1.35rem',
    },
  })
)

export default CohortForm
