import React from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import LightToolTip from 'components/shared/LightToolTip'

import { CampaignDeliveryStat } from 'store/promo/types'

interface Props {
  campaignStats: CampaignDeliveryStat[]
  title: string
}

const DeliveryStatsList: React.FC<Props> = ({ campaignStats, title }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.DeliveryStatsToolTip'

  return (
    <Box>
      <Typography variant="h2" className={classes.headerText}>
        {title}
      </Typography>
      {campaignStats.map((campaignStat) => (
        <Box>
          {campaignStat && (
            <Container disableGutters className={classes.row}>
              <Typography variant="h3" className={classes.headerText}>
                {campaignStat.templateName}
              </Typography>
            </Container>
          )}
          {campaignStat.promo_date_stats &&
            campaignStat.promo_date_stats.map((stat) => (
              <Box key={stat.utm_campaign}>
                <Container disableGutters className={classes.container}>
                  <Grid container direction="column" className={classes.grid}>
                    <Grid container item direction="row" xs={12}>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          className={clsx(
                            classes.status,
                            classes.completedText
                          )}
                        >
                          {t(`${tRef}.sent`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body2"
                          className={classes.completedText}
                        >
                          {stat.sent}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          className={clsx(
                            classes.status,
                            classes.completedText
                          )}
                        >
                          {t(`${tRef}.delivered`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body2"
                          className={classes.completedText}
                        >
                          {stat.delivered}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          className={clsx(
                            classes.status,
                            classes.completedText
                          )}
                        >
                          {t(`${tRef}.opened`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body2"
                          className={classes.completedText}
                        >
                          {stat.opened}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          className={clsx(
                            classes.status,
                            classes.completedText
                          )}
                        >
                          {t(`${tRef}.clicked`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body2"
                          className={classes.completedText}
                        >
                          {stat.clicked}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          className={clsx(
                            classes.status,
                            classes.completedText
                          )}
                        >
                          {t(`${tRef}.failed`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="body2"
                          className={classes.completedText}
                        >
                          {stat.failed}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  )
}

const DeliveryStatsTooltip: React.FC<Props> = ({
  campaignStats,
  children,
  title,
}) => {
  return (
    <LightToolTip
      title={<DeliveryStatsList campaignStats={campaignStats} title={title} />}
    >
      <div>{children}</div>
    </LightToolTip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      fontWeight: 450,
    },
    completedText: {
      color: theme.palette.secondary.main,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    grid: {
      marginLeft: '1rem',
    },
    headerText: {
      color: colors.gray[400],
      marginRight: '0.5rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

export default DeliveryStatsTooltip
