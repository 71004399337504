import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { format as dateFormat } from 'date-fns'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'

import PromoModalContainer from './PromoModalContainer'
import SendStatsContainer from './Stats/SendStatsContainer'
import DeliveryStatsContainer from './Stats/DeliveryStatsContainer'
import ConfirmationModal from 'components/shared/ConfirmationModal'
import StatusListToolTip from './Tooltips/StatusListTooltip'

import { AppState } from 'store'
import {
  CampaignPayload,
  Promo,
  PromoStatus,
  StatusBase,
} from 'store/promo/types'
import {
  campaignIsApproved,
  campaignsLoadedOnce,
  lastCampaignDate,
  nextCampaignDate,
  promoIsApproved,
  promoIsCancelled,
  promos,
  promosLoaded,
  promosNeedLoading,
  promoStatus,
} from 'store/promo/selector'

import useAlerts from 'hooks/useAlerts'
import { cancelPromoThunk } from 'store/promo/thunks'
import { fetchCampaigns, fetchPromos } from 'store/promo/actions'
import TableWrapper from 'components/shared/TableWrapper'
import usePagination from 'hooks/usePagination'
import { tryGetErrorMessage } from 'utils/errors'

interface PromoRowContainerProps {
  promo: Promo
  onEditClick: (promoID: number) => void
  onCancelClick: (promoID: number) => void
}

interface PromoRowProps extends PromoRowContainerProps {
  nextCampaign: CampaignPayload | null
  promoCancelled: boolean
  currentPromoStatus: PromoStatus | null
  promoApproved: boolean
  nextCampaignApproved: boolean
  lastCampaignID?: number | null
}

const PromoRow: React.FC<PromoRowProps> = ({
  promo,
  onEditClick,
  onCancelClick,
  nextCampaign,
  promoCancelled,
  currentPromoStatus,
  promoApproved,
  nextCampaignApproved,
  lastCampaignID,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const tRef = 'pages.Campaigns.sections.promos'
  let statusString = t(`${tRef}.statuses.${''}`)
  if (currentPromoStatus) {
    statusString = t(
      `${tRef}.statuses.${
        currentPromoStatus?.ended_at ? 'completed' : 'started'
      }.${currentPromoStatus.status}`
    )
  }
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{promo.id}</Typography>
      </TableCell>
      <TableCell>
        <Box className={classes.row}>
          <Typography variant="body2">{promo.name}</Typography>
          {promoApproved && (
            <Tooltip title="Promo approved" aria-label="add">
              <CheckBoxIcon className={classes.checkbox} />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {dateFormat(promo.created_at, t(`dateFormats.default.date`))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <Box className={classes.row}>
            {nextCampaign?.send_at
              ? dateFormat(
                  nextCampaign.send_at,
                  t(`dateFormats.default.dateTime`)
                )
              : '--'}
            {nextCampaignApproved && (
              <Tooltip title="Campaign approved" aria-label="add">
                <CheckBoxIcon className={classes.checkbox} />
              </Tooltip>
            )}
          </Box>
        </Typography>
      </TableCell>
      <TableCell>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="body2">{statusString}</Typography>
          </Grid>
          {promo.statuses.length > 0 && (
            <Grid item xs={3} className={classes.statusContainer}>
              <StatusListToolTip
                statuses={promo.statuses.map(
                  (status) => ({ ...status } as StatusBase)
                )}
              >
                <IconButton className={classes.statusButton}>
                  <InfoIcon className={classes.statusIcon} />
                </IconButton>
              </StatusListToolTip>
              <SendStatsContainer
                promoID={promo.id}
                promoApproved={promoApproved}
              />
              {lastCampaignID && (
                <DeliveryStatsContainer
                  promoID={promo.id}
                  promoApproved={promoApproved}
                  showLoader={true}
                />
              )}
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell>
        <Container disableGutters className={classes.rowControlsContainer}>
          <IconButton
            aria-label={t(`${tRef}.buttons.edit`)}
            onClick={() => onEditClick(promo.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label={t(`${tRef}.buttons.delete`)}
            onClick={() => onCancelClick(promo.id)}
            disabled={promoCancelled}
          >
            <DeleteIcon />
          </IconButton>
        </Container>
      </TableCell>
    </TableRow>
  )
}

const PromoRowContainer: React.FC<PromoRowContainerProps> = ({
  promo,
  onEditClick,
  onCancelClick,
}) => {
  const dispatch = useDispatch()

  const currentPromoStatus = useSelector<AppState, PromoStatus | null>(
    (state) => promoStatus(state.promos, promo.id),
    isEqual
  )

  const nextCampaign = useSelector<AppState, CampaignPayload | null>(
    (state) => nextCampaignDate(state.promos, promo.id),
    isEqual
  )

  const lastCampaign = useSelector<AppState, CampaignPayload | null>(
    (state) => lastCampaignDate(state.promos, promo.id),
    isEqual
  )

  const campaignApproved = useSelector<AppState, boolean>((state) =>
    campaignIsApproved(state.promos, nextCampaign?.id)
  )

  const promoCancelled = useSelector<AppState, boolean>((state) =>
    promoIsCancelled(state.promos, promo.id)
  )

  const promoApproved = useSelector<AppState, boolean>((state) =>
    promoIsApproved(state.promos, promo.id)
  )

  const loadedOnceCampaigns = useSelector<AppState, boolean>((state) =>
    campaignsLoadedOnce(state.promos, promo.id)
  )

  useEffect(() => {
    if (promoApproved && !loadedOnceCampaigns) {
      //fetching campaigns here so we can display the next send date
      dispatch(fetchCampaigns(promo.id))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [promoApproved, loadedOnceCampaigns, promo.id])

  return (
    <PromoRow
      promo={promo}
      onEditClick={onEditClick}
      onCancelClick={onCancelClick}
      currentPromoStatus={currentPromoStatus}
      nextCampaign={nextCampaign}
      lastCampaignID={lastCampaign?.id}
      promoCancelled={promoCancelled}
      promoApproved={promoApproved}
      nextCampaignApproved={campaignApproved}
    />
  )
}

interface PromoTableProps {
  promos: Promo[]
  onEditClick: (promoID: number) => void
  onCancelClick: (promoID: number) => void
}

const PromoTable: React.FC<PromoTableProps> = ({
  promos,
  onEditClick,
  onCancelClick,
}) => {
  const { t } = useTranslation()
  const tRef = 'pages.Campaigns.sections.promos'
  const classes = useStyles()

  const { pager, startIndex, endIndex } = usePagination(promos.length, 10)
  const paginatedPromos = promos.slice(startIndex, endIndex)

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.headerText}>
                {t(`${tRef}.columns.id`)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" className={classes.headerText}>
                {t(`${tRef}.columns.name`)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" className={classes.headerText}>
                {t(`${tRef}.columns.setupDate`)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" className={classes.headerText}>
                {t(`${tRef}.columns.nextRunDate`)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" className={classes.headerText}>
                {t(`${tRef}.columns.status`)}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedPromos.map((promo) => (
            <PromoRowContainer
              promo={promo}
              onEditClick={onEditClick}
              key={promo.id}
              onCancelClick={onCancelClick}
            />
          ))}
        </TableBody>
      </Table>
      {pager}
    </Box>
  )
}

interface CancelPromoProps {
  promoID: number | null
  onClose: () => void
}
const CancelPromoModalContainer: React.FC<CancelPromoProps> = ({
  promoID,
  onClose,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tRef = 'pages.Campaigns.sections.promos'
  const [cancelModalOpen, setCancelModalOpen] = useState(true)
  const { infoAlert, errorAlert } = useAlerts()

  const onConfirm = useCallback(async () => {
    setCancelModalOpen(false)
    if (promoID) {
      const resp = await dispatch(cancelPromoThunk(promoID))
      if (!resp) {
        infoAlert('Promo cancelled!')
        return
      }
      errorAlert('Failed to cancel Promo: ' + tryGetErrorMessage(resp))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoID, infoAlert, errorAlert])

  const onCancel = useCallback(() => {
    setCancelModalOpen(false)
    onClose()
  }, [setCancelModalOpen, onClose])

  if (!promoID) return null

  return (
    <>
      {cancelModalOpen && (
        <ConfirmationModal
          displayText={t(`${tRef}.cancelConfirmation`)}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  )
}

const CampaignsContainer: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns'
  const dispatch = useDispatch()

  const [formOpen, setFormOpen] = useState(false)
  const [selectedPromoID, setSelectedPromoID] = useState<number | null>(null)
  const [promoToCancelID, setPromoToCancelID] = useState<number | null>(null)

  const promotions = useSelector<AppState, Promo[]>(
    (state) => promos(state.promos),
    isEqual
  )

  const promoLoadingNeeded = useSelector<AppState, boolean>((state) =>
    promosNeedLoading(state.promos)
  )

  const promosReady = useSelector<AppState, boolean>((state) =>
    promosLoaded(state.promos)
  )

  const closeForm = useCallback(async () => {
    setFormOpen(false)
    setSelectedPromoID(null)
  }, [setFormOpen, setSelectedPromoID])

  const onAddClick = useCallback(async () => {
    setFormOpen(true)
  }, [setFormOpen])

  const onEditClick = useCallback(
    async (promoID: number) => {
      setFormOpen(true)
      setSelectedPromoID(promoID)
    },
    [setFormOpen, setSelectedPromoID]
  )

  const onCancelClick = useCallback(
    async (promoID: number) => {
      setPromoToCancelID(promoID)
    },
    [setPromoToCancelID]
  )

  const onPromoCancelModalClose = useCallback(async () => {
    setPromoToCancelID(null)
  }, [setPromoToCancelID])

  const setPromoID = useCallback(
    (id: number) => setSelectedPromoID(id),
    [setSelectedPromoID]
  )

  useEffect(() => {
    if (promoLoadingNeeded) {
      dispatch(fetchPromos())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoLoadingNeeded])

  return (
    <>
      <TableWrapper
        title={t(`${tRef}.sections.promos.title`)}
        isLoading={!promosReady}
        onAddClick={onAddClick}
      >
        {promotions.length > 0 && (
          <PromoTable
            promos={promotions}
            onEditClick={onEditClick}
            onCancelClick={onCancelClick}
          />
        )}
        {promotions.length === 0 && (
          <Container className={classes.emptyContainer}>
            <Typography variant="body2">
              {t(`${tRef}.sections.promos.empty`)}
            </Typography>
          </Container>
        )}
      </TableWrapper>

      <PromoModalContainer
        open={formOpen}
        onClose={closeForm}
        promoID={selectedPromoID}
        setPromoID={setPromoID}
      />
      <CancelPromoModalContainer
        promoID={promoToCancelID}
        onClose={onPromoCancelModalClose}
      />
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem',
    },
    sectionHeader: {
      marginLeft: '1rem',
      flexBasis: '80%',
    },
    container: {
      paddingBottom: '.5rem',
      paddingTop: '0.5rem',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowControlsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    formContainer: {
      padding: '1rem',
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonsContainer: {
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: '1rem',
    },
    formControl: {
      flexShrink: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    readOnly: {
      height: '2.3rem',
    },
    formLabel: {
      fontSize: '1.1rem',
      color: colors.blue.pa100,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    statusButton: {
      padding: '0.1rem',
      marginLeft: '0.5rem',
    },
    statusIcon: {
      width: '1.2rem',
      height: '1.2rem',
      color: colors.gray.pa100,
    },
    headerText: {
      fontWeight: 600,
    },
    checkbox: {
      marginLeft: '0.5rem',
      color: colors.green[400],
      width: '1.2rem',
      height: '1.2rem',
    },
  })
)

export default CampaignsContainer
