import React from 'react'
import { formatRelative } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import LightToolTip from 'components/shared/LightToolTip'

import { StatusBase } from 'store/promo/types'

interface Props {
  statuses: StatusBase[]
}

const StatusList: React.FC<Props> = ({ statuses }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos'
  const completedStatuses = statuses.filter((status) => status.ended_at)
  const completed =
    completedStatuses.length >= 10
      ? completedStatuses.slice(completedStatuses.length - 10)
      : completedStatuses
  const upcomming = statuses.filter((status) => !status.ended_at)
  return (
    <>
      {completedStatuses.length > 10 && (
        <Container disableGutters>
          <Typography variant="body2" className={classes.dots}>
            ...
          </Typography>
        </Container>
      )}

      <Grid container direction="column">
        {completed.map((status) => (
          <Grid
            container
            item
            direction="row"
            xs={12}
            key={`${status.status}-${status.started_at?.toISOString() || ''}`}
          >
            <Grid item xs={7}>
              <Typography
                variant="body2"
                className={clsx(classes.status, classes.completedText)}
              >
                {t(`${tRef}.statuses.completed.${status.status}`)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.completedText}>
                {status.ended_at
                  ? formatRelative(status.ended_at, new Date())
                  : '--'}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {upcomming.map((status) => (
          <Grid
            container
            item
            direction="row"
            xs={12}
            key={`${status.status}-${status.started_at?.toISOString() || ''}`}
          >
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.status}>
                {t(`${tRef}.statuses.started.${status.status}`)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">
                {status.ended_at
                  ? formatRelative(status.ended_at, new Date())
                  : '--'}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const StatusListToolTip: React.FC<Props> = ({ statuses, children }) => {
  return (
    <LightToolTip title={<StatusList statuses={statuses} />}>
      <div>{children}</div>
    </LightToolTip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      fontWeight: 450,
    },
    completedText: {
      color: theme.palette.secondary.main,
    },
    dots: {
      textAlign: 'center',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
  })
)

export default StatusListToolTip
