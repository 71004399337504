import React, { useCallback, useState } from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { ArrayField, useFieldArray, useForm } from 'react-hook-form'
import useAlerts from 'hooks/useAlerts'
import { useDispatch } from 'react-redux'
import { format as dateFormat } from 'date-fns'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import ValidationSelect from 'components/shared/ValidationSelect'
import ValidationTextField from 'components/shared/ValidationTextField'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import DiscountConfirmationModal from './DiscountConfirmationModal'

import Grid from '@material-ui/core/Grid'

import { createDiscountThunk, updateDiscountThunk } from 'store/discounts/thunk'

import {
  DiscountToken,
  StickerPrice,
  DiscountTokenTypes,
  DiscountAmountTypes,
  DiscountTokenPayload,
  DiscountConfirmationData,
  DiscountPaymentPlan,
  DiscountPlanCode,
} from 'store/discounts/types'
import Switch from '@material-ui/core/Switch'
import { tryGetErrorMessage } from 'utils/errors'

interface FormProps {
  discount: DiscountToken | null
  stickerPrices: StickerPrice[]
  onClose: () => void
  currencyCodes: string[]
}

interface DiscountInputs {
  discount_token: string
  type: DiscountTokenTypes
  short_description: string
  discount_amount: number | null
  discount_amount_type: string | null
  starts_at: string
  expires_at: string
  set_individual_prices: boolean
  selected_plan_codes: DiscountPaymentPlan[]
  days_to_live?: number | null
}

const DiscountForm: React.FC<FormProps> = ({
  discount,
  onClose,
  stickerPrices,
  currencyCodes,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const dateFormatStr = 'yyyy-MM-dd'
  const timezone = 'America/New_York'
  const tRef = 'components.Discounts.DiscountForm'

  const defaultValues: DiscountInputs = {
    discount_token: discount?.discount_token || '',
    type: discount ? discount['type'] : DiscountTokenTypes.Global,
    short_description: discount?.short_description || '',
    discount_amount: discount?.discount_percent
      ? discount.discount_percent * 100
      : 0,
    discount_amount_type:
      discount?.discount_percent && discount.discount_percent > 0
        ? DiscountAmountTypes.Percent
        : DiscountAmountTypes.Amount,
    starts_at: dateFormat(discount?.starts_at || new Date(), dateFormatStr),
    expires_at: dateFormat(discount?.expires_at || new Date(), dateFormatStr),
    set_individual_prices:
      discount &&
      (!discount.discount_percent || discount.discount_percent === 0)
        ? true
        : false,
    selected_plan_codes: discount?.discount_payment_plans || [],
    days_to_live: discount?.days_to_live,
  }

  const { infoAlert, errorAlert } = useAlerts()

  const { register, handleSubmit, errors, watch, control } =
    useForm<DiscountInputs>({
      defaultValues: defaultValues,
      shouldUnregister: false,
    })

  const {
    fields: planCodesFields,
    append: planCodesAppend,
    remove: planCodesRemove,
  } = useFieldArray<DiscountPaymentPlan>({
    name: `selected_plan_codes`,
    control: control,
  })

  const tokenType = watch('type')
  const setIndividualPrices = watch('set_individual_prices')
  const discountAmountType = watch('discount_amount_type')
  const startsAt = watch('starts_at')

  const canFinishSetup =
    tokenType === DiscountTokenTypes.Global || planCodesFields.length > 0

  //store the available sticker prices in local state so we can add and remove as needed
  const [availablePrices, setAvailablePrices] = useState<DiscountPaymentPlan[]>(
    [...stickerPrices]
  )
  const [filteredPrices, setFilteredPrices] = useState<DiscountPaymentPlan[]>([
    ...stickerPrices,
  ])
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalData, setConfirmationModalData] =
    useState<DiscountConfirmationData | null>(null)

  const [discountTokenPayload, setDiscountTokenPayload] =
    useState<DiscountTokenPayload | null>(null)

  const [searchText, setSearchText] = useState('')
  const [currency, setCurrency] = useState('USD')

  //Filter the list of available plan codes shown every time the search field or the currency changes
  const onSearchChange = useCallback(
    (event) => {
      setSearchText(event.target.value)
      const searchTextLower = event.target.value.toLowerCase()
      setFilteredPrices([
        ...availablePrices.filter(
          (price) =>
            price.currency_code === currency &&
            (price.plan_name.toLowerCase().includes(searchTextLower) ||
              price.plan_code.toLowerCase().includes(searchTextLower))
        ),
      ])
    },
    [currency, availablePrices]
  )

  const onCurrencyChange = useCallback(
    (event) => {
      const currentCode = event.target.value as string
      setCurrency(currentCode)
      setFilteredPrices([
        ...availablePrices.filter(
          (price) =>
            price.currency_code === currentCode &&
            (price.plan_name.includes(searchText) ||
              price.plan_code.includes(searchText))
        ),
      ])
    },
    [availablePrices, searchText]
  )

  //remove from the list of available sticker prices and add to the selected list
  const onAddStickerPrice = useCallback(
    (price: DiscountPaymentPlan, index: number) => {
      setAvailablePrices((aPrices) => [
        ...aPrices.filter((p) => p.plan_code !== price.plan_code),
      ])
      setFilteredPrices((sPrices) => [
        ...sPrices.slice(0, index),
        ...sPrices.slice(index + 1),
      ])
      planCodesAppend(price)
    },
    [planCodesAppend]
  )
  //remove from the list of selected sticker prices and add to the available list
  const onRemoveStickerPrice = useCallback(
    (
      index: number,
      fieldPrice:
        | DiscountPaymentPlan
        | Partial<ArrayField<DiscountPaymentPlan, 'id'>>
    ) => {
      const price: DiscountPaymentPlan = {
        plan_code: fieldPrice.plan_code || '',
        payment_plan_id: fieldPrice.payment_plan_id || 0,
        sticker_price: fieldPrice.sticker_price || 0,
        plan_name: fieldPrice.plan_name || '',
        is_sticker_price: !!fieldPrice.is_sticker_price,
        type: fieldPrice.type || '',
        currency_code: fieldPrice.currency_code || '',
        discount_value: fieldPrice.discount_value,
      }
      planCodesRemove(index)
      setAvailablePrices((aPrices) => [...aPrices, price])
      setFilteredPrices((sPrices) => [...sPrices, price])
    },
    [planCodesRemove]
  )

  const showConfirmationModal = useCallback(
    (modalData: DiscountConfirmationData) => {
      setConfirmationModalData(modalData)
      setConfirmationModalOpen(true)
    },
    []
  )

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false)
  }, [])

  //grab the form data from state and send it off to the api
  const onCreateDiscountConfirm = useCallback(async () => {
    if (discountTokenPayload) {
      if (discount) {
        const result = await dispatch(
          updateDiscountThunk(discountTokenPayload, discount.discount_token)
        )
        if (!result) {
          infoAlert('Changes saved!')
          onClose()
          return
        }
        errorAlert('Failed to save changes: ' + tryGetErrorMessage(result))
      } else {
        const result = await dispatch(createDiscountThunk(discountTokenPayload))
        if (!result) {
          infoAlert('Discount Token Created!')
          onClose()
          return
        }
        errorAlert(
          'Failed to create discount token: ' + tryGetErrorMessage(result)
        )
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [infoAlert, errorAlert, discountTokenPayload, onClose])

  const isValidDiscountAmount = useCallback(
    async (value: string) =>
      (discountAmountType === DiscountAmountTypes.Amount &&
        setIndividualPrices) ||
      Number(value) > 0,
    [setIndividualPrices, discountAmountType]
  )

  const isValidExpiryDate = useCallback(
    async (value: string) => {
      const expiresAtVal = new Date(value)
      const startsAtVal = new Date(startsAt)
      const expiresAtUtc = zonedTimeToUtc(value, 'America/New_York')
      const tomorrowUtc = new Date(new Date().toDateString())
      tomorrowUtc.setDate(tomorrowUtc.getDate() + 1)
      return (
        expiresAtVal.getTime() > startsAtVal.getTime() &&
        expiresAtUtc.getTime() >= tomorrowUtc.getTime()
      )
    },
    [startsAt]
  )

  const onSubmit = useCallback(
    async (data: DiscountInputs) => {
      const discountPercent = Number(data.discount_amount) / 100
      let discountPlanCodes: DiscountPlanCode[] = []

      if (data.selected_plan_codes && data.type !== DiscountTokenTypes.Global) {
        discountPlanCodes =
          data.discount_amount_type === DiscountAmountTypes.Amount
            ? data.selected_plan_codes.map((sPrice) => ({
                plan_code: sPrice.plan_code,
                discount_value: data.set_individual_prices
                  ? Number(sPrice.discount_value) || 0
                  : Number(data.discount_amount || 0),
              }))
            : data.selected_plan_codes.map((sPrice) => ({
                plan_code: sPrice.plan_code,
              }))
      }
      const payload: DiscountTokenPayload = {
        discount_token: data.discount_token,
        type: data.type,
        short_description: data.short_description,
        discount_percent:
          data.discount_amount_type === DiscountAmountTypes.Amount
            ? null
            : discountPercent,
        discount_plan_codes: discountPlanCodes,
        starts_at: zonedTimeToUtc(data.starts_at, timezone).toISOString(),
        expires_at: zonedTimeToUtc(data.expires_at, timezone).toISOString(),
        days_to_live: Number(data.days_to_live) || 0,
      }
      //store the payload in state for use when the confirm button is click
      setDiscountTokenPayload(payload)
      showConfirmationModal({
        discountCode: payload.discount_token,
        discountAmount: Number(data.discount_amount),
        discountAmountType: data.discount_amount_type as DiscountAmountTypes,
        affectedStickerPrices:
          data.type !== DiscountTokenTypes.Global
            ? [
                ...data.selected_plan_codes.map(
                  (plan) =>
                    ({
                      ...plan,
                      sticker_price: Number(plan.sticker_price),
                      discount_value: Number(plan.discount_value),
                    } as DiscountPaymentPlan)
                ),
              ]
            : stickerPrices,
        individualPrices: !!data.set_individual_prices,
      })
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [infoAlert, errorAlert, onClose]
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Container disableGutters className={classes.formGroup}>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.fields.type.label`)}</InputLabel>
              <ValidationSelect
                name="type"
                inputRef={register({
                  validate: (value) => value !== '',
                })}
                defaultValue={defaultValues.type}
                isValid={!errors.type}
                validationMessage={t(`${tRef}.validation.required`)}
              >
                {Object.values(DiscountTokenTypes).map((tType) => (
                  <option value={tType} key={tType}>
                    {t(`components.Discounts.types.${tType}`)}
                  </option>
                ))}
              </ValidationSelect>
            </Box>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.fields.code.label`)}</InputLabel>
              <ValidationTextField
                name="discount_token"
                inputRef={register({ required: true })}
                defaultValue={defaultValues.discount_token}
                isValid={!errors.discount_token}
                validationMessage={t(`${tRef}.validation.required`)}
              />
            </Box>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.fields.description.label`)}</InputLabel>
              <ValidationTextField
                className={classes.xlField}
                name="short_description"
                inputRef={register({ required: true })}
                defaultValue={defaultValues.short_description}
                isValid={!errors.short_description}
                validationMessage={t(`${tRef}.validation.required`)}
              />
            </Box>
          </Container>
          <Container disableGutters className={classes.formGroup}>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.fields.startDate.label`)}</InputLabel>
              <ValidationTextField
                name="starts_at"
                inputRef={register({
                  required: true,
                })}
                defaultValue={defaultValues.starts_at}
                isValid={!errors.starts_at}
                validationMessage={t(`${tRef}.validation.required`)}
                type="date"
              />
            </Box>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.fields.endDate.label`)}</InputLabel>
              <ValidationTextField
                name="expires_at"
                inputRef={register({
                  required: true,
                  validate: isValidExpiryDate,
                })}
                defaultValue={defaultValues.expires_at}
                isValid={!errors.expires_at}
                validationMessage={t(`${tRef}.validation.future`)}
                type="date"
              />
            </Box>
            <Box className={classes.fieldContainer}>
              <InputLabel>
                {t(`${tRef}.fields.discountAmountType.label`)}
              </InputLabel>
              <Box className={classes.row}>
                <ValidationTextField
                  name="discount_amount"
                  inputRef={register({ validate: isValidDiscountAmount })}
                  defaultValue={''}
                  isValid={!errors.discount_amount}
                  validationMessage={t(`${tRef}.validation.required`)}
                  type="number"
                  inputProps={{ step: 0.01 }}
                />
                <ValidationSelect
                  name="discount_amount_type"
                  inputRef={register({ required: true })}
                  defaultValue={''}
                  isValid={true}
                  validationMessage={t(`${tRef}.validation.required`)}
                >
                  <option value={'percent'} key={'percent'}>
                    {t(`${tRef}.fields.discountAmountType.percent`)}
                  </option>
                  {tokenType !== DiscountTokenTypes.Global && (
                    <option value={'amount'} key={'amount'}>
                      {t(`${tRef}.fields.discountAmountType.amount`)}
                    </option>
                  )}
                </ValidationSelect>
              </Box>
            </Box>
            {tokenType === DiscountTokenTypes.User && (
              <Box className={classes.fieldContainer}>
                <InputLabel>{t(`${tRef}.fields.daysToLive.label`)}</InputLabel>

                <ValidationTextField
                  name="days_to_live"
                  inputRef={register({ required: true, min: 1 })}
                  defaultValue={30}
                  isValid={!errors.discount_amount}
                  validationMessage={t(`${tRef}.validation.required`)}
                  type="number"
                  inputProps={{ step: 1 }}
                  className={classes.daysToLive}
                />
              </Box>
            )}
          </Container>
          {tokenType !== DiscountTokenTypes.Global && (
            <>
              <Container disableGutters className={classes.planCodesContainer}>
                <Box
                  className={clsx(classes.formGroup, classes.searchContainer)}
                >
                  <Box className={classes.fieldContainer}>
                    <InputLabel>
                      {t(`${tRef}.fields.currency.label`)}
                    </InputLabel>
                    <Select
                      onChange={(e) => onCurrencyChange(e)}
                      defaultValue={currency}
                    >
                      {currencyCodes.map((code) => (
                        <MenuItem value={code}>{code}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box className={classes.fieldContainer}>
                    <InputLabel>
                      {t(`${tRef}.fields.planCode.label`)}
                    </InputLabel>
                    <TextField
                      className={classes.search}
                      onChange={onSearchChange}
                      defaultValue={searchText}
                      placeholder={t(`${tRef}.fields.planCode.placeHolder`)}
                    />
                  </Box>
                  {discountAmountType &&
                    discountAmountType === DiscountAmountTypes.Amount && (
                      <Box className={classes.fieldContainer}>
                        <InputLabel>
                          {t(`${tRef}.fields.setIndividualPrices.label`)}
                        </InputLabel>
                        <Switch
                          color="primary"
                          name="set_individual_prices"
                          inputRef={register}
                          defaultChecked={defaultValues.set_individual_prices}
                        />
                      </Box>
                    )}
                </Box>

                {searchText.length > 1 && (
                  <Accordion className={classes.accordian} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h3">
                        {t(`${tRef}.availablePlanCodes`, {
                          number: filteredPrices.length,
                        })}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid className={clsx(classes.grid, classes.scrollable)}>
                        {filteredPrices.map((price, pIndex) => (
                          <Grid container item xs={12}>
                            <Grid container item xs={5}>
                              <Typography
                                variant="body2"
                                className={classes.planNameResult}
                              >
                                {price.plan_name}
                              </Typography>
                              <Typography
                                variant="body2"
                                className={classes.planCodeResult}
                              >
                                {`(${price.plan_code})`}
                              </Typography>
                            </Grid>
                            <Grid container item xs={1}>
                              <Typography
                                variant="body2"
                                className={classes.priceResult}
                              >
                                {`$${price.sticker_price} (${price.currency_code})`}
                              </Typography>
                            </Grid>
                            <Grid container item xs={1}>
                              <Button
                                variant="text"
                                color="secondary"
                                className={classes.addButton}
                                onClick={() => onAddStickerPrice(price, pIndex)}
                              >
                                {t(`${tRef}.add`)}
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {planCodesFields.length > 0 && (
                  <Accordion className={classes.accordian}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h3">
                        {t(`${tRef}.selectedPlanCodes`, {
                          number: planCodesFields.length,
                        })}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid className={classes.grid}>
                        {planCodesFields.map((price, pIndex) => (
                          <>
                            <input
                              type="hidden"
                              name={`selected_plan_codes[${pIndex}].plan_code`}
                              value={price.plan_code}
                              ref={register}
                            />
                            <input
                              type="hidden"
                              name={`selected_plan_codes[${pIndex}].payment_plan_id`}
                              value={price.payment_plan_id}
                              ref={register}
                            />
                            <input
                              type="hidden"
                              name={`selected_plan_codes[${pIndex}].sticker_price`}
                              value={price.sticker_price}
                              ref={register}
                            />
                            <input
                              type="hidden"
                              name={`selected_plan_codes[${pIndex}].plan_name`}
                              value={price.plan_name}
                              ref={register}
                            />
                            <input
                              type="hidden"
                              name={`selected_plan_codes[${pIndex}].currency_code`}
                              value={price.currency_code}
                              ref={register}
                            />
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.selectedItemsRow}
                            >
                              <Grid container item xs={5}>
                                <Typography
                                  variant="body2"
                                  className={classes.planNameResult}
                                >
                                  {price.plan_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.planCodeResult}
                                >
                                  {`(${price.plan_code})`}
                                </Typography>
                              </Grid>
                              <Grid container item xs={1}>
                                <Typography
                                  variant="body2"
                                  className={classes.priceResult}
                                >
                                  {`$${price.sticker_price} (${price.currency_code})`}
                                </Typography>
                              </Grid>
                              {setIndividualPrices &&
                                discountAmountType ===
                                  DiscountAmountTypes.Amount && (
                                  <Grid container item xs={2}>
                                    <ValidationTextField
                                      placeholder="Discount amount"
                                      className={classes.individualPrices}
                                      defaultValue={price.discount_value}
                                      type="number"
                                      name={`selected_plan_codes[${pIndex}].discount_value`}
                                      inputRef={register({
                                        required: true,
                                        min: 1,
                                        max: price.sticker_price || 100,
                                      })}
                                      validationMessage={t(
                                        `${tRef}.validation.discountAmount`
                                      )}
                                      isValid={
                                        !(
                                          errors.selected_plan_codes &&
                                          errors.selected_plan_codes[pIndex]
                                            ?.discount_value
                                        )
                                      }
                                    />
                                  </Grid>
                                )}

                              <Grid container item xs={1}>
                                <Button
                                  variant="text"
                                  color="secondary"
                                  className={classes.addButton}
                                  onClick={() =>
                                    onRemoveStickerPrice(pIndex, price)
                                  }
                                >
                                  {t(`${tRef}.remove`)}
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Container>
            </>
          )}
          <Container disableGutters className={classes.footerContainer}>
            <Typography variant="body2" color="secondary">
              *{t(`${tRef}.requiredFields`)}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              color="primary"
              disabled={!canFinishSetup}
            >
              {!discount ? t(`${tRef}.finishSetup`) : t(`${tRef}.saveChanges`)}
            </Button>
          </Container>
        </Box>
      </form>
      <DiscountConfirmationModal
        data={confirmationModalData}
        onClose={closeConfirmationModal}
        open={confirmationModalOpen}
        onConfirm={onCreateDiscountConfirm}
      />
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: {
      marginLeft: '1rem',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    xlField: {
      width: '30rem',
    },
    search: {
      width: '30rem',
    },
    searchContainer: {
      marginTop: '1rem',
    },
    planNameResult: {
      marginRight: '0.25rem',
      fontSize: '1rem',
    },
    planCodeResult: {
      marginRight: '1rem',
    },
    priceResult: {
      fontWeight: 'bold',
    },
    addButton: {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      height: '1.5rem',
    },
    planCodesContainer: {
      margin: '1rem 0rem 1rem 0rem',
    },
    grid: {
      width: '100%',
    },
    scrollable: {
      maxHeight: '10rem',
      overflow: 'auto',
    },
    accordian: {
      marginBottom: '1rem',
    },
    individualPrices: {
      width: '20rem',
      marginBottom: '0.5rem',
    },
    selectedItemsRow: {
      alignItems: 'center',
    },
    daysToLive: {
      width: '7rem',
    },
  })
)

export default DiscountForm
