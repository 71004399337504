import { AlertsContext } from 'components/shared/AlertsProvider'
import { useCallback, useContext } from 'react'

/*
  A simple react hook that can be used to access the infoAlert and errorAlert hooks from any component
  It makes use of the context defined in AlertsProvider.tsx
*/
function useAlerts() {
  //this is to make the linter happy, but it should never get called...
  const dummyAlert = useCallback((mesasge: string) => {
    /* eslint-disable-next-line no-console */
    console.log('Missing info alert function')
  }, [])

  const { infoAlert: info, errorAlert: err } = useContext(AlertsContext)
  const infoAlert = info || dummyAlert
  const errorAlert = err || dummyAlert

  return { infoAlert, errorAlert }
}

export default useAlerts
