import { DiscountTokenTypes } from 'store/discounts/types'
import {
  ActionTypes,
  CampaignStatues,
  EngagementTypes,
  EventSystems,
  PromoStatuses,
} from 'store/promo/types'

const en = {
  // Please try to nest your translation keys to somewhat match the path to the
  // component.
  translation: {
    pages: {
      Shell: {
        Campaigns: 'Campaigns',
        Discounts: 'Discounts',
        logout: 'Logout',
        Partner: 'Partner',
        Content: 'Content',
        PR: 'PR',
        Reporting: 'Reporting',
        Marketing: 'Marketing',
        Commerce: 'Commerce',
        Banners: 'Banners',
      },
      Auth: {
        Login: {
          pageTitle: 'Products Admin - Talent Inc.',
        },
      },
      Campaigns: {
        pageTitle: 'Campaigns',
        sections: {
          promos: {
            title: 'Promotion Tracking',
            empty: 'There are no Promotions yet',
            cancelConfirmation:
              'Are you sure you want to cancel this promo. This will stop any running processes and cannot be undone.',
            columns: {
              id: 'ID',
              name: 'Promo name',
              setupDate: 'Setup date',
              launchDate: 'Launch date',
              status: 'Promo Status',
              nextRunDate: 'Next run date',
            },
            form: {
              title: 'Create a promo',
              promoName: '',
              macroListSize: 'Macro List Size',
              save: 'Save',
              saveAndContinue: 'Save and Continue',
              saveChangesConfirmation:
                'Are you sure you want to exit? Any unsaved changes will be lost.',
              audience: {
                add: 'Add Another Audience',
                addViaCSV: 'Add via CSV',
                uploadCSV: 'Upload CSV',
                uploadFileName: 'Upload {{fileName}}',
                changeFile: 'Change File',
                targetAudience: 'Target Audience {{number}}',
                brandCategory: 'Brand Category',
                mailCampaignSequence: 'Mail Campaign Sequence',
                attribute: 'Attribute',
                lead: 'Lead',
                customer: 'Customer',
                leadScoreRange: 'Lead Score Range',
                dateRange: 'Date Range',
                estimatedListSize: 'Est. List Size',
                save: 'Save Audience',
                deleteConfirmation:
                  'Are you sure you want to delete this Cohort? This cannot be undone',
                excludePurchasers: 'Exclude Purchasers',
                selectMailCampaignSequence: 'Select Mail Campaign Sequence',
                behavior: {
                  add: 'Add another engagement',
                  track: 'Track traffic that',
                  between: 'between',
                  and: 'and',
                  description: 'Behavior Description',
                  selectBehavior: 'Select a Behavior',
                  selectSystem: 'Select a System',
                  actionTypes: {
                    [ActionTypes.Did]: 'did',
                    [ActionTypes.DidNot]: 'did not',
                  },
                  engagementTypes: {
                    [EngagementTypes.CritiqueFirstViewed]:
                      'Critique First Viewed',
                    [EngagementTypes.MailCampaignSequence]:
                      'Mail Campaign Sequence',
                    [EngagementTypes.OpenedPromoEmail]: 'Opened Promo Email',
                    [EngagementTypes.ClickedPromoEmail]: 'Clicked Promo Email',
                    [EngagementTypes.DeliveredPromoEmail]: 'Sent Promo Email',
                    [EngagementTypes.AramisEvent]: 'Aramis Event',
                  },
                  eventSystems: {
                    [EventSystems.OMSUser]: 'OMS (User)',
                    [EventSystems.OMSClient]: 'OMS (Client)',
                    [EventSystems.ZipJob]: 'ZipJob',
                    [EventSystems.Portal]: 'Portal',
                    [EventSystems.Products]: 'Products',
                  },
                  validation: {
                    required: 'This field is required',
                  },
                },
                validation: {
                  required: 'This field is required',
                  greaterThanZero: 'This value must be greater than zero',
                },
              },
              csvAudience: {
                name: 'CSV Name',
                exclude: 'Exclude Purchasers',
                listSize: 'List Size',
              },
              audienceEsclusion: {
                header: 'Audience Exclusion',
                name: 'CSV Name',
                listSize: 'List Size',
              },
              campaign: {
                test: 'Test Email',
                emailTemplate: 'Email Template',
                launchDateAndTime: 'Launch Date and Time',
                timezone: 'Timezone',
                add: 'Add Another Email Campaign',
                delete: 'Delete',
                at: 'at',
                save: 'Save Campaign',
                deleteConfirmation:
                  'Are you sure you want to delete this Campaign? This cannot be undone',
                send: 'Send',
                sendTo: 'Send To',
                currentUser: 'Current User',
                custom: 'Custom',
                brand: 'Brand',
                email: 'Email Address',
                sendTest: 'Send Test Email',
                selectBrand: 'Select Site',
                selectTemplate: 'Select a Template',
                validation: {
                  required: 'This field is required',
                  lauchDateandTime:
                    'This field is required and must be at least 3 hours in the future',
                },
              },
              audienceConfirmation:
                'I confirm that the audiences has been tested and verified',
              emailConfirmation:
                'I confirm that all emails have been tested and verified',
              finishLater: 'Finish Later',
              finishSetup: 'Finish Setup',
              validation: {
                promoName: 'Promo Name is required',
              },
            },
            buttons: {
              copy: 'Copy',
              edit: 'Edit',
              delete: 'Delete',
            },
            statuses: {
              '': 'Pending',
              started: {
                [PromoStatuses.Approved]: 'Promo Approved',
                [PromoStatuses.Cancelled]: 'Promo Cancelled',
                [PromoStatuses.BuildingUserList]: 'Building User List',
                [PromoStatuses.PromoError]: ' Promo Error',
                [PromoStatuses.ExclusionListFirstRun]:
                  'Running Initial User Exclusion',
                [PromoStatuses.CSVExclusion]: 'Running CSV User Exclusion',
                [CampaignStatues.Rendering]: 'Campaign Rendering',
                [CampaignStatues.Error]: ' Campaign Error',
                [CampaignStatues.Approved]: ' Campaign Approved',
              },
              completed: {
                [PromoStatuses.Approved]: 'Promo Approved',
                [PromoStatuses.Cancelled]: 'Promo Cancelled',
                [PromoStatuses.BuildingUserList]: 'Finished Building User List',
                [PromoStatuses.PromoError]: ' Promo Error',
                [PromoStatuses.ExclusionListFirstRun]:
                  'Finished Running Initial User Exclusion',
                [PromoStatuses.CSVExclusion]:
                  'Finished Running CSV User Exclusion',
                [CampaignStatues.Rendering]: 'Finished Campaign Rendering',
                [CampaignStatues.Error]: ' Campaign Error',
                [CampaignStatues.Approved]: ' Campaign Approved',
              },
            },
            SendStatsToolTip: {
              ready: 'Ready',
              inFlight: 'In-Flight',
              delayed: 'Delayed',
              title: 'Email Send Stats',
            },
            DeliveryStatsToolTip: {
              sent: 'Sent',
              delivered: 'Delivered',
              opened: 'Opened',
              clicked: 'Clicked',
              failed: 'Failed',
              loading: 'Loading delivery stats',
              title: 'Email Delivery Stats',
            },
          },
        },
      },
      Discounts: {
        pageTitle: 'Discounts',
      },
      Banners: {
        pageTitle: 'Banners',
      },
      Home: {
        welcome: 'Welcome to Talent Inc. Products Admin',
      },
    },
    components: {
      Auth: {
        Login: {
          header: 'Products Admin',
        },
      },
      ConfirmationModal: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      Discounts: {
        DiscountsTable: {
          title: 'Discount Tokens',
          empty: 'There are no Discount Tokens yet',
          deleteConfirmation:
            'Are you sure you want to delete this Discount Token? This cannot be undone.',
          columns: {
            code: 'Code',
            description: 'Description',
            use: 'Use',
            discount: 'Discount',
            uses: 'Logged uses',
            revenue: 'Revenue',
          },
          Details: {
            BrandUrls: {
              empty: 'No Brand URLs Available',
              heading: 'Brand URLs',
            },
          },
        },
        DiscountForm: {
          title: 'Create a discount',
          titleEdit: 'Manage discount',
          finishSetup: 'Finish Setup',
          saveChanges: 'Save Changes',
          requiredFields: 'required fields',
          add: 'Add',
          remove: 'Remove',
          availablePlanCodes: 'Available Plan Codes ({{number}})',
          selectedPlanCodes: 'Selected Plan Codes ({{number}})',
          fields: {
            type: {
              label: 'Type',
              placeHolder: 'Select a discount type',
            },
            code: {
              label: 'Discount Code',
            },
            description: {
              label: 'Description',
            },
            startDate: {
              label: 'Start Date',
            },
            endDate: {
              label: 'End Date',
            },
            discountAmountType: {
              label: 'Discount Amount',
              placeHolder: 'Select amount type',
              percent: 'Percent',
              amount: 'Amount',
            },
            discountAmount: {
              placeHolder: 'Discount amount',
            },
            currency: {
              label: 'Currency',
            },
            planCode: {
              label: 'Plan Code',
              placeHolder: 'Start typing to add Plan Codes',
            },
            setIndividualPrices: {
              label: 'Individual Plan Code Discounts',
            },
            daysToLive: {
              label: 'Days to Live',
            },
          },
          validation: {
            required: 'This field is required',
            future:
              'This date must be in the future and greater than the start date',
            discountAmount:
              'This value must be greater than zero and less than the original price',
            lessThanEnd:
              'This date is required and must be less than the End Date',
          },
        },
        ConfirmationForm: {
          title: 'Confirm discount',
          labels: {
            discountCode: 'Discount Code',
            discountAmount: 'Discount Amount',
            currency: 'Currency',
            before: 'Before',
            after: 'After',
            custom: 'Custom',
          },
          buttons: {
            back: 'Back',
            confirm: 'Confirm',
          },
        },
        AddUserTokenModal: {
          affectedPlanCodes: 'Eligible Plan Codes ({{number}})',
          recentlyGenerated: 'Recently Generated Tokens ({{number}})',
          generate: 'Generate',
          delete: 'Delete',
          title: 'Generate Discount Token for User',
          fields: {
            discountCode: {
              label: 'Discount Code',
            },
            discountAmount: {
              label: 'Discount Amount',
            },
            expires: {
              label: 'Expires',
              value: '{{daysToLive}} Days',
            },
          },
        },
        StickerPricesTable: {
          title: 'Sticker Prices',
          empty: 'There are no Sticker Prices yet',
          deleteConfirmation:
            'Are you sure you want to delete this Sticker Price? This cannot be undone.',
          columns: {
            code: 'Plan Code',
            name: 'Plan Name',
            price: 'Price',
            currency: 'Currency',
          },
        },
        buttons: {
          edit: 'Edit',
          expand: 'Expand',
        },
        types: {
          [DiscountTokenTypes.Global]: 'Public',
          [DiscountTokenTypes.PerPlanCode]: 'Per Plan Code',
          [DiscountTokenTypes.User]: 'Individual',
        },
      },
      Banners: {
        BannersContainer: {
          ctaLink: {
            placeholder: '/resume-writing?dt=SAVE35',
          },
        },
      },
    },
    routes: {
      home: '/',
      login: '/login',
      logout: '/logout',
      resetPassword: '/reset-password',
      campaigns: '/campaigns',
      discounts: '/discounts',
      banners: '/banners',
    },
    dateFormats: {
      default: {
        date: 'MMM d, yyyy',
        dateTime: "MMM d, yyyy 'at'  h:mm a",
      },
    },
  },
}

export default en
