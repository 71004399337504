import { BrandState } from './types'

export const brandsNeedLoading = (state: BrandState) =>
  !state.meta.FETCH_BRANDS.loaded

export const campaignSequencesNeedLoading = (state: BrandState) =>
  !state.meta.FETCH_MAILCAMP_SEQ.loaded

export const allBrands = (state: BrandState) => Object.values(state.brands)

export const allCampaignSequences = (state: BrandState) =>
  Object.values(state.mailCampaignSequences)

export const activeMailCampaignSequences = (state: BrandState) => {
  const activeIDs = new Set([33, 34, 32, 35, 39])
  //filter out un-used mail campaign sequences
  return Object.values(state.mailCampaignSequences).filter((seq) =>
    activeIDs.has(seq.id)
  )
}
