import {
  HashMap,
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils'

import { PromoActions } from './actions'

export const TopResumeSiteID = 1

export enum CohortSources {
  Database = 'database',
  CSV = 'csv_upload',
}

export interface Cohort {
  cohortID: number | null
  name?: string
  source: CohortSources
  mailCampaignSequence?: number
  audienceType?: AudienceTypes
  leadScoreStart?: number
  leadScoreEnd?: number
  startDate?: Date
  endDate?: Date
  estimatedSize?: number
  s3Location?: string
  behaviors: Behavior[]
  excludePurchasers?: boolean
}

export enum EventSystems {
  OMSUser = 'oms-user',
  OMSClient = 'oms-client',
  ZipJob = 'zip-job',
  Portal = 'portal',
  Products = 'products',
}

export interface Behavior {
  behaviorID?: number
  actionType: ActionTypes
  engagementType?: EngagementTypes
  engagementKey?: string
  system?: EventSystems
  startDate?: Date
  endDate?: Date
}

export enum EngagementTypes {
  CritiqueFirstViewed = 'critique_first_viewed',
  MailCampaignSequence = 'mail_campaign_sequence',
  OpenedPromoEmail = 'opened_promo',
  ClickedPromoEmail = 'clicked_promo',
  DeliveredPromoEmail = 'delivered_promo',
  AramisEvent = 'event_in_aramis',
  CSVUpload = 'csv_upload',
  ExcludePurchasers = 'exclude_purchasers',
  LeadCreated = 'user_created',
  CustomerCreated = 'customer_created',
  LeadScore = 'lead_score',
}

export const GenericEngagementTypes = [
  EngagementTypes.OpenedPromoEmail,
  EngagementTypes.ClickedPromoEmail,
  EngagementTypes.DeliveredPromoEmail,
  EngagementTypes.AramisEvent,
]

export enum ActionTypes {
  Did = 1,
  DidNot = 0,
}

export enum AudienceTypes {
  Lead = 'lead',
  Customer = 'customer',
}

export enum CampaignStatues {
  Approved = 'promo_date_approved',
  Cancelled = 'promo_date_cancelled',
  Rendering = 'promo_date_rendering',
  Error = 'promo_date_error',
}

export enum PromoStatuses {
  Approved = 'promo_approved',
  Cancelled = 'promo_cancelled',
  BuildingUserList = 'building_user_list',
  PromoError = 'promo_error',
  ExclusionListFirstRun = 'initial_user_exclusion',
  CSVExclusion = 'excluding_by_csv',
}

export interface StatusBase {
  status: string
  started_at: Date | null
  ended_at: Date | null
}

export interface PromoStatus extends Omit<StatusBase, 'status'> {
  id: number
  promo_id: number
  date_id: number | null
  cohort_id: number | null
  status: CampaignStatues | PromoStatuses
  created_at: Date
  updated_at: Date
}

export interface PromoBase {
  name: string
  description: string | null
  exclusion_list_s3_location?: string | null
  utm_campaign_base?: string
}

export interface Promo extends PromoBase {
  id: number
  created_at: Date
  updated_at?: Date
  statuses: PromoStatus[]
}

export interface PromoWithCohortsAndCampaigns extends Promo {
  campaigns: Campaign[]
  cohorts: Cohort[]
}

export interface PromoFilterSettingBase {
  field: string
  value: string | null
}

export interface PromoFilterSetting extends PromoFilterSettingBase {
  id: number
  created_at: Date
  updated_at: Date
}

export interface PromoFilterBase {
  include: boolean
  type: EngagementTypes
  name: string
  settings: PromoFilterSettingBase[]
}

export interface PromoFilter extends Omit<PromoFilterBase, 'settings'> {
  id: number
  cohort_id: number
  settings: PromoFilterSetting[]
  created_at: Date
  updated_at: Date
}

export enum PromoFilterFields {
  StartDate = 'start_date',
  EndDate = 'end_date',
  MailCampaignSequenceID = 'mail_campaign_sequence_id',
  PromoID = 'promo_id',
  EventTypeID = 'event_type_id',
  MinLeadScore = 'min_lead_score',
  MaxLeadScore = 'max_lead_score',
  S3Location = 's3_location',
  UserID = 'user_id',
  Email = 'email',
  System = 'system',
}

export enum PromoSendTypes {
  UTC = 'UTC',
}

export interface CohortPayloadBase {
  promo_id: number
  name: string | null
  filters: PromoFilterBase[]
}

export interface CohortPayload extends Omit<CohortPayloadBase, 'filters'> {
  id: number
  filters: PromoFilter[]
  created_at?: Date
  updated_at?: Date
}

export interface CampaignTemplateBase {
  email_template_id: number
  utm_campaign?: string
  experiment_id?: number
  experiment_case_id?: number
}

export interface CampaignTemplate extends CampaignTemplateBase {
  id: number
  date_id: number
  created_at: Date
  updated_at: Date | null
}

export interface UpdateCampaignTemplatePayload extends CampaignTemplateBase {
  id: number
  date_id: number
}

export interface CampaignPayloadBase {
  templates: CampaignTemplateBase[]
  send_at: Date | null
  send_type: string
  sqs_arn?: string | null
}

export interface CampaignPayload
  extends Omit<CampaignPayloadBase, 'templates'> {
  id: number
  created_at: Date
  updated_at: Date
  statuses?: PromoStatus[]
  templates: CampaignTemplate[]
}

export interface UpdateCampaignPayload
  extends Omit<CampaignPayloadBase, 'templates'> {
  promo_id: number
  templates: UpdateCampaignTemplatePayload[]
}

export interface Campaign {
  campaignID?: number
  subject?: string | null
  emailTemplateID?: number
  sendAtDate?: string
  sendAtTime?: string
  sendAtTimezone?: string
  statuses?: PromoStatus[]
  templates?: CampaignTemplate[]
}

export interface EmailTemplate {
  id: number
  template: string | null
  from_name: string | null
  from_email: string | null
  subject: string | null
  body: string | null
  link_params: string | null
  discount_token: string | null
  updated_at: Date
}

export interface TestEmailTemplatePayload {
  email_template_id: number | null
  promo_date_id: number | null
  send_now: boolean
  email: string | null
  utm_campaign: string | null
  site_id: number | null
  lead_email: string | null
}

export interface SQSQueueMessageCount {
  queue_exists: boolean
  delayed: number
  inflight: number
  good: number
}

export interface CampaignSendStat {
  templateName?: string
  message_counts?: SQSQueueMessageCount
}

export interface UserListEstimate {
  estimate: number
}

export interface UTMCampaignDeliveryStat {
  utm_campaign: string
  template: string | null
  sent: number
  delivered: number
  opened: number
  clicked: number
  failed: number
}

export interface CampaignDeliveryStat {
  templateName?: string
  promo_date_stats: UTMCampaignDeliveryStat[] | null
}

export interface BehaviorInputs {
  behaviorID: number | null
  actionType: ActionTypes
  engagementType?: EngagementTypes
  engagementKey?: string
  startDate: string
  endDate: string
  system?: string
}

export interface CohortInputs {
  promoID: number
  cohortID: number | null
  name: string
  mailCampaignSequence: number
  audienceType: AudienceTypes
  leadScoreStart: number
  leadScoreEnd: number
  startDate: string
  endDate: string
  behaviors: BehaviorInputs[]
}

export interface ExclusionList {
  name: string
  size: number
}

export interface PromoState {
  promos: HashMap<Promo>
  cohorts: HashMap<CohortPayload>
  cohortsByPromoID: HashMap<Set<number>>
  emailTemplates: HashMap<EmailTemplate>
  campaigns: HashMap<CampaignPayload>
  campaignsByPromoID: HashMap<Set<number>>
  campaignSendStats: HashMap<CampaignSendStat>
  promoSizeEstimates: HashMap<UserListEstimate>
  cohortSizeEstimates: HashMap<UserListEstimate>
  exclusionLists: HashMap<ExclusionList[]>
  campaignDeliveryStats: HashMap<CampaignDeliveryStat>
  meta: {
    [PromoActions.CREATE_PROMO]: LoadingLoadedErrorState
    [PromoActions.FETCH_PROMOS]: LoadingLoadedErrorState
    [PromoActions.FETCH_EMAIL_TEMPLATES]: LoadingLoadedErrorState
    [PromoActions.CREATE_CAMPAIGN]: LoadingLoadedErrorState
    [PromoActions.CREATE_COHORT]: LoadingLoadedErrorState
    [PromoActions.FETCH_PROMO_COHORTS]: HashMap<LoadingLoadedErrorState>
    [PromoActions.REMOVE_PROMO_COHORT]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_CAMPAIGNS]: HashMap<LoadingLoadedErrorState>
    [PromoActions.REMOVE_PROMO_CAMPAIGN]: HashMap<LoadingLoadedErrorState>
    [PromoActions.APPROVE_CAMPAIGN]: HashMap<LoadingLoadedErrorState>
    [PromoActions.APPROVE_PROMO]: HashMap<LoadingLoadedErrorState>
    [PromoActions.UPDATE_PROMO]: HashMap<LoadingLoadedErrorState>
    [PromoActions.UPDATE_CAMPAIGN]: HashMap<LoadingLoadedErrorState>
    [PromoActions.UPDATE_COHORT]: HashMap<LoadingLoadedErrorState>
    [PromoActions.CANCEL_PROMO]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_CAMPAIGN_QUEUE_STATS]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_PROMO_SIZE_ESTIMATE]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_COHORT_SIZE_ESTIMATE]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_PROMO_EXCLUSION_LISTS]: HashMap<LoadingLoadedErrorState>
    [PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS]: HashMap<LoadingLoadedErrorState>
    [PromoActions.TEST_EMAIL_TEMPLATE]: LoadingLoadedErrorState
  }
}

export const initialPromoState: PromoState = {
  promos: {},
  cohorts: {},
  cohortsByPromoID: {},
  emailTemplates: {},
  campaigns: {},
  campaignsByPromoID: {},
  campaignSendStats: {},
  promoSizeEstimates: {},
  cohortSizeEstimates: {},
  exclusionLists: {},
  campaignDeliveryStats: {},
  meta: {
    [PromoActions.CREATE_PROMO]: { ...initialLoadedLoadingErrorState },
    [PromoActions.FETCH_PROMOS]: { ...initialLoadedLoadingErrorState },
    [PromoActions.FETCH_EMAIL_TEMPLATES]: { ...initialLoadedLoadingErrorState },
    [PromoActions.CREATE_CAMPAIGN]: { ...initialLoadedLoadingErrorState },
    [PromoActions.CREATE_COHORT]: { ...initialLoadedLoadingErrorState },
    [PromoActions.FETCH_PROMO_COHORTS]: {},
    [PromoActions.REMOVE_PROMO_COHORT]: {},
    [PromoActions.FETCH_CAMPAIGNS]: {},
    [PromoActions.REMOVE_PROMO_CAMPAIGN]: {},
    [PromoActions.APPROVE_CAMPAIGN]: {},
    [PromoActions.APPROVE_PROMO]: {},
    [PromoActions.UPDATE_PROMO]: {},
    [PromoActions.UPDATE_CAMPAIGN]: {},
    [PromoActions.UPDATE_COHORT]: {},
    [PromoActions.CANCEL_PROMO]: {},
    [PromoActions.FETCH_CAMPAIGN_QUEUE_STATS]: {},
    [PromoActions.FETCH_PROMO_SIZE_ESTIMATE]: {},
    [PromoActions.FETCH_COHORT_SIZE_ESTIMATE]: {},
    [PromoActions.FETCH_PROMO_EXCLUSION_LISTS]: {},
    [PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS]: {},
    [PromoActions.TEST_EMAIL_TEMPLATE]: { ...initialLoadedLoadingErrorState },
  },
}
