import BannersContainer from 'components/Banners/BannersContainer'
import PageShell from 'pages/PageShell'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BannersPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <PageShell pageTitle={t('pages.Banners.pageTitle')}>
      <BannersContainer />
    </PageShell>
  )
}

export default BannersPage
