import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import pnWoff from 'assets/fonts/pntopresume.woff'
import pnWoff2 from 'assets/fonts/pntopresume.woff2'
import pnMWoff from 'assets/fonts/pntopresume-m.woff'
import pnMWoff2 from 'assets/fonts/pntopresume-m.woff2'
import pnSBWoff from 'assets/fonts/pntopresume-sb.woff'
import pnSBWoff2 from 'assets/fonts/pntopresume-sb.woff2'
import pnBWoff from 'assets/fonts/pntopresume-b.woff'
import pnBWoff2 from 'assets/fonts/pntopresume-b.woff2'
import tgaBWoff from 'assets/fonts/texgyreadventor-b.woff'
import tgaBWoff2 from 'assets/fonts/texgyreadventor-b.woff2'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const breakpoints = createBreakpoints({})

export const colors = {
  blue: {
    100: '#eef6fc', // Section BGs
    200: '#d4e9f8', // Stepper Inactive Section
    300: '#86d0f5', // Inactive Buttons
    400: '#0da1ec', // Links, Buttons, Checkmarks, Dropdown Arrows, Gallery Arrows
    500: '#1d86e8', // Stepper Active
    600: '#333954', // Lost Connection BG
    700: '#052a4d', // Main Text
    a100: 'rgba(13, 161, 236, 0.15)', // Article highlight
    a200: 'rgba(13, 161, 236, 0.35)', // Article highlight hover
    a300: '#a0c2e5', // Modal line item icons
    a400: '#0e83eb', // Button hover
    a500: '#deecf9', // Light blue in footer, Content pages header BG
    l100: '#18a2c4', //Linkedin header color
    r100: '#051f55', //Resume Refresh background color
    r200: '#7f9ad1', //Resume Refresh purple/blue
    pa100: '#09143b', //Products Admin Primary
    pa200: '#030716', //Products Admin Primary Dark
    pa300: '#11256f',
    pa400: '#fafafc',
    po100: 'rgb(9, 20, 59, 0.5)', //primary 50% opacity
    po200: 'rgb(14, 131, 235, 0.5)', //primary 50% opacity
    o100: '#51648a', //overline
    t100: '#333f57', //table header
  },
  gray: {
    100: '#f5f8fb', // App BG, outlined button hover bg
    200: '#f3f3f4', // Secondary Buttons
    300: '#dfdfdf', // Icons, Paper border
    400: '#66677d', // Secondary Text
    500: '#e1e4e7', // Form Inputs
    600: '#e4e8f0', // Notification borders, outlined button border
    a100: '#edf0f5', // Modal line item icons bg
    a200: '#f9f9f9', // Disabled secondary buttons
    b200: '#e8e8e9', // Secondary button hover
    c200: '#eceff5', // inactive tab bg
    a300: '#a9afbc', // inactive tab badge dot
    a400: '#052a4d', // Your portfolio text
    a500: '#adadba', // Disabled order progress step
    b400: '#b2b3be', // Disabled contained button text
    a600: '#f1f3f7', // Disabled outlined button border
    a700: '#505050', // Document preview text
    b600: '#e4e8ef', // Light gray in progress dots
    pa100: 'rgba(0, 0, 0, 0.26)', //disabled outlined button border
  },
  green: {
    300: '#d0f2ee', // Green Chip BG
    400: '#0fbb9f', // Green Chip Text, Connected Text, Correct Text
  },
  orange: {
    200: 'rgba(255,208,197,0.3)', // Orange Chip BG
    300: '#fabba4', // Rating Stars
    400: '#ff896f', // Orange Chip Text
    500: '#ff896b', //upsell expiry date
  },
  red: {
    200: '#ffe1e1', // Red Chip Background
    400: '#ff6b6b', // Errors, Red Chip Text
    600: '#ec4e38', // remove icon
  },
  black: {
    100: '#232323', // Input text
  },
  yellow: {
    100: '#ffcc00', // Warning yellow
  },
  white: {
    100: '#ffffff',
    200: '#fcfcfc', // page background color
  },
}

const proximaNova = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
      url(${pnWoff2}) format('woff2'),
      url(${pnWoff}) format('woff')
    `,
}
const proximaNovaMedium = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
      url(${pnMWoff2}) format('woff2'),
      url(${pnMWoff}) format('woff')
    `,
}
const proximaNovaSemiBold = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
      url(${pnSBWoff2}) format('woff2'),
      url(${pnSBWoff}) format('woff')
    `,
}
const proximaNovaBold = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      url(${pnBWoff2}) format('woff2'),
      url(${pnBWoff}) format('woff')
    `,
}
const texGyreAdventorBold = {
  fontFamily: 'Tex Gyre Adventor',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      url(${tgaBWoff2}) format('woff2'),
      url(${tgaBWoff}) format('woff')
    `,
}

export const MUITheme = createTheme({
  palette: {
    primary: {
      main: colors.blue.pa100,
      dark: colors.blue.pa200,
      light: colors.blue.pa300,
    },
    secondary: {
      main: colors.blue.a400,
    },
    background: {
      default: colors.white[100],
    },
  },
  typography: {
    fontFamily: 'Proxima Nova, Arial, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      color: colors.blue[700],
      fontFamily: 'Tex Gyre Adventor, Proxima Nova, Arial, sans-serif',
      fontSize: '2.13em',
      fontWeight: 700,
      lineHeight: '1.41em',
    },
    h2: {
      fontSize: '1.25em',
      fontWeight: 700,
      lineHeight: '1.4em',
    },
    h3: {
      fontSize: '1.13em',
      fontWeight: 500,
      lineHeight: '1.56em',
    },
    h4: {
      fontSize: '.88em',
      fontWeight: 500,
      lineHeight: '1.63em',
    },
    h5: {
      color: colors.gray[400],
      fontSize: '1em',
      fontWeight: 500,
      lineHeight: '1.63em',
    },
    body1: {
      color: colors.gray[400],
      fontSize: '1.13em',
      fontWeight: 400,
      lineHeight: '1.56em',
    },
    body2: {
      color: colors.gray[400],
      fontSize: '1em',
      lineHeight: '1.5em',
    },
    subtitle1: {
      fontFamily: 'Tex Gyre Adventor, Proxima Nova, Arial, sans-serif',
      fontSize: '1.5em',
      fontWeight: 700,
      lineHeight: '1.58em',
    },
    subtitle2: {
      color: colors.gray[400],
      fontSize: '.88em',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    button: {
      color: 'white',
      fontSize: '1.13em',
      fontWeight: 600,
      lineHeight: '1.6em',
      textAlign: 'center',
    },
    overline: {
      fontSize: '1rem',
      color: colors.blue.o100,
    },
  },
  props: {
    MuiAppBar: {
      color: 'default',
    },
    MuiPaper: {
      elevation: 4,
    },
    MuiDialog: {
      PaperProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      color: 'primary',
    },
    MuiInput: {
      placeholder: '--',
    },
    MuiSelect: {
      IconComponent: ExpandMoreIcon,
    },
    MuiTextField: {
      label: '',
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          proximaNova,
          proximaNovaMedium,
          proximaNovaSemiBold,
          proximaNovaBold,
          texGyreAdventorBold,
        ],
      },
    },
    MuiContainer: {
      root: {
        padding: '0',
        marginLeft: '2rem',
        marginRight: '2rem',
      },
      disableGutters: {
        marginLeft: '0',
        marginRight: '0',
        padding: '0',
      },
    },
    MuiButton: {
      root: {
        padding: '.56em 1.2em',
        textTransform: 'inherit',
        height: '2.5rem',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
      },
      contained: {
        backgroundColor: colors.gray[200],
        color: colors.gray[400],
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.gray.b200,
          boxShadow: 'none',
        },
        '&$disabled': {
          backgroundColor: `${colors.gray.a200}`,
          color: `${colors.gray.b400}`,
        },
      },
      containedPrimary: {
        color: '#fff',
        '&:hover': {
          backgroundColor: colors.blue.pa300,
        },
        '&:disabled': {
          backgroundColor: colors.blue.po100,
          color: colors.white[100],
        },
      },
      containedSecondary: {
        '&:disabled': {
          backgroundColor: colors.blue.po200,
          color: colors.white[100],
        },
      },
      label: {
        lineHeight: '1.5',
      },
      outlined: {
        border: `2px solid ${colors.gray[600]}`,
        padding: '.56em 1.2em',
        '&:hover': {
          backgroundColor: colors.gray[100],
        },
        '&$disabled': {
          border: `1px solid ${colors.gray.pa100}`,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.blue.pa100}`,
        '&:hover': {
          border: `2px solid ${colors.blue.pa100}`,
        },
        '&$disabled': {
          border: `1px solid ${colors.gray.pa100}`,
        },
      },
      text: {
        padding: '0',
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.blue.a400,
        },
      },
    },
    MuiPaper: {
      outlined: {
        border: `1px solid ${colors.gray.b600}`,
        borderRadius: '.38em',
        marginTop: '1.5em',
        paddingLeft: '2em',
        paddingRight: '2em',
        [breakpoints.down('xs')]: {
          borderRadius: '0',
        },
      },
    },
    MuiDivider: {
      root: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: colors.blue.t100,
        fontFamily: 'Proxima Nova',
        border: 'none',
        fontSize: '1rem',
      },
    },
    MuiTableHead: {
      root: {
        border: 'none',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiSwitch: {
      root: {
        background: 'inherit',
      },
    },
    MuiInput: {
      root: {
        border: `none`,
        borderRadius: '4px',
        padding: '.8rem 1rem',
        color: colors.black[100],
        boxShadow: 'inset 0 2px 6px 0 rgba(225, 228, 231, 0.67)',
        height: '2.5rem',
        backgroundColor: colors.blue.pa400,
        marginRight: '1rem',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.1rem',
        color: colors.blue.pa100,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0.25rem .5rem 0.25rem 0rem',
      },
    },
    MuiRadio: {
      root: {
        height: '2.5rem',
      },
    },
  },
})
