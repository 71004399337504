import React from 'react'
import clsx from 'clsx'
import TextField from '@material-ui/core/TextField'
import ErrorToolTip from './ErrorToolTip'

interface Props {
  validationMessage: string
  isValid: boolean
  className?: string
  inputRef: any
  label?: string
  defaultValue?: string | number
  name: string
  type?: string
  disabled?: boolean
  inputProps?: any
  placeholder?: string
  onChange?: any
}

/*
  A wrapper Material UI's <TextField> that adds a tooltip for displaying validation error messages

  Validation is handled by React-Hook-Form. 
  The validation rules are provided via the 'inputRef' parameter in the format as described by React-Hook-Form's documentation
  https://react-hook-form.com/get-started#Applyvalidation
  https://react-hook-form.com/api#register
 */
const ValidationTextField: React.FC<Props> = ({
  validationMessage,
  isValid,
  className,
  inputRef,
  label,
  defaultValue,
  name,
  type,
  disabled,
  inputProps,
  placeholder,
  onChange,
}) => {
  return (
    <ErrorToolTip
      PopperProps={{
        disablePortal: true,
      }}
      open={!isValid}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={validationMessage}
      arrow
    >
      <TextField
        label={label || ''}
        className={clsx(className)}
        name={name}
        inputRef={inputRef}
        defaultValue={defaultValue || ''}
        type={type}
        disabled={disabled}
        inputProps={inputProps}
        placeholder={placeholder}
        onChange={onChange}
      />
    </ErrorToolTip>
  )
}

export default ValidationTextField
