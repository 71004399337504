import Snackbar from '@material-ui/core/Snackbar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { colors } from 'styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

enum AlertTypes {
  Info = 'Info',
  Error = 'Error',
}

interface Props {
  open: boolean
  handleClose: (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => void
  message: string
  alertType: AlertTypes
}
const Alert: React.FC<Props> = ({ open, handleClose, message, alertType }) => {
  const classes = useStyles()
  const autoClose = alertType === AlertTypes.Info
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoClose ? 3000 : null}
    >
      <Paper
        className={clsx(
          classes.paper,
          alertType === AlertTypes.Error ? classes.error : classes.info
        )}
      >
        <Typography variant="body2" className={classes.text}>
          {message}
        </Typography>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          className={classes.iconButton}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Snackbar>
  )
}
/*This Provider component enables the use of the alert hooks accros the application
  without passsing state between components. The infoAlert and errorAlert methods can b
  used to display messages from any component
*/

interface AlertsContextType {
  infoAlert: (message: string) => void
  errorAlert: (message: string) => void
}

export const AlertsContext = React.createContext<
  AlertsContextType | Partial<AlertsContextType>
>({})

const AlertsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.Info)

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const infoAlert = useCallback((msg: string) => {
    setMessage(msg)
    setAlertType(AlertTypes.Info)
    setOpen(true)
  }, [])

  const errorAlert = useCallback((msg: string) => {
    setMessage(msg)
    setAlertType(AlertTypes.Error)
    setOpen(true)
  }, [])

  return (
    <AlertsContext.Provider value={{ infoAlert, errorAlert }}>
      {children}
      <Alert
        open={open}
        handleClose={handleClose}
        message={message}
        alertType={alertType}
      />
    </AlertsContext.Provider>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      minWidth: '20rem',
      justifyContent: 'space-between',
      padding: '1rem',
    },
    error: {
      backgroundColor: colors.red[600],
    },
    info: {
      backgroundColor: theme.palette.secondary.main,
    },
    text: {
      color: colors.white[100],
    },
    iconButton: {
      color: colors.white[100],
    },
  })
)

export default AlertsProvider
