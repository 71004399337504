import {
  HashMap,
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils'
import { BannersActions } from './actions'
export interface Banner {
  id: number
  domain: string
  brand: string
  currency_code: string
  discount_token: string
  banner_text: string
}

export interface BannersState {
  banners: HashMap<Banner>
  meta: {
    [BannersActions.FETCH_BANNERS]: LoadingLoadedErrorState
    [BannersActions.UPDATE_SELECTED_BANNERS]: LoadingLoadedErrorState
  }
}
export const initalBannersState: BannersState = {
  banners: {},
  meta: {
    [BannersActions.FETCH_BANNERS]: { ...initialLoadedLoadingErrorState },
    [BannersActions.UPDATE_SELECTED_BANNERS]: {
      ...initialLoadedLoadingErrorState,
    },
  },
}
