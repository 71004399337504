import React from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'

import Loader from 'components/shared/Loader'

interface Props {
  title: string
  onAddClick?: () => void
  isLoading?: boolean
}

const TableWrapper: React.FC<Props> = ({
  title,
  onAddClick,
  isLoading,
  children,
}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Container disableGutters className={classes.sectionContainer}>
        <Typography variant="overline" className={classes.sectionHeader}>
          {title}
        </Typography>
        <Box>
          <IconButton aria-label="filter">
            <FilterListIcon />
          </IconButton>
          <IconButton aria-label="add" onClick={onAddClick}>
            <AddIcon />
          </IconButton>
        </Box>
      </Container>
      <Divider />
      <Container disableGutters className={classes.container}>
        <>
          {isLoading && <Loader />}
          {!isLoading && <>{children}</>}
        </>
      </Container>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem',
    },
    sectionHeader: {
      marginLeft: '1rem',
      flexBasis: '80%',
    },
    container: {
      paddingBottom: '.5rem',
      paddingTop: '0.5rem',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paper: {
      marginBottom: '2rem',
    },
  })
)

export default TableWrapper
