import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import { Promo, PromoWithCohortsAndCampaigns } from 'store/promo/types'
import { createPromoThunk, updatePromoThunk } from 'store/promo/thunks'

import CampaignFormContainer from './CampaignFormContainer'
import CohortsContainer from './CohortsContainer'
import FinishSetupForm from './FinishSetupForm'
import {
  campaignsLoadedOnce,
  cohortsLoadedOnce,
  hasSavedCohorts,
} from 'store/promo/selector'
import { AppState } from 'store'
import Loader from 'components/shared/Loader'
import useAlerts from 'hooks/useAlerts'
import ExclusionListsContainer from './ExclusionListsContainer'
import ValidationTextField from 'components/shared/ValidationTextField'
import PromoSizeEstimateContainer from './SizeEstimates/PromoSizeEstimateContainer'
import LoaderButton from 'components/shared/LoaderButton'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import { tryGetErrorMessage } from 'utils/errors'

// this represents the structure of all data on the top level promo form
interface PromoInputs {
  id: number | null
  name: string
}

interface PromoHeaderProps {
  promo: PromoWithCohortsAndCampaigns
  hasCohorts: boolean
}

const ExistingPromoHeader: React.FC<PromoHeaderProps> = ({
  promo,
  hasCohorts,
}) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form'
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const promoDefaults = {
    id: promo.id,
    name: promo.name,
  }

  const { infoAlert, errorAlert } = useAlerts()
  const { register, handleSubmit, watch, errors } = useForm<PromoInputs>({
    defaultValues: { ...promoDefaults },
  })

  const promoName = watch('name')

  const onSave = useCallback(
    async (data: PromoInputs) => {
      const result = await dispatch(
        updatePromoThunk(promo.id, { name: data.name, description: '' })
      )
      if (!result) {
        infoAlert('Changes saved!')
        return
      }
      errorAlert('Failed to save changes: ' + tryGetErrorMessage(result))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoAlert, errorAlert]
  )

  const [submissionState, submitForm] = useAsyncFn(onSave, [onSave])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Container disableGutters className={classes.formGroup}>
        <input type="hidden" name="id" value={promo.id} ref={register} />
        <ValidationTextField
          label={t(`${tRef}.promoName`)}
          className={classes.xlDropdown}
          name="name"
          inputRef={register({ required: true })}
          defaultValue={promo.name}
          isValid={!errors.name}
          validationMessage={t(`${tRef}.validation.promoName`)}
        />

        <Box className={classes.formControl}>
          <InputLabel>{t(`${tRef}.macroListSize`)}</InputLabel>
          {!hasCohorts && (
            <Typography variant="body2" className={classes.readOnly}>
              --
            </Typography>
          )}
          {hasCohorts && (
            <Box className={clsx(classes.loaderContainer, classes.readOnly)}>
              <PromoSizeEstimateContainer promoID={promo.id} />
            </Box>
          )}
        </Box>

        <LoaderButton
          type="submit"
          variant="contained"
          disabled={!promoName}
          loading={submissionState.loading}
        >
          {t(`${tRef}.save`)}
        </LoaderButton>
      </Container>
    </form>
  )
}

interface NewPromoProps {
  setPromoID: (id: number) => void
}
const NewPromoHeader: React.FC<NewPromoProps> = ({ setPromoID }) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form'
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { register, handleSubmit, watch, errors } = useForm<PromoInputs>()
  const promoName = watch('name')

  const onPromoFormSubmit = useCallback(
    async (data) => {
      const createdPromo = await dispatch(
        createPromoThunk({ name: data.name, description: '' })
      )
      if ('id' in createdPromo) {
        const thisPromo: Promo = createdPromo as any as Promo
        setPromoID(thisPromo.id)
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [setPromoID]
  )

  const [submissionState, submitForm] = useAsyncFn(onPromoFormSubmit, [
    onPromoFormSubmit,
  ])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Container disableGutters className={classes.formGroup}>
        <ValidationTextField
          label={t(`${tRef}.promoName`)}
          className={classes.xlDropdown}
          name="name"
          inputRef={register({ required: true })}
          isValid={!errors.name}
          validationMessage={t(`${tRef}.validation.promoName`)}
        />

        <Box className={classes.formControl}>
          <InputLabel>{t(`${tRef}.macroListSize`)}</InputLabel>
          <Typography variant="body2" className={classes.readOnly}>
            --
          </Typography>
        </Box>
        <LoaderButton
          type="submit"
          variant="contained"
          disabled={!promoName}
          loading={submissionState.loading}
        >
          {t(`${tRef}.saveAndContinue`)}
        </LoaderButton>
      </Container>
    </form>
  )
}

interface Props {
  open: boolean
  setPromoID: (id: number) => void
  closeForm: () => void
  confirmCloseForm: () => void
  promo: PromoWithCohortsAndCampaigns | null
}

const CreatePromoModal: React.FC<Props> = ({
  open,
  setPromoID,
  closeForm,
  confirmCloseForm,
  promo,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form'

  const loadedOnce = useSelector<AppState, boolean>((state) =>
    cohortsLoadedOnce(state.promos, promo?.id)
  )

  const loadedOnceCampaigns = useSelector<AppState, boolean>((state) =>
    campaignsLoadedOnce(state.promos, promo?.id)
  )

  const hasCohorts = useSelector<AppState, boolean>((state) =>
    hasSavedCohorts(state.promos, promo?.id)
  )

  //if the promo has been created but campaigns and cohorts have not been loaded
  //show the loader
  const showLoader = promo && (!loadedOnce || !loadedOnceCampaigns)

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      scroll="body"
      maxWidth="lg"
      fullWidth={true}
    >
      <Box className={classes.formContainer}>
        <Container disableGutters className={classes.formHeader}>
          <Typography variant="h2">{t(`${tRef}.title`)}</Typography>
          <IconButton onClick={() => confirmCloseForm()}>
            <CloseIcon />
          </IconButton>
        </Container>
        {showLoader && <Loader />}
        {!showLoader && (
          <Container disableGutters>
            {promo && (
              <ExistingPromoHeader promo={promo} hasCohorts={hasCohorts} />
            )}
            {!promo && <NewPromoHeader setPromoID={setPromoID} />}
            {promo && (
              <>
                <Divider />
                {<CohortsContainer promo={promo} />}
                <Divider />
                <CampaignFormContainer promo={promo} />
                <Divider />
                <Container
                  disableGutters
                  className={clsx(classes.row, classes.rowPadded)}
                >
                  <ExclusionListsContainer promoID={promo.id} />
                </Container>
                <Divider />
                <FinishSetupForm
                  promoID={promo.id}
                  closeForm={closeForm}
                  confirmCloseForm={confirmCloseForm}
                />
              </>
            )}
          </Container>
        )}
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowPadded: {
      marginTop: '1rem',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonsContainer: {
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: '1rem',
    },
    leftButton: {
      marginRight: '1rem',
    },
    formControl: {
      flexShrink: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    readOnly: {
      height: '2.3rem',
    },
    formLabel: {
      fontSize: '0.9rem',
      color: colors.blue.pa100,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    paddedHeader: {
      marginBottom: '1rem',
    },
    audienceContainer: {
      borderLeft: `solid 3px ${colors.blue.pa100}`,
      paddingLeft: '1rem',
      marginBottom: '1rem',
    },
    leadScore: {
      width: '7rem',
    },
    date: {
      width: '12.5rem',
    },
    time: {
      width: '10rem',
    },
    dash: {
      marginRight: '1rem',
    },
    removeIcon: {
      color: colors.red[600],
      width: '1.2rem',
      height: '1.2rem',
    },
    removeButton: {
      marginLeft: '1rem',
      width: '1.2rem',
      height: '1.2rem',
    },
    sentenceText: {
      marginRight: '1rem',
    },
    mediumDropdown: {
      width: '10rem',
    },
    largeDropdown: {
      width: '15rem',
    },
    xlDropdown: {
      width: '30rem',
    },
    previewContainer: {
      width: '8rem',
      height: '14rem',
      border: `solid 2px ${colors.blue.pa100}`,
      marginRight: '2rem',
    },
    campaignFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    campaignContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    subjectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mailSubject: {
      width: '40rem',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    loaderContainer: {
      marginRight: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
)

export default CreatePromoModal
