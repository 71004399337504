import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { format as dateFormat } from 'date-fns'
import ct from 'countries-and-timezones'
import { zonedTimeToUtc } from 'date-fns-tz'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import ConfirmationModal from 'components/shared/ConfirmationModal'

import { Campaign, EmailTemplate, PromoSendTypes } from 'store/promo/types'
import {
  createCampaignThunk,
  removeCampaignThunk,
  updateCampaignThunk,
} from 'store/promo/thunks'
import useAlerts from 'hooks/useAlerts'
import { TestEmailButtonContainer } from './TestEmailModal'
import ValidationSelect from 'components/shared/ValidationSelect'
import ValidationTextField from 'components/shared/ValidationTextField'
import { useForm } from 'react-hook-form'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import LoaderButton from 'components/shared/LoaderButton'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import DeliveryStatsContainer from './Stats/DeliveryStatsContainer'
import { tryGetErrorMessage } from 'utils/errors'

interface CampaignProps {
  promoID: number
  promoApproved?: boolean
  canEdit: boolean
  campaign: Campaign
  index: number
  templates: EmailTemplate[]
  removeCampaign: (index: number) => void
}

type CampaignInputs = {
  promoID: number
  campaignID: number | null
  subject: string | null
  emailTemplateID: number | null
  sendAtDate: string
  sendAtTime: string
  sendAtTimeZone: string
}

const CampaignForm: React.FC<CampaignProps> = ({
  promoID,
  promoApproved,
  canEdit,
  campaign,
  index,
  templates,
  removeCampaign,
}) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.campaign'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const defaultTimeZoneName = 'America/New_York'
  const defaultTimeZone = ct.getTimezone(defaultTimeZoneName)

  const defaultValues = {
    subject: '',
    emailTemplateID: campaign.emailTemplateID || 0,
    sendAtDate: campaign.sendAtDate || dateFormat(new Date(), 'yyyy-MM-dd'),
    sendAtTime: campaign.sendAtTime || '00:00',
    sendAtTimeZone: defaultTimeZoneName, //TODO: Need to store the selected timezone in the db so this is not necessary
  }

  const timezones = Object.values(ct.getAllTimezones())
    .filter((tz) =>
      [
        'America/New_York',
        'Europe/Berlin',
        'Europe/London',
        'Australia/Sydney',
      ].includes(tz.name)
    )
    .sort((a, b) => a.utcOffset - b.utcOffset)

  const { infoAlert, errorAlert } = useAlerts()

  const { register, handleSubmit, errors, watch } = useForm<CampaignInputs>({
    defaultValues: defaultValues,
  })
  const emailTemplateID = watch('emailTemplateID')
  const sendAtDate = watch('sendAtDate')
  const canTest = !!(emailTemplateID && emailTemplateID > 0)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  //react-hook-form's setValue does not seem to be working when called from the MUI Autocomplete onChange
  //using set state to keep track of that field
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    defaultValues.sendAtTimeZone
  )

  const showConfirmationModal = useCallback(() => {
    setConfirmModalOpen(true)
  }, [])

  const isThreeHoursLater = useCallback(
    async (value: string) => {
      const utcTime = zonedTimeToUtc(sendAtDate + ' ' + value, selectedTimeZone)
      const nowTime = zonedTimeToUtc(new Date(), defaultTimeZoneName)
      const diffInHours =
        (utcTime.getTime() - nowTime.getTime()) / (1000 * 60 * 60)
      return diffInHours > 3
    },
    [selectedTimeZone, sendAtDate]
  )

  const onConfirm = useCallback(async () => {
    if (campaign.campaignID && promoID) {
      setConfirmModalOpen(false)
      const resp = await dispatch(
        removeCampaignThunk(promoID, campaign.campaignID)
      )
      if (!resp) {
        infoAlert('Campaign removed!')
        return
      }
      errorAlert('Failed to remove Campaign: ' + tryGetErrorMessage(resp))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoID, campaign.campaignID, infoAlert, errorAlert])

  const [removalSubmissionState, submitRemoval] = useAsyncFn(onConfirm, [
    onConfirm,
  ])

  const onCancel = useCallback(() => {
    setConfirmModalOpen(false)
  }, [])

  const onSubmit = useCallback(
    async (data: CampaignInputs) => {
      if (data.sendAtDate && data.sendAtTime && data.emailTemplateID) {
        const utcTime = zonedTimeToUtc(
          data.sendAtDate + ' ' + data.sendAtTime,
          selectedTimeZone
        )

        //if there is campaign ID, update the exisitng campaign
        if (campaign.campaignID && campaign.templates && data.emailTemplateID) {
          const payload = {
            templates: campaign.templates.map((template) => ({
              id: template.id,
              date_id: template.date_id,
              email_template_id: Number(data.emailTemplateID),
            })),
            send_at: utcTime,
            send_type: PromoSendTypes.UTC,
            promo_id: promoID,
          }
          const result = await dispatch(
            updateCampaignThunk(promoID, campaign.campaignID, payload)
          )
          if (!result) {
            infoAlert('Changes saved!')
            return
          }
          errorAlert('Failed to update Campaign: ' + tryGetErrorMessage(result))
        } else {
          //otherwise, create one
          const result = await dispatch(
            createCampaignThunk(Number(data.promoID), {
              templates: [{ email_template_id: Number(data.emailTemplateID) }],
              send_at: utcTime,
              send_type: PromoSendTypes.UTC,
            })
          )
          //if there are no errors, remove the campaign from state.
          //it will now be rendered by the selector data
          if (!result) {
            removeCampaign(index)
            infoAlert('Changes saved!')
            return
          }
          errorAlert('Failed to save Campaign: ' + tryGetErrorMessage(result))
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index, campaign.campaignID, selectedTimeZone]
  )

  const onTzChange = useCallback(async (value: string) => {
    setSelectedTimeZone(value)
  }, [])

  const [saveSubmissionState, submitSave] = useAsyncFn(onSubmit, [onSubmit])

  return (
    <>
      <form onSubmit={handleSubmit(submitSave)}>
        <Box className={classes.campaignContainer}>
          <input type="hidden" name="promoID" value={promoID} ref={register} />
          <input
            type="hidden"
            name="campaignID"
            value={campaign.campaignID}
            ref={register}
          />
          <Box className={classes.previewContainer}></Box>
          <Box className={classes.campaignFormContainer}>
            <Container disableGutters className={classes.subjectContainer}>
              <Box>
                <InputLabel>{t(`${tRef}.emailTemplate`)}</InputLabel>
                <ValidationSelect
                  name="emailTemplateID"
                  inputRef={register({ min: 1 })}
                  defaultValue={defaultValues.emailTemplateID}
                  isValid={!errors.emailTemplateID}
                  validationMessage={t(`${tRef}.validation.required`)}
                  disabled={!canEdit}
                >
                  <option value={0} key={0}>
                    {t(`${tRef}.selectTemplate`)}
                  </option>
                  {templates.map((template) => (
                    <option value={template.id} key={template.id}>
                      {template.id} - {template.template}
                    </option>
                  ))}
                </ValidationSelect>
              </Box>

              <Box className={classes.row}>
                {campaign.campaignID && (
                  <Box>
                    <DeliveryStatsContainer
                      promoID={promoID}
                      campaignID={campaign.campaignID}
                      showLoader={true}
                      promoApproved={!!promoApproved}
                    />
                  </Box>
                )}

                {canTest && (
                  <TestEmailButtonContainer emailTemplateID={emailTemplateID} />
                )}
                {campaign.campaignID && (
                  <LoaderButton
                    variant="outlined"
                    className={classes.button}
                    onClick={() => showConfirmationModal()}
                    loading={removalSubmissionState.loading}
                  >
                    {t(`${tRef}.delete`)}
                  </LoaderButton>
                )}

                {!campaign.campaignID && canEdit && (
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => removeCampaign(index)}
                  >
                    {t(`${tRef}.delete`)}
                  </Button>
                )}
              </Box>
            </Container>
            <Container
              disableGutters
              className={clsx(classes.row, classes.rowPadded)}
            >
              <Box>
                <InputLabel>{t(`${tRef}.launchDateAndTime`)}</InputLabel>
                <Box className={classes.row}>
                  <ValidationTextField
                    className={classes.date}
                    name="sendAtDate"
                    inputRef={register({ required: true })}
                    defaultValue={defaultValues.sendAtDate}
                    isValid={!errors.sendAtDate}
                    validationMessage={t(`${tRef}.validation.required`)}
                    disabled={!canEdit}
                    type="date"
                  />
                  <Typography variant="body2" className={classes.sentenceText}>
                    {t(`${tRef}.at`)}
                  </Typography>
                  <ValidationTextField
                    className={classes.time}
                    name="sendAtTime"
                    inputRef={register({
                      required: true,
                      validate: isThreeHoursLater,
                    })}
                    defaultValue={defaultValues.sendAtTime}
                    isValid={!errors.sendAtTime}
                    validationMessage={t(`${tRef}.validation.lauchDateandTime`)}
                    disabled={!canEdit}
                    type="time"
                  />
                </Box>
              </Box>
              <Box>
                <InputLabel>{t(`${tRef}.timezone`)}</InputLabel>
                <Autocomplete
                  id="combo-box-demo"
                  options={timezones}
                  getOptionLabel={(tz) => `${tz.name} (GMT ${tz.utcOffsetStr})`}
                  onChange={(event, newValue) =>
                    onTzChange(newValue?.name || '')
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          paddingTop: '0rem',
                        },
                      }}
                      placeholder="Start Typing to filter"
                    />
                  )}
                  className={classes.autoComplete}
                  defaultValue={defaultTimeZone}
                  disabled={!canEdit}
                />
              </Box>
            </Container>
            {canEdit && (
              <Box className={classes.rowPadded}>
                <LoaderButton
                  variant="contained"
                  className={classes.leftButton}
                  type="submit"
                  loading={saveSubmissionState.loading}
                >
                  {t(`${tRef}.save`)}
                </LoaderButton>
              </Box>
            )}
          </Box>
        </Box>
      </form>
      {confirmModalOpen && (
        <ConfirmationModal
          displayText={t(`${tRef}.deleteConfirmation`)}
          onConfirm={submitRemoval}
          onCancel={onCancel}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowPadded: {
      marginTop: '1rem',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginLeft: '1rem',
    },
    leftButton: {
      marginRight: '1rem',
    },
    date: {
      width: '12.5rem',
    },
    time: {
      width: '10rem',
    },
    dash: {
      marginRight: '1rem',
    },
    sentenceText: {
      marginRight: '1rem',
    },
    largeDropdown: {
      width: '15rem',
    },
    xlDropdown: {
      width: '30rem',
    },
    previewContainer: {
      width: '8rem',
      height: '14rem',
      border: `solid 2px ${colors.blue.pa100}`,
      marginRight: '2rem',
    },
    campaignFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    campaignContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    subjectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    autoComplete: {
      width: '30rem',
      paddingTop: '0rem',
    },
    loaderContainer: {
      marginRight: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '0.2rem',
    },
  })
)

export default CampaignForm
