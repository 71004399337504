import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { colors } from 'styles'
import MailIcon from '@material-ui/icons/Mail'

import { AppState } from 'store'
import {
  campaignsLoadedOnce,
  currentSendStatsByPromo,
} from 'store/promo/selector'
import { fetchRunningCampaignsSendStatsThunk } from 'store/promo/thunks'
import { CampaignSendStat } from 'store/promo/types'
import SendStatsTooltip from '../Tooltips/SendStatsTooltip'

interface Props {
  promoID: number
  promoApproved: boolean
}

const SendStatsContainer: React.FC<Props> = ({ promoID, promoApproved }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const tRef = 'pages.Campaigns.sections.promos.SendStatsToolTip'

  const loadedOnceCampaigns = useSelector<AppState, boolean>((state) =>
    campaignsLoadedOnce(state.promos, promoID)
  )

  const sendStats = useSelector<AppState, CampaignSendStat[]>(
    (state) => currentSendStatsByPromo(state.promos, promoID),
    isEqual
  )

  useEffect(() => {
    if (promoApproved && loadedOnceCampaigns) {
      dispatch(fetchRunningCampaignsSendStatsThunk(promoID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [promoApproved, loadedOnceCampaigns, promoID])

  if (sendStats.length === 0) return null
  return (
    <SendStatsTooltip stats={sendStats} title={t(`${tRef}.title`) || ''}>
      <IconButton className={classes.statusButton}>
        <MailIcon className={classes.statusIcon} />
      </IconButton>
    </SendStatsTooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    statusButton: {
      padding: '0.1rem',
      marginLeft: '0.5rem',
    },
    statusIcon: {
      width: '1.2rem',
      height: '1.2rem',
      color: colors.gray.pa100,
    },
    loader: {
      width: '1rem',
      height: '1rem',
    },
    loaderText: {
      marginLeft: '1rem',
    },
  })
)

export default SendStatsContainer
