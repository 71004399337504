import Auth0Lock from 'auth0-lock'
import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/browser'
import { initialLoadedLoadingErrorState } from '@talentinc/state-utils'
import { differenceInHours, fromUnixTime } from 'date-fns'
import useLocation from 'react-use/lib/useLocation'
import { useTranslation } from 'react-i18next'

import { AppState } from 'store'
import { Auth0Config } from 'store/config/types'
import { appAuth0Config } from 'store/config/selectors'
import {
  loginSuccess,
  loginFailure,
  logout as reduxLogout,
  checkingSession as reduxCheckingSession,
} from 'store/user/actions'
import { CustomerJWT, AuthState } from 'store/user/types'
import { fetchConfig } from 'store/config/actions'
import i18n from 'i18n'

export type Auth0LockContextType = null | Auth0LockStatic
export const Auth0LockContext = React.createContext<Auth0LockContextType>(null)

const MAX_RETRIES = 5

export const Auth0LockProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { hostname } = useLocation()
  const [lock, setLock] = useState<Auth0LockContextType>(null)
  const [retry, setRetry] = useState(0)
  const auth0Config = useSelector<AppState, Auth0Config | null>(
    (state) => appAuth0Config(state.config, hostname),
    isEqual
  )
  const { checkingSession } = useSelector<AppState, AuthState>(
    (state) => state.user.auth,
    isEqual
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Initialize Lock only once and after we have all the config needed for it.
  useEffect(() => {
    if (auth0Config && !lock) {
      const lockInstance = constructLockFromConfig(auth0Config, {
        container: 'auth0-root',
        languageDictionary: {
          title: t('components.Auth.Login.header'),
        },
      })

      lockInstance.on('authenticated', (authResult) => {
        if (authResult.idTokenPayload) {
          const groups: string[] =
            authResult.idTokenPayload[`https://products.talentinc.com/groups`]
          if (
            groups &&
            (groups.includes('Developer') || groups.includes('Admin'))
          ) {
            setAuth0Cookie(authResult)
            dispatch(loginSuccess(authResult))
          } else {
            //TODO: Figure out what to do here? redirect?
          }
        }
      })

      lockInstance.on('authorization_error', (error) => {
        Sentry.captureMessage(error.description || error.error, {
          level: Sentry.Severity.Error,
          // @ts-ignore
          extra: error,
        })
        dispatch(loginFailure(error))
      })

      lockInstance.on('unrecoverable_error', (error) => {
        // The reporting logic for this is in another useEffect below
        dispatch(loginFailure(error))
      })

      // lockInstance.on('forgot_password ready', (error) => {})

      // lockInstance.on('forgot_password submit', (error) => {})

      setLock(lockInstance)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [auth0Config?.domain, auth0Config?.clientID, lock])

  // Refresh the token if needed.
  useEffect(() => {
    if (checkingSession.loaded || !lock) {
      return
    }

    lock.checkSession({}, (_, authResult) => {
      if (authResult?.idTokenPayload) {
        setAuth0Cookie(authResult)
        dispatch(loginSuccess(authResult))
      }

      dispatch(reduxCheckingSession(false))
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkingSession.loaded, lock])

  // Handle instances where Auth0 fails to load it's config. This happens for
  // some users for reasons beyond my explaination. Some popular theories are:
  //
  // 1. An ad blocker might be blocking the request to get the Auth0 config.
  // 2. An enterprise firewall might be blocking the request.
  // 3. The Great Firewall of China has issues loading the Auth0 config.
  // 4. Intermittent connection issues for the user.
  //
  // In those cases, Auth0 will emit an unrecoverable_error event. We will
  // attempt to fetch the Auth0 config details from our server, unset the Lock
  // instance, which will then trigger the above effect to re-init Lock, and
  // we'll increment the retry counter. After 5 retries (with exponential
  // backoff), we'll give up on reconfiguring.
  useEffect(() => {
    if (lock) {
      lock.on('unrecoverable_error', (error) => {
        if (retry <= MAX_RETRIES) {
          setTimeout(async () => {
            await dispatch(fetchConfig(true))

            setRetry((currentRetry) => {
              setLock(null)
              return currentRetry + 1
            })
          }, 1000 * retry)
        } else {
          Sentry.captureMessage(
            `Login form attempted to render ${MAX_RETRIES} times and could not successfully render.`,
            {
              level: Sentry.Severity.Error,
              // @ts-ignore
              extra: error,
            }
          )
        }
      })
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lock, retry])

  return (
    <Auth0LockContext.Provider value={lock}>
      {children}
    </Auth0LockContext.Provider>
  )
}

function useAuth0() {
  const dispatch = useDispatch()
  const auth0Config = useSelector<AppState, Auth0Config | null>(
    (state) => state.config.config?.auth0 || null,
    isEqual
  )
  const { loggedIn, identity, checkingSession } = useSelector<
    AppState,
    AuthState
  >((state) => state.user.auth, isEqual)
  const lock = useContext(Auth0LockContext)

  const logout = useCallback(async () => {
    if (!lock) {
      return
    }

    Cookies.remove(CookieName, CookieOptions)
    dispatch(reduxLogout())
    lock.logout({
      returnTo: `${window.location.origin}/login`,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lock])

  return {
    lock,
    loggedIn,
    checkingSession,
    identity,
    logout,
    auth0Config,
  }
}

export const CookieName = 'portal_token'
export const CookieOptions = {
  path: '/',
  domain: window.location.host,
  secure: true,
}

export const setAuth0Cookie = (authResult: AuthResult) => {
  Cookies.set(CookieName, authResult.idToken, {
    ...CookieOptions,
    expires: new Date(authResult.idTokenPayload.exp * 1000),
  })
}

const DefaultAuth0Options: Auth0LockConstructorOptions = {
  auth: {
    redirectUrl: `${window.location.origin}/auth/callback`,
    responseType: 'token id_token',
    params: {
      scope: 'openid email',
    },
  },
  theme: {
    primaryColor: '#0ea0ec',
  },
  avatar: null,
  configurationBaseUrl: `${process.env.REACT_APP_PORTAL_API_URL}/api/v2/auth/auth0-proxy`,
  forgotPasswordLink: i18n.t('routes.resetPassword'),
}

export const constructLockFromConfig = (
  config: Auth0Config,
  options?: Auth0LockConstructorOptions
): Auth0LockStatic => {
  return new Auth0Lock(
    config.clientID,
    config.domain,
    merge(DefaultAuth0Options, options)
  )
}

export const createAuthStateFromCookies = (): AuthState => {
  const jwt = Cookies.get(CookieName)
  const identity = jwt ? jwtDecode<CustomerJWT>(jwt) : null

  if (identity) {
    Sentry.setUser({
      id: identity['https://products.talentinc.com/user_id']?.toString(),
    })
  }

  // Refresh the JWT if it expires in the next 24 hour
  const tokenCloseToExperiation =
    (jwt &&
      !!identity?.exp &&
      differenceInHours(fromUnixTime(identity.exp), new Date()) <= 24) ||
    false

  return {
    loggedIn: !!jwt,
    identity,
    jwt: jwt || null,
    // Checking the session on the callback page will break things
    checkingSession: {
      ...initialLoadedLoadingErrorState,
      loaded:
        window.location.pathname.includes('callback') ||
        !tokenCloseToExperiation,
    },
    error: null,
  }
}

export default useAuth0
