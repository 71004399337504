import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enUS from './en'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: process.env.NODE_ENV !== 'production' && !process.env.JEST_WORKER_ID,
    resources: {
      'en-US': enUS,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
