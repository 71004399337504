import produce from 'immer'
import * as stateUtils from '@talentinc/state-utils'
import { EventAction, EventActions } from './actions'
import { EventState, initalEventState } from './types'

export default function eventsReducer(
  state: EventState = initalEventState,
  action: EventAction
): EventState {
  return produce(state, (draft) => {
    switch (action.type) {
      case EventActions.FETCH_EVENT_TYPES:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break
      case EventActions.FETCH_EVENT_TYPES_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }
      case EventActions.FETCH_EVENT_TYPES_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((event) => {
          draft.eventTypes[event.id] = event
        })
        break
      }
    }
  })
}
