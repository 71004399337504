import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useAlerts from 'hooks/useAlerts'
import useUser from 'hooks/useUser'
import { isEqual } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { allBrands } from 'store/brand/selector'
import { UserBrand } from 'store/brand/types'
import { testEmailTemplateThunk } from 'store/promo/thunks'
import { TestEmailTemplatePayload, TopResumeSiteID } from 'store/promo/types'
import { useTranslation } from 'react-i18next'
import LoaderButton from 'components/shared/LoaderButton'
import { tryGetErrorMessage } from 'utils/errors'

interface Props {
  open: boolean
  onClose: () => void
  emailTemplateID: number | null
}

type InputTypes = {
  sendTo: string
  site: number
  email: string
  delivery_email: string
}

const TestEmailModal: React.FC<Props> = ({
  open,
  onClose,
  emailTemplateID,
}) => {
  const classes = useStyles()
  const user = useUser()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { control, watch, register } = useForm<InputTypes>({
    defaultValues: {
      sendTo: 'current',
      site: 0,
      email: '',
      delivery_email: '',
    },
  })

  const { infoAlert, errorAlert } = useAlerts()

  const brands = useSelector<AppState, UserBrand[]>(
    (state) => allBrands(state.brands),
    isEqual
  )

  const sendingTest = useSelector<AppState, boolean>(
    (state) => state.promos.meta.TEST_EMAIL_TEMPLATE.isLoading
  )

  const sendTo = watch('sendTo')
  const site = watch('site')
  const email = watch('email')
  const delivery_email = watch('delivery_email')

  const tRef = 'pages.Campaigns.sections.promos.form.campaign'

  const sendTestEmail = useCallback(async () => {
    if (emailTemplateID && (site || user.id) && (user.email || email)) {
      const data: TestEmailTemplatePayload = {
        email_template_id: emailTemplateID,
        promo_date_id: null,
        send_now: true,
        lead_email: user.email || '',
        utm_campaign: null,
        site_id: TopResumeSiteID,
        email: null,
      }

      if (sendTo === 'custom') {
        data.site_id = site
        data.lead_email = email
        data.email = delivery_email
      }

      const result = await dispatch(
        testEmailTemplateThunk(emailTemplateID, data)
      )
      if (!result) {
        infoAlert('Test email sent!')
        onClose()
        return
      }
      errorAlert('Failed to send test email: ' + tryGetErrorMessage(result))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user, emailTemplateID, errorAlert, infoAlert])
  const enableSend =
    sendTo === 'current' || (site > 0 && email !== '' && delivery_email !== '')
  return (
    <Dialog
      open={open}
      scroll="body"
      maxWidth="sm"
      onClose={onClose}
      fullWidth={true}
    >
      <Box className={classes.container}>
        <Typography variant="h2">{t(`${tRef}.sendTest`)}</Typography>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.sendTo`)}</InputLabel>
          <Controller
            as={
              <RadioGroup row aria-label={`audienceType`}>
                <FormControlLabel
                  value="current"
                  control={<Radio />}
                  label={t(`${tRef}.currentUser`)}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label={t(`${tRef}.custom`)}
                />
              </RadioGroup>
            }
            name={`sendTo`}
            control={control}
          />
        </Box>
        {sendTo === 'custom' && (
          <>
            <Box className={classes.fieldContainer}>
              <InputLabel>{t(`${tRef}.brand`)}</InputLabel>
              <Controller
                as={
                  <Select className={classes.xlDropdown}>
                    <MenuItem value={0} key={0}>
                      {t(`${tRef}.selectBrand`)}
                    </MenuItem>
                    {brands.map((brand) => (
                      <MenuItem value={brand.site_id} key={brand.site_id}>
                        {brand.home_url + ' (' + brand.site_id + ') '}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name={`site`}
                control={control}
                rules={{ required: true }}
                defaultValue={''}
              />
            </Box>
            <Box className={classes.fieldContainer}>
              <TextField
                name={`email`}
                defaultValue=""
                type="email"
                inputRef={register}
                label="Lead Email Address"
              />
            </Box>
            <Box className={classes.fieldContainer}>
              <TextField
                name={`delivery_email`}
                defaultValue=""
                type="email"
                inputRef={register}
                label="Delivery Email Address"
              />
            </Box>
          </>
        )}

        <LoaderButton
          variant="contained"
          disabled={!enableSend}
          onClick={sendTestEmail}
          loading={sendingTest}
        >
          {t(`${tRef}.send`)}
        </LoaderButton>
      </Box>
    </Dialog>
  )
}

interface ContainerProps {
  emailTemplateID: number | null
}
export const TestEmailButtonContainer: React.FC<ContainerProps> = ({
  emailTemplateID,
}) => {
  const tRef = 'pages.Campaigns.sections.promos.form.campaign'
  const { t } = useTranslation()
  const classes = useStyles()

  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const openEmailModal = useCallback(async () => {
    setEmailModalOpen(true)
  }, [])

  const closeEmailModal = useCallback(async () => {
    setEmailModalOpen(false)
  }, [])
  return (
    <>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={openEmailModal}
      >
        {t(`${tRef}.test`)}
      </Button>
      {emailModalOpen && (
        <TestEmailModal
          open={emailModalOpen}
          onClose={closeEmailModal}
          emailTemplateID={emailTemplateID}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '2rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    xlDropdown: {
      width: '30rem',
    },
    button: {
      marginLeft: '1rem',
    },
  })
)
export default TestEmailModal
