import React, { Attributes, useState } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface Props {
  text: string
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      marginLeft: '-0.5rem',
    },
  })
)

const NavDropdownMenu: React.FC<Props> = ({ text, className, children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={className}
        endIcon={
          <ExpandMoreIcon className={classes.icon}>expand</ExpandMoreIcon>
        }
      >
        {text}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onClick: handleClose,
            } as Attributes)
          }
          return child
        })}
      </Menu>
    </>
  )
}

export default NavDropdownMenu
