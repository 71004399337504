import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import { AppState } from 'store'
import { fetchPromoSizeEstimate } from 'store/promo/actions'
import {
  promoSizeEstimate,
  promoSizeEstimateLoaded,
  promoSizeEstimateLoadedOnce,
} from 'store/promo/selector'
import { UserListEstimate } from 'store/promo/types'

interface Props {
  promoID: number
  className?: string
}

const PromoSizeEstimateContainer: React.FC<Props> = ({
  promoID,
  className,
}) => {
  const dispatch = useDispatch()
  const estimateLoadedOnce = useSelector<AppState, boolean>((state) =>
    promoSizeEstimateLoadedOnce(state.promos, promoID)
  )
  const estimateLoaded = useSelector<AppState, boolean>((state) =>
    promoSizeEstimateLoaded(state.promos, promoID)
  )

  const estimateLoading = useSelector<AppState, boolean>(
    (state) => !!state.promos.meta.FETCH_PROMO_SIZE_ESTIMATE[promoID]?.isLoading
  )

  const estimate = useSelector<AppState, UserListEstimate | null>((state) =>
    promoSizeEstimate(state.promos, promoID)
  )
  useEffect(() => {
    if (!estimateLoadedOnce && !estimateLoading) {
      dispatch(fetchPromoSizeEstimate(promoID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [estimateLoadedOnce, estimateLoading, promoID])
  if (!estimateLoaded) return <LinearProgress />
  return (
    <Typography variant="body2" className={className}>
      {estimate?.estimate || '--'}
    </Typography>
  )
}

export default PromoSizeEstimateContainer
