import { ConfigState, Auth0Config, EnvConfig } from './types'

export const envConfig = (
  hostname: string = window.location.hostname
): EnvConfig | null => {
  const config: EnvConfig = {}

  // If we have the Auth0 details baked into the bundle, use them instead of
  // waiting for the API to return them.
  if (
    hostname?.includes('zipjob') &&
    process.env.REACT_APP_ZIPJOB_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_ZIPJOB_AUTH0_DOMAIN
  ) {
    config.auth0 = {
      clientID: process.env.REACT_APP_ZIPJOB_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_ZIPJOB_AUTH0_DOMAIN,
    }
  }

  if (
    process.env.REACT_APP_TALENTINC_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_TALENTINC_AUTH0_DOMAIN
  ) {
    config.auth0 = {
      clientID: process.env.REACT_APP_TALENTINC_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_TALENTINC_AUTH0_DOMAIN,
    }
  }

  if (!config) {
    return null
  }

  return config
}

export const appAuth0Config = (
  state: ConfigState,
  hostname?: string
): Auth0Config | null => {
  // Always prefer what the server provides us
  if (state.config?.auth0) {
    return state.config.auth0
  }

  const envAuth0Config = envConfig(hostname)?.auth0

  if (envAuth0Config) {
    return envAuth0Config
  }

  return null
}
