import { APIError } from 'utils/axios'
import { AppThunkAction } from 'store'
import {
  CampaignPayloadBase,
  CohortPayload,
  CohortPayloadBase,
  Promo,
  PromoBase,
  TestEmailTemplatePayload,
  UpdateCampaignPayload,
} from './types'
import {
  approveCampaign,
  approvePromo,
  cancelPromo,
  createCampaign,
  createCohort,
  createPromo,
  CreatePromoSuccess,
  fetchCampaignDeliveryStats,
  fetchCampaignQueueStats,
  fetchCohortSizeEstimate,
  fetchPromoCohorts,
  fetchPromoExclusionLists,
  fetchPromoSizeEstimate,
  removePromoCampaign,
  removePromoCohort,
  testEmailTemplate,
  updateCampaign,
  updateCohort,
  updatePromo,
  uploadExclusionList,
  uploadInclusionList,
} from './actions'
import { runningCampaignIDsForPromo } from './selector'

export const createPromoThunk =
  (data: PromoBase): AppThunkAction<Promo | APIError> =>
  async (dispatch): Promise<Promo | APIError> => {
    try {
      const result = (await dispatch(
        createPromo({ ...data })
      )) as unknown as CreatePromoSuccess
      return result.payload.data
    } catch (e) {
      return e as APIError
    }
  }

export const updatePromoThunk =
  (promoID: number, data: PromoBase): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(updatePromo(promoID, { ...data }))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const createCampaignThunk =
  (
    promoID: number,
    data: CampaignPayloadBase
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(createCampaign(promoID, { ...data }))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const uploadInclusionListAndUpdateCohorts =
  (
    file: File,
    promoID: number,
    excludePurchasers: boolean
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(uploadInclusionList(file, promoID, excludePurchasers))
      dispatch(fetchPromoCohorts(promoID))
      dispatch(fetchPromoSizeEstimate(promoID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const uploadExclustionListThunk =
  (file: File, promoID: number): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(uploadExclusionList(file, promoID))
      dispatch(fetchPromoExclusionLists(promoID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const createCohortThunk =
  (promoID: number, data: CohortPayloadBase): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(createCohort(promoID, data))
      dispatch(fetchPromoCohorts(promoID))
      dispatch(fetchPromoSizeEstimate(promoID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const finishPromoSetupThunk =
  (promoID: number, campaignIDs: number[]): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(approvePromo(promoID))
      await Promise.all(
        campaignIDs.map(async (campaignID) => {
          dispatch(approveCampaign(promoID, campaignID))
        })
      )
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const approveCampaignsThunk =
  (promoID: number, campaignIDs: number[]): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await Promise.all(
        campaignIDs.map(async (campaignID) => {
          dispatch(approveCampaign(promoID, campaignID))
        })
      )
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const testEmailTemplateThunk =
  (
    templateID: number,
    data: TestEmailTemplatePayload
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(testEmailTemplate(templateID, { ...data }))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const updateCampaignThunk =
  (
    promoID: number,
    campaignID: number,
    data: UpdateCampaignPayload
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(updateCampaign(promoID, campaignID, { ...data }))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const updateCohortThunk =
  (
    promoID: number,
    cohortID: number,
    data: CohortPayload
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(updateCohort(promoID, cohortID, { ...data }))
      dispatch(fetchPromoSizeEstimate(promoID))
      dispatch(fetchCohortSizeEstimate(promoID, cohortID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const removeCohotThunk =
  (promoID: number, cohortID: number): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(removePromoCohort(promoID, cohortID))
      dispatch(fetchPromoSizeEstimate(promoID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const removeCampaignThunk =
  (promoID: number, campaignID: number): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(removePromoCampaign(promoID, campaignID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const cancelPromoThunk =
  (promoID: number): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(cancelPromo(promoID))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const fetchRunningCampaignsSendStatsThunk =
  (promoID: number): AppThunkAction<null | APIError> =>
  async (dispatch, getState): Promise<null | APIError> => {
    try {
      runningCampaignIDsForPromo(getState().promos, promoID).forEach((cID) => {
        dispatch(fetchCampaignQueueStats(promoID, cID))
      })
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const fetchRunningCampaignDeliveryStatsThunk =
  (promoID: number): AppThunkAction<null | APIError> =>
  async (dispatch, getState): Promise<null | APIError> => {
    try {
      runningCampaignIDsForPromo(getState().promos, promoID).forEach((cID) => {
        dispatch(fetchCampaignDeliveryStats(promoID, cID))
      })
      return null
    } catch (e) {
      return e as APIError
    }
  }
