import React from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  title: string
  onClose: () => void
  open: boolean
  disableFullWidth?: boolean
  maxWidth?: 'lg' | 'xl' | 'md'
}

const FormModalWrapper: React.FC<Props> = ({
  title,
  onClose,
  children,
  open,
  disableFullWidth,
  maxWidth,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      scroll="body"
      maxWidth={maxWidth || 'lg'}
      fullWidth={!disableFullWidth}
    >
      <Box className={classes.formContainer}>
        <Container disableGutters className={classes.formHeader}>
          <Typography variant="h2">{title}</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Container>
        {children}
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

export default FormModalWrapper
