import produce from 'immer'
import * as stateUtils from '@talentinc/state-utils'
import { BannersState, initalBannersState } from './types'
import { BannersAction, BannersActions } from './actions'

export default function bannersReducer(
  state: BannersState = initalBannersState,
  action: BannersAction
): BannersState {
  return produce(state, (draft) => {
    switch (action.type) {
      case BannersActions.FETCH_BANNERS: {
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break
      }
      case BannersActions.FETCH_BANNERS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }
      case BannersActions.FETCH_BANNERS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((banner) => {
          draft.banners[banner.domain] = banner
        })
        break
      }
      case BannersActions.UPDATE_SELECTED_BANNERS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }
      case BannersActions.UPDATE_SELECTED_BANNERS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        break
      }
    }
  })
}
