import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAlerts from 'hooks/useAlerts'
import { isEqual } from 'lodash'
import usePagination from 'hooks/usePagination'
import { useDispatch, useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Container from '@material-ui/core/Container'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Collapse from '@material-ui/core/Collapse'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {
  DiscountBrand,
  DiscountToken,
  DiscountTokenTypes,
} from 'store/discounts/types'
import { AppState } from 'store'
import { discountBrands, discountBrandsLoaded } from 'store/discounts/selectors'
import { fetchDiscountBrands } from 'store/discounts/actions'

interface RowProps {
  discount: DiscountToken
  onEditClick: (token: string) => void
  onDeleteClick: (token: string) => void
  onAddUserTokenClick: (token: string) => void
}

const DiscountRow: React.FC<RowProps> = ({
  discount,
  onEditClick,
  onDeleteClick,
  onAddUserTokenClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const tRef = 'components.Discounts'
  const discountPercent =
    discount.discount_percent && discount.discount_percent > 0
      ? `${discount.discount_percent * 100}%`
      : '--'
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const [detailsOpen, setDetailsOpen] = useState(false)

  const toggleDetails = useCallback(() => {
    setDetailsOpen(!detailsOpen)
  }, [detailsOpen])

  return (
    <>
      <TableRow>
        <TableCell>{discount.discount_token}</TableCell>
        <TableCell>{discount.short_description}</TableCell>
        <TableCell>{t(`${tRef}.types.${discount.type}`)}</TableCell>
        <TableCell>{discountPercent}</TableCell>
        <TableCell>{discount.usage_stats?.uses || ''}</TableCell>
        <TableCell>
          {discount.usage_stats?.revenue
            ? formatter.format(discount.usage_stats?.revenue)
            : ''}
        </TableCell>
        <TableCell>
          <Container disableGutters className={classes.rowControlsContainer}>
            {discount.type === DiscountTokenTypes.User && (
              <IconButton
                aria-label={t(`${tRef}.buttons.edit`)}
                onClick={() => onAddUserTokenClick(discount.discount_token)}
              >
                <PersonAddIcon />
              </IconButton>
            )}
            <IconButton
              aria-label={t(`${tRef}.buttons.edit`)}
              onClick={() => onEditClick(discount.discount_token)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label={t(`${tRef}.buttons.edit`)}
              onClick={() => onDeleteClick(discount.discount_token)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label={t(`${tRef}.buttons.expand`)}
              onClick={toggleDetails}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Container>
        </TableCell>
      </TableRow>
      <CollapsableDetailsRow
        discountToken={discount.discount_token}
        open={detailsOpen}
      />
    </>
  )
}

interface CollapseProps {
  discountToken: string
  open: boolean
}
const CollapsableDetailsRow: React.FC<CollapseProps> = ({
  discountToken,
  open,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { infoAlert } = useAlerts()
  const { t } = useTranslation()
  const tRef = 'components.Discounts.DiscountsTable.Details.BrandUrls'

  const brandsLoaded = useSelector<AppState, boolean>((state) =>
    discountBrandsLoaded(state.discounts, discountToken)
  )
  const brands = useSelector<AppState, DiscountBrand[]>(
    (state) => discountBrands(state.discounts, discountToken),
    isEqual
  )

  const copyToClipboard = useCallback(
    (url: string) => {
      navigator.clipboard.writeText(url)
      infoAlert('Copied to clipboard!')
    },
    [infoAlert]
  )

  useEffect(() => {
    if (!brandsLoaded && open) {
      dispatch(fetchDiscountBrands(discountToken))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [brandsLoaded, open])

  return (
    <TableRow>
      <TableCell colSpan={7} className={classes.expandableCell}>
        <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!brandsLoaded && open && (
              <LinearProgress className={classes.linearProgress} />
            )}
            {brandsLoaded && brands.length === 0 && (
              <Container className={classes.emptyContainer}>
                <Typography variant="h5" className={classes.emptyBrands}>
                  {t(`${tRef}.empty`)}
                </Typography>
              </Container>
            )}
            {brandsLoaded && brands.length > 0 && (
              <Box>
                <Typography variant="h2" className={classes.brandUrlsHeader}>
                  {t(`${tRef}.heading`)}
                </Typography>
                <Grid container className={classes.brandUrlsContainer}>
                  {brands.map((brand) => {
                    const thisUrl = `${brand.landing_page}/p/?action=checkout&dt=${discountToken}`
                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        key={brand.brand_name + brand.landing_page}
                      >
                        <Grid item xs={4} className={classes.centerCell}>
                          <Typography
                            variant="body2"
                            className={classes.brandName}
                          >
                            {brand.brand_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.centerCell}>
                          <Typography variant="body2">{thisUrl}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => copyToClipboard(thisUrl)}
                            className={classes.copyButton}
                          >
                            <FileCopyIcon className={classes.copyIcon} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            )}
          </Collapse>
        </Box>
      </TableCell>
    </TableRow>
  )
}

interface TableProps {
  discounts: DiscountToken[]
  onEditClick: (token: string) => void
  onDeleteClick: (token: string) => void
  onAddUserTokenClick: (token: string) => void
}

const DiscountsTable: React.FC<TableProps> = ({
  discounts,
  onEditClick,
  onDeleteClick,
  onAddUserTokenClick,
}) => {
  const { t } = useTranslation()
  const tRef = 'components.Discounts.DiscountsTable'
  const { pager, startIndex, endIndex } = usePagination(discounts.length, 5)
  const paginatedDiscounts = discounts.slice(startIndex, endIndex)

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(`${tRef}.columns.code`)}</TableCell>
            <TableCell>{t(`${tRef}.columns.description`)}</TableCell>
            <TableCell>{t(`${tRef}.columns.use`)}</TableCell>
            <TableCell>{t(`${tRef}.columns.discount`)}</TableCell>
            <TableCell>{t(`${tRef}.columns.uses`)}</TableCell>
            <TableCell>{t(`${tRef}.columns.revenue`)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedDiscounts.map((discount) => (
            <DiscountRow
              discount={discount}
              key={discount.discount_token}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onAddUserTokenClick={onAddUserTokenClick}
            />
          ))}
        </TableBody>
      </Table>
      {pager}
    </Box>
  )
}
const useStyles = makeStyles((theme) =>
  createStyles({
    rowControlsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    linearProgress: {
      margin: '2rem',
    },
    emptyBrands: {
      textAlign: 'center',
    },
    expandableCell: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    brandUrlsContainer: {
      margin: '1rem',
    },
    scrollable: {
      maxHeight: '10rem',
      overflow: 'auto',
    },
    brandName: {
      fontWeight: 'bold',
    },
    centerCell: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    copyButton: {
      padding: '0.3rem',
      marginLeft: '0.5rem',
    },
    copyIcon: {
      width: '1.2rem',
      height: '1.2rem',
    },
    brandUrlsHeader: {
      marginTop: '1rem',
      fontWeight: 'normal',
      color: theme.palette.secondary.main,
    },
    emptyContainer: {
      margin: '1rem',
    },
  })
)

export default DiscountsTable
