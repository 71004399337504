import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  Action,
} from 'redux'
import thunk, { ThunkAction } from 'redux-thunk'
import { setAutoFreeze, enableMapSet, enableES5 } from 'immer'
import { isIE } from 'react-device-detect'
import { useSelector, TypedUseSelectorHook } from 'react-redux'

import { createAxiosMiddleware } from 'utils/axios'
import configReducer from './config/reducer'
import userReducer from './user/reducer'
import preloadReducer from './preload/reducer'
import promosReaducer from './promo/reducer'
import brandsReducer from './brand/reducer'
import eventsReducer from './events/reducer'
import discountsReducer from './discounts/reducer'
import bannersReducer from './banners/reducer'

// Immer specific optimizations
if (isIE) {
  enableES5()
}
setAutoFreeze(process.env.NODE_ENV !== 'production' && !isIE)
enableMapSet()

// keep in alphabetical order. It makes tracing in the Redux devtools a little easier
const createRootReducer = () =>
  combineReducers({
    banners: bannersReducer,
    brands: brandsReducer,
    config: configReducer,
    discounts: discountsReducer,
    events: eventsReducer,
    preload: preloadReducer,
    promos: promosReaducer,
    user: userReducer,
  })

const middleware = [thunk, createAxiosMiddleware()]

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore() {
  return createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middleware))
  )
}

// This is the shape of our global state object.
export type AppState = ReturnType<ReturnType<typeof createRootReducer>>

// This is a generic type for Thunk actions in this app.
export type AppThunkAction<
  ThunkReturnType = void,
  ExtraArgument = unknown
> = ThunkAction<
  Promise<ThunkReturnType>,
  AppState,
  ExtraArgument,
  Action<string>
>

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
