import { Theme, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { colors } from 'styles'

const LightToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: colors.white[200],
    boxShadow: theme.shadows[1],
    fontSize: '0.9rem',
  },
}))(Tooltip)

export default LightToolTip
