import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import Loader from 'components/shared/Loader'
import DiscountForm from './DiscountForm'

import { DiscountToken, StickerPrice } from 'store/discounts/types'
import { AppState } from 'store'
import {
  allStickerPrices,
  availableCurrencyCodes,
  discountLoadedOnce,
  discountTokenWithPaymentPlans,
  unSelectedStickerPrices,
} from 'store/discounts/selectors'

import { fetchDiscount, fetchStickerPrices } from 'store/discounts/actions'
import FormModalWrapper from 'components/shared/FormModalWrapper'

interface Props {
  open: boolean
  discountToken: string | null
  onClose: () => void
}

const DiscountModalContainer: React.FC<Props> = ({
  open,
  discountToken,
  onClose,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tRef = 'components.Discounts.DiscountForm'

  const loadedOnceDiscount = useSelector<AppState, boolean>((state) =>
    discountLoadedOnce(state.discounts, discountToken)
  )

  const availableSPrices = useSelector<AppState, StickerPrice[]>(
    (state) => unSelectedStickerPrices(state.discounts, discountToken),
    isEqual
  )

  const allSPrices = useSelector<AppState, StickerPrice[]>(
    (state) => allStickerPrices(state.discounts, true),
    isEqual
  )

  const discount = useSelector<AppState, DiscountToken | null>(
    (state) => discountTokenWithPaymentPlans(state.discounts, discountToken),
    isEqual
  )

  const currencyCodes = useSelector<AppState, string[]>((state) =>
    availableCurrencyCodes(state.discounts)
  )

  useEffect(() => {
    if (!loadedOnceDiscount && discountToken) {
      dispatch(fetchDiscount(discountToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountToken, loadedOnceDiscount])

  useEffect(() => {
    dispatch(fetchStickerPrices())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLoader = discount && !loadedOnceDiscount
  const stickerPrices = discountToken ? availableSPrices : allSPrices

  return (
    <FormModalWrapper
      onClose={onClose}
      open={open}
      title={discount ? t(`${tRef}.titleEdit`) : t(`${tRef}.title`)}
    >
      {showLoader && <Loader />}
      {!showLoader && (
        <DiscountForm
          discount={discount}
          stickerPrices={stickerPrices}
          onClose={onClose}
          currencyCodes={currencyCodes}
        />
      )}
    </FormModalWrapper>
  )
}

export default DiscountModalContainer
