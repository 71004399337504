import * as ram from '@talentinc/redux-axios-middleware'
import { MailCampaignSequence, UserBrand } from './types'

export enum BrandActions {
  FETCH_BRANDS = 'FETCH_BRANDS',
  FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS',
  FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL',
  FETCH_MAILCAMP_SEQ = 'FETCH_MAILCAMP_SEQ',
  FETCH_MAILCAMP_SEQ_SUCCESS = 'FETCH_MAILCAMP_SEQ_SUCCESS',
  FETCH_MAILCAMP_SEQ_FAIL = 'FETCH_MAILCAMP_SEQ_FAIL',
}

interface FetchBrands extends ram.AxiosMiddlewareActionCreator {
  type: BrandActions.FETCH_BRANDS
}

interface FetchBrandsSuccess
  extends ram.AxiosMiddlewareActionSuccess<UserBrand[], FetchBrands> {
  type: BrandActions.FETCH_BRANDS_SUCCESS
}

interface FetchBrandsFail extends ram.AxiosMiddlewareActionFail<FetchBrands> {
  type: BrandActions.FETCH_BRANDS_FAIL
}

interface FetchMailCampaignSequences extends ram.AxiosMiddlewareActionCreator {
  type: BrandActions.FETCH_MAILCAMP_SEQ
}

interface FetchMailCampaignSequencesSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    MailCampaignSequence[],
    FetchMailCampaignSequences
  > {
  type: BrandActions.FETCH_MAILCAMP_SEQ_SUCCESS
}

interface FetchMailCampaignSequencesFail
  extends ram.AxiosMiddlewareActionFail<FetchMailCampaignSequences> {
  type: BrandActions.FETCH_MAILCAMP_SEQ_FAIL
}

export const fetchBrands = (): FetchBrands => ({
  type: BrandActions.FETCH_BRANDS,
  payload: {
    request: {
      url: '/v2/brands/full',
    },
  },
})

export const fetchMailCampaignSequences = (): FetchMailCampaignSequences => ({
  type: BrandActions.FETCH_MAILCAMP_SEQ,
  payload: {
    request: {
      url: '/v2/brands/mail-campaign-sequences',
    },
  },
})

export type BrandAction =
  | FetchBrands
  | FetchBrandsSuccess
  | FetchBrandsFail
  | FetchMailCampaignSequences
  | FetchMailCampaignSequencesSuccess
  | FetchMailCampaignSequencesFail
