import Login from 'components/Auth/Login'
import Logout from 'components/Auth/Logout'
import ProtectedRoute from 'components/Auth/ProtectedRoute'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CallbackPage from './Auth/Callback'
import { useTranslation } from 'react-i18next'

import HomePage from './Home'
import CampaignsPage from './Campaigns'
import DiscountsPage from './Discounts'
import BannersPage from './Banners'

const Controller: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet titleTemplate="Products Admin" />
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact path={t('routes.home')}>
            <HomePage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.campaigns')}>
            <CampaignsPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.discounts')}>
            <DiscountsPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={t('routes.banners')}>
            <BannersPage />
          </ProtectedRoute>
          <Route exact path={t('routes.login')}>
            <Login />
          </Route>
          <Route exact path={t('routes.logout')}>
            <Logout />
          </Route>
          <Route exact path="/auth/callback">
            <CallbackPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default Controller
