import { ErrorType } from '@talentinc/state-utils'

import { AppThunkAction } from 'store'
import { fetchConfigAndConfigure } from 'store/config/actions'
import { fetchUserAndConfigure } from 'store/user/thunks'

export enum PreloadActions {
  PRELOAD_FINISHED = 'PRELOAD_FINISHED',
  PRELOAD_FAILED = 'PRELOAD_FAILED',
  PAT_PRELOAD_FINISHED = 'PAT_PRELOAD_FINISHED',
  PAT_PRELOAD_FAILED = 'PAT_PRELOAD_FAILED',
}

/**
 * preloadApp is a thunk that dispatches all requests needed for the first
 * interaction to happen.
 */
export const preloadApp = (): AppThunkAction => async (dispatch, getState) => {
  try {
    // Fetch the Auth0 config
    dispatch(fetchConfigAndConfigure())
      .then(() => {
        //const { config } = getState().config;
      })
      .catch((error) =>
        dispatch({
          type: PreloadActions.PRELOAD_FAILED,
          error,
        })
      )

    dispatch({ type: PreloadActions.PRELOAD_FINISHED })
  } catch (error) {
    dispatch({
      type: PreloadActions.PRELOAD_FAILED,
      error,
    })
  }
}

/**
 * preloadUser is a thunk to fetch all the data on the user for the first
 * meaningful interaction. This is separate from preloadApp because there are
 * cases where you don't want to fetch the user as a part of the preload
 * (ex: authentication).
 */
export const preloadUser =
  (userIdentifier = 'me'): AppThunkAction =>
  async (dispatch) => {
    dispatch(fetchUserAndConfigure(userIdentifier))
  }

export const preloadUserByProductsAccessToken =
  (productsAccessToken: string): AppThunkAction =>
  async (dispatch) => {
    try {
      await dispatch(fetchUserAndConfigure(productsAccessToken))
      dispatch({ type: PreloadActions.PAT_PRELOAD_FINISHED })
    } catch (e) {
      dispatch({ type: PreloadActions.PAT_PRELOAD_FAILED })
    }
  }

interface PreloadFinished {
  type: PreloadActions.PRELOAD_FINISHED
}

interface PreloadFailed {
  type: PreloadActions.PRELOAD_FAILED
  error: ErrorType
}

export interface PATPreloadFinished {
  type: PreloadActions.PAT_PRELOAD_FINISHED
}

export interface PATPreloadFailed {
  type: PreloadActions.PAT_PRELOAD_FAILED
  error: ErrorType
}

export type PreloadAction =
  | PreloadFinished
  | PreloadFailed
  | PATPreloadFinished
  | PATPreloadFailed
