import {
  HashMap,
  initialLoadedLoadingErrorState,
  LoadingLoadedErrorState,
} from '@talentinc/state-utils'
import { DiscountActions } from './actions'

export interface DiscountPlanCode {
  plan_code: string
  discount_value?: number
}

export interface DiscountPaymentPlanBase {
  plan_code: string
  plan_name: string
  amount: number
  currency_code: string
}

export enum DiscountTokenTypes {
  Global = 'global',
  PerPlanCode = 'per_plan_code',
  User = 'single_use',
}

export const PlanCodeTypeOptions = [
  DiscountTokenTypes.Global,
  DiscountTokenTypes.User,
]

export enum DiscountAmountTypes {
  Amount = 'amount',
  Percent = 'percent',
}

export interface DiscountTokenBase {
  discount_token: string
  type: DiscountTokenTypes
  short_description: string
  discount_percent: number | null
  expires_at: Date
  starts_at: Date
  days_to_live?: number
}

export interface StickerPrice extends Omit<DiscountPaymentPlanBase, 'amount'> {
  payment_plan_id: number
  sticker_price: number
  is_sticker_price: boolean
  type: string
}

export interface DiscountPaymentPlan extends StickerPrice {
  discount_value?: number
}

export interface DiscountToken extends DiscountTokenBase {
  discount_plan_codes?: DiscountPlanCode[] | null
  discount_payment_plans?: DiscountPaymentPlan[]
  usage_stats?: DiscountTokenUsage | null
}

export interface UserDiscountToken
  extends Omit<
    DiscountToken,
    | 'starts_at'
    | 'expires_at'
    | 'days_to_live'
    | 'discount_percent'
    | 'short_description'
  > {
  anon_token: string
}

export interface DiscountTokenPayload
  extends Omit<DiscountTokenBase, 'expires_at' | 'starts_at'> {
  discount_plan_codes: DiscountPlanCode[]
  expires_at: string
  starts_at: string
}

export interface DiscountTokenPayloadBase
  extends Omit<
    DiscountTokenBase,
    | 'expires_at'
    | 'starts_at'
    | 'type'
    | 'short_description'
    | 'discount_percent'
    | 'days_to_live'
  > {
  discount_plan_codes?: DiscountPlanCode[]
}
export interface DeleteDiscountTokenPayload {
  discount_token: string
  type: DiscountTokenTypes
}

export interface PatchDiscountTokenPayload
  extends Omit<DiscountTokenBase, 'expires_at' | 'starts_at' | 'type'> {
  expires_at: string
  starts_at: string
  new_discount_token?: string
}

export interface DiscountTokenUsage {
  discount_token: string
  uses: number
  revenue: number
}

export interface DiscountBrand {
  brand_name: string
  landing_page: string
  currency_code: string
  plan_codes: string[]
}

export interface DiscountConfirmationData {
  discountCode: string
  discountAmount: number
  discountAmountType: DiscountAmountTypes
  affectedStickerPrices: DiscountPaymentPlan[]
  individualPrices: boolean
}

export interface DiscountState {
  discounts: HashMap<DiscountToken>
  paymentPlansByDiscount: HashMap<Set<string>>
  stickerPrices: HashMap<StickerPrice>
  stickerPricesByCurrencyCode: HashMap<Set<string>>
  currencyCodes: string[]
  userDiscountTokens: HashMap<Set<string>>
  usageStats: HashMap<DiscountTokenUsage>
  brands: HashMap<DiscountBrand[]>
  meta: {
    [DiscountActions.FETCH_DISCOUNTS]: LoadingLoadedErrorState
    [DiscountActions.CREATE_DISCOUNT]: LoadingLoadedErrorState
    [DiscountActions.FETCH_STICKER_PRICES]: LoadingLoadedErrorState
    [DiscountActions.FETCH_DISCOUNT]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.UPDATE_DISCOUNT_INFO]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.REMOVE_DSICOUNT_PLAN_CODES]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.DELETE_DISCOUNT_TOKEN]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.CREATE_USER_DISCOUNT]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.DELETE_USER_DISCOUNT]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.FETCH_DISCOUNT_USAGE_STATS]: LoadingLoadedErrorState
    [DiscountActions.DELETE_STICKER_PRICE]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.FETCH_DISCOUNT_BRANDS]: HashMap<LoadingLoadedErrorState>
    [DiscountActions.ADD_STICKER_PRICES]: LoadingLoadedErrorState
  }
}

export const initalDiscountState: DiscountState = {
  discounts: {},
  paymentPlansByDiscount: {},
  stickerPrices: {},
  stickerPricesByCurrencyCode: {},
  currencyCodes: [],
  userDiscountTokens: {},
  usageStats: {},
  brands: {},
  meta: {
    [DiscountActions.FETCH_DISCOUNTS]: { ...initialLoadedLoadingErrorState },
    [DiscountActions.CREATE_DISCOUNT]: { ...initialLoadedLoadingErrorState },
    [DiscountActions.FETCH_STICKER_PRICES]: {
      ...initialLoadedLoadingErrorState,
    },
    [DiscountActions.FETCH_DISCOUNT]: {},
    [DiscountActions.UPDATE_DISCOUNT_INFO]: {},
    [DiscountActions.REMOVE_DSICOUNT_PLAN_CODES]: {},
    [DiscountActions.DELETE_DISCOUNT_TOKEN]: {},
    [DiscountActions.CREATE_USER_DISCOUNT]: {},
    [DiscountActions.DELETE_USER_DISCOUNT]: {},
    [DiscountActions.FETCH_DISCOUNT_USAGE_STATS]: {
      ...initialLoadedLoadingErrorState,
    },
    [DiscountActions.DELETE_STICKER_PRICE]: {},
    [DiscountActions.FETCH_DISCOUNT_BRANDS]: {},
    [DiscountActions.ADD_STICKER_PRICES]: { ...initialLoadedLoadingErrorState },
  },
}
