import produce from 'immer'
import * as stateUtils from '@talentinc/state-utils'
import {
  DiscountState,
  DiscountToken,
  DiscountTokenPayload,
  DiscountTokenPayloadBase,
  initalDiscountState,
  PatchDiscountTokenPayload,
  UserDiscountToken,
} from './types'
import { DiscountAction, DiscountActions } from './actions'

export default function discountsReducer(
  state: DiscountState = initalDiscountState,
  action: DiscountAction
): DiscountState {
  return produce(state, (draft) => {
    switch (action.type) {
      case DiscountActions.FETCH_DISCOUNT_USAGE_STATS:
      case DiscountActions.CREATE_DISCOUNT:
      case DiscountActions.FETCH_STICKER_PRICES:
      case DiscountActions.FETCH_DISCOUNTS: {
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break
      }
      case DiscountActions.FETCH_DISCOUNT_USAGE_STATS_FAIL:
      case DiscountActions.CREATE_DISCOUNT_FAIL:
      case DiscountActions.FETCH_STICKER_PRICES_FAIL:
      case DiscountActions.FETCH_DISCOUNTS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case DiscountActions.FETCH_DISCOUNTS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((discount) => {
          draft.discounts[discount.discount_token] = discount
        })
        break
      }

      case DiscountActions.FETCH_STICKER_PRICES_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((price) => {
          draft.stickerPrices[price.plan_code] = price
        })
        break
      }
      case DiscountActions.UPDATE_DISCOUNT_INFO_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        const previousAction = action.meta.previousAction
        draft.meta[previousActionType][previousAction.discountToken] =
          stateUtils.setSuccessState(
            state.meta[previousActionType][previousAction.discountToken]
          )

        updateDraftWithDiscountTokenInfo(
          action.meta.previousAction.data,
          draft,
          previousAction.discountToken
        )

        break
      }
      case DiscountActions.CREATE_DISCOUNT_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )

        updateDraftWithNewDiscount(action.meta.previousAction.tokens, draft)

        break
      }
      case DiscountActions.DELETE_USER_DISCOUNT: {
        draft.meta[action.type][action.userDiscountToken] =
          stateUtils.setStartState(stateUtils.initialLoadedLoadingErrorState)
        break
      }
      case DiscountActions.FETCH_DISCOUNT_BRANDS:
      case DiscountActions.CREATE_USER_DISCOUNT:
      case DiscountActions.DELETE_DISCOUNT_TOKEN:
      case DiscountActions.REMOVE_DSICOUNT_PLAN_CODES:
      case DiscountActions.UPDATE_DISCOUNT_INFO:
      case DiscountActions.FETCH_DISCOUNT: {
        draft.meta[action.type][action.discountToken] =
          stateUtils.setStartState(stateUtils.initialLoadedLoadingErrorState)
        break
      }
      case DiscountActions.DELETE_USER_DISCOUNT_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][
          action.meta.previousAction.userDiscountToken
        ] = stateUtils.setErrorState(
          state.meta[previousActionType][
            action.meta.previousAction.userDiscountToken
          ],
          action.error
        )
        break
      }
      case DiscountActions.FETCH_DISCOUNT_BRANDS_FAIL:
      case DiscountActions.CREATE_USER_DISCOUNT_FAIL:
      case DiscountActions.DELETE_DISCOUNT_TOKEN_FAIL:
      case DiscountActions.REMOVE_DSICOUNT_PLAN_CODES_FAIL:
      case DiscountActions.UPDATE_DISCOUNT_INFO_FAIL:
      case DiscountActions.FETCH_DISCOUNT_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][
          action.meta.previousAction.discountToken
        ] = stateUtils.setErrorState(
          state.meta[previousActionType][
            action.meta.previousAction.discountToken
          ],
          action.error
        )
        break
      }
      case DiscountActions.FETCH_DISCOUNT_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.discountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.discountToken]
          )
        updateDraftWithDiscount(
          action.payload.data,
          draft,
          previousAction.discountToken
        )

        break
      }

      case DiscountActions.REMOVE_DSICOUNT_PLAN_CODES_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.discountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.discountToken]
          )

        updateDraftWithRemovedDiscount(
          previousAction.data,
          draft,
          previousAction.discountToken
        )

        break
      }

      case DiscountActions.DELETE_DISCOUNT_TOKEN_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.discountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.discountToken]
          )

        delete draft.discounts[previousAction.discountToken]
        break
      }

      case DiscountActions.CREATE_USER_DISCOUNT_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.discountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.discountToken]
          )

        updateDraftWithNewUserDiscount(
          action.payload.data,
          draft,
          previousAction.discountToken
        )

        break
      }

      case DiscountActions.DELETE_USER_DISCOUNT_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.userDiscountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.userDiscountToken]
          )
        if (draft.userDiscountTokens[previousAction.discountToken]) {
          draft.userDiscountTokens[previousAction.discountToken].delete(
            previousAction.userDiscountToken
          )
        }

        break
      }

      case DiscountActions.FETCH_DISCOUNT_USAGE_STATS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((stat) => {
          draft.usageStats[stat.discount_token] = stat
        })
        break
      }
      case DiscountActions.DELETE_STICKER_PRICE: {
        draft.meta[action.type][action.planCode] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case DiscountActions.DELETE_STICKER_PRICE_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.planCode] =
          stateUtils.setErrorState(
            state.meta[previousActionType][action.meta.previousAction.planCode],
            action.error
          )
        break
      }
      case DiscountActions.DELETE_STICKER_PRICE_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.planCode] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.planCode]
          )
        delete draft.stickerPrices[previousAction.planCode]
        break
      }
      case DiscountActions.FETCH_DISCOUNT_BRANDS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.discountToken] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.discountToken]
          )
        draft.brands[previousAction.discountToken] = action.payload.data

        break
      }
    }
  })
}

const updateDraftWithNewUserDiscount = (
  data: UserDiscountToken[],
  draft: DiscountState,
  discountToken: string
) => {
  if (!draft.userDiscountTokens[discountToken]) {
    draft.userDiscountTokens[discountToken] = new Set<string>()
  }
  data.forEach((token) => {
    draft.userDiscountTokens[discountToken].add(token.anon_token)
  })
}

const updateDraftWithRemovedDiscount = (
  data: DiscountTokenPayloadBase[],
  draft: DiscountState,
  discountToken: string
) => {
  //remove deleted plan codes from the discount token's set
  if (draft.paymentPlansByDiscount[discountToken]) {
    const oldPlanCodes = draft.paymentPlansByDiscount[discountToken]
    data.forEach((payload) => {
      if (payload.discount_plan_codes) {
        payload.discount_plan_codes.forEach((deletedToken) => {
          oldPlanCodes.delete(deletedToken.plan_code)
        })
      }
    })

    draft.paymentPlansByDiscount[discountToken] = oldPlanCodes
  }

  const removedPlanCodes = new Set<string>(
    data.flatMap((payload) => {
      if (!payload.discount_plan_codes) return []
      return payload.discount_plan_codes.map((plan) => plan.plan_code)
    })
  )
  const token = draft.discounts[discountToken]
  //update the plan codes on the discount token object
  if (token.discount_plan_codes) {
    draft.discounts[discountToken].discount_plan_codes =
      token.discount_plan_codes?.filter(
        (plan) => !removedPlanCodes.has(plan.plan_code)
      )

    //if there are more plan codes associated, the token was "deleted", so we can remove from the store
    if (draft.discounts[discountToken].discount_plan_codes?.length === 0) {
      delete draft.discounts[discountToken]
      delete draft.paymentPlansByDiscount[discountToken]
    }
  }
}

const updateDraftWithDiscount = (
  results: DiscountToken,
  draft: DiscountState,
  discountToken: string
) => {
  draft.discounts[discountToken] = results
  const paymentPlans = results.discount_plan_codes
  if (paymentPlans) {
    paymentPlans.forEach((plan) => {
      if (!draft.paymentPlansByDiscount[results.discount_token]) {
        draft.paymentPlansByDiscount[results.discount_token] = new Set<string>()
      }
      draft.paymentPlansByDiscount[results.discount_token].add(plan.plan_code)
    })
  }
}
const updateDraftWithNewDiscount = (
  tokens: DiscountTokenPayload[],
  draft: DiscountState
) => {
  // the data returned by the api is identical to what was sent so we can
  // add it to the redux store from the previous action params
  tokens.forEach((discount) => {
    draft.discounts[discount.discount_token] = {
      starts_at: new Date(discount.starts_at),
      expires_at: new Date(discount.expires_at),
      discount_percent: discount.discount_percent,
      discount_token: discount.discount_token,
      short_description: discount.short_description,
      type: discount.type,
      discount_plan_codes: discount.discount_plan_codes,
    }
    if (discount.discount_plan_codes) {
      discount.discount_plan_codes.forEach((plan) => {
        if (!draft.paymentPlansByDiscount[discount.discount_token]) {
          draft.paymentPlansByDiscount[discount.discount_token] =
            new Set<string>()
        }
        draft.paymentPlansByDiscount[discount.discount_token].add(
          plan.plan_code
        )
      })
    }
  })
}

const updateDraftWithDiscountTokenInfo = (
  data: PatchDiscountTokenPayload[],
  draft: DiscountState,
  discountToken: string
) => {
  // the data returned by the api is identical to what was sent so we can
  // add it to the redux store from the previous action params
  data.forEach((discount) => {
    //The unique identifier for Discoint token is its name
    //therefore editing it means we need to do some re-mapping of state

    //make sure we are adding the new data to the updated token, if it was changed
    const updatedDiscount = discount.new_discount_token
      ? discount.new_discount_token
      : discount.discount_token

    draft.discounts[updatedDiscount] = {
      starts_at: new Date(discount.starts_at),
      expires_at: new Date(discount.expires_at),
      discount_percent: discount.discount_percent,
      discount_token: discount.discount_token,
      short_description: discount.short_description,
      type: draft.discounts[discount.discount_token].type,
    }

    //re-map the assigned payment plans and delete the old token from the store
    if (discount.new_discount_token) {
      draft.paymentPlansByDiscount[discount.new_discount_token] =
        draft.paymentPlansByDiscount[discountToken]
      delete draft.discounts[discountToken]
      delete draft.paymentPlansByDiscount[discountToken]
    }
  })
}
