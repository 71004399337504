import React, { useCallback, useEffect } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@material-ui/data-grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { allBanners } from 'store/banners/selectors'
import { fetchBanners, updateSelectedBanners } from 'store/banners/actions'
import { AppState } from 'store'
import { Banner } from 'store/banners/types'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, createStyles } from '@material-ui/styles'

function Columns() {
  const apiRef = useGridApiRef()
  const { t } = useTranslation()
  const classes = useStyles({})
  const ctaLinkRef = React.createRef()
  const tRef = 'components.Banners.BannersContainer'
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        // DataGrid made me do it
        // https://stackoverflow.com/questions/66726455/material-ui-get-all-rows-from-datagrid
        apiRef.current = params.api
        return (
          <Typography align="right" style={{ fontSize: '0.8em' }}>
            {params.value}
          </Typography>
        )
      },
    },
    { field: 'domain', headerName: 'Domain', width: 180 },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography className={classes.smallText}>{params.value}</Typography>
        )
      },
    },
    { field: 'currency_code', headerName: 'Currency', width: 180 },
    {
      field: 'discount_token',
      headerName: 'Discount',
      editable: true,
      width: 180,
    },
    {
      field: 'banner_text',
      headerName: 'Banner',
      editable: true,
      width: 400,
      renderCell: (params) => {
        return (
          <Typography className={classes.smallText}>{params.value}</Typography>
        )
      },
    },
    {
      field: 'cta_text',
      headerName: 'CTA Text',
      editable: true,
      width: 180,
      renderCell: (params) => {
        return (
          <Typography className={classes.smallText}>{params.value}</Typography>
        )
      },
    },
    {
      field: 'cta_link',
      headerName: 'CTA Link',
      editable: true,
      width: 400,
      renderCell: (params) => {
        return (
          <InputBase
            className={classes.smallText}
            fullWidth
            placeholder={t(`${tRef}.ctaLink.placeholder`)}
            defaultValue={params.value}
            ref={ctaLinkRef}
          />
        )
      },
    },
  ]
  return { apiRef, columns: columns }
}
const BannersContainer: React.FC = () => {
  const { apiRef, columns } = Columns()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchBanners())
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onSave = useCallback(async () => {
    const selectedRows = Array.from(apiRef.current.getSelectedRows().values())
    await dispatch(updateSelectedBanners(selectedRows as Banner[]))
    dispatch(fetchBanners())
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [apiRef])

  const banners = useSelector<AppState, Banner[]>(
    (state) => allBanners(state.banners),
    isEqual
  )
  return (
    <>
      <Button onClick={onSave}>Save Selected</Button>
      <DataGrid
        autoHeight
        autoPageSize
        checkboxSelection
        columns={columns}
        rows={banners}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    smallText: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      fontSize: '0.8em',
    },
  })
)

export default BannersContainer
