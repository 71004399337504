import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import LoaderButton from 'components/shared/LoaderButton'
import Divider from '@material-ui/core/Divider'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'

import { AppState } from 'store'
import {
  DiscountAmountTypes,
  DiscountConfirmationData,
} from 'store/discounts/types'

interface Props {
  data: DiscountConfirmationData | null
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

const DiscountConfirmationModal: React.FC<Props> = ({
  data,
  onClose,
  onConfirm,
  open,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const tRef = 'components.Discounts.ConfirmationForm'

  const getDiscountAmountText = (formData: DiscountConfirmationData | null) => {
    if (
      formData?.individualPrices &&
      formData?.discountAmountType === DiscountAmountTypes.Amount
    )
      return t(`${tRef}.labels.custom`)
    if (formData?.discountAmountType === DiscountAmountTypes.Percent)
      return `${formData.discountAmount}%`
    return `$${formData?.discountAmount}`
  }
  const isCreatingDiscount = useSelector<AppState, boolean>(
    (state) => state.discounts.meta.CREATE_DISCOUNT.isLoading
  )

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      scroll="body"
      maxWidth="lg"
    >
      <Box className={classes.formContainer}>
        <Container disableGutters className={classes.formHeader}>
          <Typography variant="h2">{t(`${tRef}.title`)}</Typography>
        </Container>
        <Container
          disableGutters
          className={clsx(classes.formGroup, classes.discountDetailsContainer)}
        >
          <Box className={classes.fieldContainer}>
            <InputLabel>{t(`${tRef}.labels.discountCode`)}</InputLabel>
            <Typography variant="body2" className={classes.price}>
              {data?.discountCode}
            </Typography>
          </Box>
          <Box className={classes.fieldContainer}>
            <InputLabel>{t(`${tRef}.labels.discountAmount`)}</InputLabel>
            <Typography variant="body2" className={classes.price}>
              {getDiscountAmountText(data)}
            </Typography>
          </Box>
        </Container>
        <Divider className={classes.divider} />
        <Container disableGutters className={classes.comparisonContainer}>
          <Box className={classes.beforeAfter}>
            <InputLabel>{t(`${tRef}.labels.before`)}</InputLabel>
            <Box>
              {data?.affectedStickerPrices &&
                data.affectedStickerPrices.map((price) => (
                  <Box className={classes.priceContainer} key={price.plan_code}>
                    <Typography variant="body2">{price.plan_name}</Typography>
                    <Typography variant="body2" className={classes.price}>
                      {`$${price.sticker_price.toFixed(0)} (${
                        price.currency_code
                      })`}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          <ArrowForwardIcon className={classes.arrowIcon} />
          <Box className={classes.beforeAfter}>
            <InputLabel>{t(`${tRef}.labels.after`)}</InputLabel>
            <Box>
              {data?.affectedStickerPrices &&
                data.affectedStickerPrices.map((price) => {
                  //if the individual prics toggle is selected, use the value on each plan code
                  const discountAmount =
                    data.individualPrices &&
                    data.discountAmountType === DiscountAmountTypes.Amount
                      ? price.discount_value || 0
                      : data.discountAmount
                  const newPrice =
                    data.discountAmountType === DiscountAmountTypes.Percent
                      ? Math.floor(
                          price.sticker_price * (1 - discountAmount / 100)
                        )
                      : (price.sticker_price - discountAmount).toFixed(0)
                  return (
                    <Box
                      className={classes.priceContainer}
                      key={price.plan_code}
                    >
                      <Typography variant="body2">{price.plan_name}</Typography>
                      <Typography variant="body2" className={classes.price}>
                        {`$${newPrice} (${price.currency_code})`}
                      </Typography>
                    </Box>
                  )
                })}
            </Box>
          </Box>
        </Container>
        <Container disableGutters className={classes.footerContainer}>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={isCreatingDiscount}
          >
            {t(`${tRef}.buttons.back`)}
          </Button>
          <LoaderButton
            variant="contained"
            className={classes.button}
            onClick={onConfirm}
            loading={isCreatingDiscount}
          >
            {t(`${tRef}.buttons.confirm`)}
          </LoaderButton>
        </Container>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1.5rem',
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      alignContent: 'center',
      marginTop: '2rem',
    },
    button: {
      marginLeft: '1rem',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    comparisonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    beforeAfter: {
      flexGrow: 1,
    },
    price: {
      fontWeight: 'bold',
      marginTop: '0.5rem',
    },
    priceContainer: {
      margin: '0.5rem 0rem 0.5rem 0rem',
    },
    arrowIcon: {
      margin: '0rem 1.5rem 0rem 1.5rem',
    },
    divider: {
      margin: '2rem 0rem 2rem 0rem',
    },
    discountDetailsContainer: {
      margin: '2rem 0rem 2rem 0rem',
    },
  })
)

export default DiscountConfirmationModal
