import React, { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  campaignsNeedLoading,
  cohortsNeedLoading,
  emailTemplatesNeedLoading,
  promoWithCohortsAndCampaigns,
} from 'store/promo/selector'
import { PromoWithCohortsAndCampaigns } from 'store/promo/types'
import CreatePromoModal from './CreatePromoModal'

import { AppState } from 'store'
import {
  fetchCampaigns,
  fetchEmailTemplates,
  fetchPromoCohorts,
} from 'store/promo/actions'
import {
  brandsNeedLoading,
  campaignSequencesNeedLoading,
} from 'store/brand/selector'
import { fetchBrands, fetchMailCampaignSequences } from 'store/brand/actions'
import { eventTypesNeedLoading } from 'store/events/selector'
import { fetchEventTypes } from 'store/events/actions'
import ConfirmationModal from 'components/shared/ConfirmationModal'

interface Props {
  open: boolean
  onClose: () => void
  promoID: number | null
  setPromoID: (id: number) => void
}

const PromoModalContainer: React.FC<Props> = ({
  open,
  onClose,
  promoID,
  setPromoID,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const tRef = 'pages.Campaigns.sections.promos.form'

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const showConfirmationModal = useCallback(() => {
    setConfirmModalOpen(true)
  }, [])

  const onConfirm = useCallback(async () => {
    setConfirmModalOpen(false)
    onClose()
  }, [onClose])

  const onCancel = useCallback(() => {
    setConfirmModalOpen(false)
  }, [])

  const promo = useSelector<AppState, PromoWithCohortsAndCampaigns | null>(
    (state) => promoWithCohortsAndCampaigns(state.promos, promoID),
    isEqual
  )

  //check if the cohorts for this promo have been loaded
  // and fetch them if needed
  const loadCohorts = useSelector<AppState, boolean>((state) =>
    cohortsNeedLoading(state.promos, promoID)
  )

  const loadCampaigns = useSelector<AppState, boolean>((state) =>
    campaignsNeedLoading(state.promos, promoID)
  )

  const templatesNeedLoading = useSelector<AppState, boolean>((state) =>
    emailTemplatesNeedLoading(state.promos)
  )

  const loadBrands = useSelector<AppState, boolean>((state) =>
    brandsNeedLoading(state.brands)
  )

  const loadCampaignSequences = useSelector<AppState, boolean>((state) =>
    campaignSequencesNeedLoading(state.brands)
  )

  const loadEventTypes = useSelector<AppState, boolean>((state) =>
    eventTypesNeedLoading(state.events)
  )

  useEffect(() => {
    if (loadCohorts && promoID) {
      dispatch(fetchPromoCohorts(promoID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadCohorts, promoID])

  useEffect(() => {
    if (templatesNeedLoading) {
      dispatch(fetchEmailTemplates())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [templatesNeedLoading])

  useEffect(() => {
    if (loadCampaigns && promoID) {
      dispatch(fetchCampaigns(promoID))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadCohorts, promoID])

  useEffect(() => {
    if (loadBrands) {
      dispatch(fetchBrands())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchBrands])

  useEffect(() => {
    if (loadCampaignSequences) {
      dispatch(fetchMailCampaignSequences())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchMailCampaignSequences])

  useEffect(() => {
    if (loadEventTypes) {
      dispatch(fetchEventTypes())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchEventTypes])

  const closeForm = useCallback(async () => {
    onClose()
  }, [onClose])

  return (
    <>
      <CreatePromoModal
        open={open}
        setPromoID={setPromoID}
        closeForm={closeForm}
        confirmCloseForm={showConfirmationModal}
        promo={promo}
      />
      {confirmModalOpen && (
        <ConfirmationModal
          displayText={t(`${tRef}.saveChangesConfirmation`)}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  )
}

export default PromoModalContainer
