import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { Campaign, CampaignPayload, Cohort } from 'store/promo/types'
import {
  campaignsNeedingApproval,
  promoCampaigns,
  promoCohorts,
  promoIsApproved,
} from 'store/promo/selector'
import { isEqual } from 'lodash'
import {
  approveCampaignsThunk,
  finishPromoSetupThunk,
} from 'store/promo/thunks'
import useAlerts from 'hooks/useAlerts'
import LoaderButton from 'components/shared/LoaderButton'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import { tryGetErrorMessage } from 'utils/errors'

type Inputs = {
  cohortsConfirmed: boolean
  campaignsConfirmed: boolean
  promoID: number
}
interface Props {
  promoID: number
  closeForm: () => void
  confirmCloseForm: () => void
}
const FinishSetupForm: React.FC<Props> = ({
  promoID,
  closeForm,
  confirmCloseForm,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form'
  const dispatch = useDispatch()
  const { infoAlert, errorAlert } = useAlerts()

  const { register, handleSubmit, watch } = useForm<Inputs>()

  const cohortsConfirmed = watch('cohortsConfirmed')
  const campaignsConfirmed = watch('campaignsConfirmed')

  const campaigns = useSelector<AppState, Campaign[]>(
    (state) => promoCampaigns(state.promos, promoID),
    isEqual
  )

  const cohorts = useSelector<AppState, Cohort[]>(
    (state) => promoCohorts(state.promos, promoID),
    isEqual
  )

  const campaignsToApprove = useSelector<AppState, CampaignPayload[]>(
    (state) => campaignsNeedingApproval(state.promos, promoID),
    isEqual
  )

  const promoApproved = useSelector<AppState, boolean>((state) =>
    promoIsApproved(state.promos, promoID)
  )

  const canEdit = !promoApproved || campaignsToApprove.length > 0

  const canFinishSetup =
    cohortsConfirmed && campaignsConfirmed && cohorts.length > 0

  const onSubmit = useCallback(
    async (data) => {
      if (!promoApproved) {
        const result = await dispatch(
          finishPromoSetupThunk(
            data.promoID,
            campaigns
              .filter((c) => !!c.campaignID)
              .map((c) => c.campaignID || 0)
          )
        )
        if (!result) {
          infoAlert('Promo approved!')
          closeForm()
          return
        }
        errorAlert(
          'Failed to finish promo setup: ' + tryGetErrorMessage(result)
        )
      } else {
        const result = await dispatch(
          approveCampaignsThunk(
            data.promoID,
            campaignsToApprove.map((c) => c.id)
          )
        )
        if (!result) {
          infoAlert('Campaigns approved!')
          closeForm()
          return
        }
        errorAlert(
          'Failed to approve campaigns and finish setup: ' +
            tryGetErrorMessage(result)
        )
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [
      campaigns,
      campaignsToApprove,
      promoApproved,
      promoID,
      closeForm,
      infoAlert,
      errorAlert,
    ]
  )

  const [submissionState, submitForm] = useAsyncFn(onSubmit, [onSubmit])

  const onFinishLaterClick = useCallback(() => {
    confirmCloseForm()
  }, [confirmCloseForm])

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        className={classes.footerContainer}
      >
        <input type="hidden" name="promoID" ref={register} value={promoID} />
        <Container disableGutters className={classes.column}>
          <Container disableGutters className={classes.row}>
            <Checkbox
              color="secondary"
              inputRef={register({ required: true })}
              name="cohortsConfirmed"
              defaultChecked={!canEdit}
              disabled={!canEdit}
            />
            <Typography variant="caption" color="secondary">
              {t(`${tRef}.audienceConfirmation`)}
            </Typography>
          </Container>
          <Container disableGutters className={classes.row}>
            <Checkbox
              color="secondary"
              inputRef={register({ required: true })}
              name="campaignsConfirmed"
              defaultChecked={!canEdit}
              disabled={!canEdit}
            />
            <Typography variant="caption" color="secondary">
              {t(`${tRef}.emailConfirmation`)}
            </Typography>
          </Container>
        </Container>
        <Container
          disableGutters
          className={clsx(classes.row, classes.buttonsContainer)}
        >
          {canEdit && (
            <>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={onFinishLaterClick}
              >
                {t(`${tRef}.finishLater`)}
              </Button>
              <LoaderButton
                type="submit"
                variant="contained"
                className={classes.button}
                color="secondary"
                disabled={!canFinishSetup}
                loading={submissionState.loading}
              >
                {t(`${tRef}.finishSetup`)}
              </LoaderButton>
            </>
          )}
        </Container>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonsContainer: {
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: '1rem',
    },
  })
)

export default FinishSetupForm
