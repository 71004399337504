import React from 'react'
import ErrorToolTip from './ErrorToolTip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'

interface Props {
  isValid: boolean
  validationMessage: string
  className?: string
  disabled?: boolean
  defaultValue?: string | number
  inputRef: any
  name: string
}

/*
  A wrapper for an HTML select that adds a tooltip for displaying validation error messages

  Validation is handled by React-Hook-Form. 
  The validation rules are provided via the 'inputRef' parameter in the format as described by React-Hook-Form's documentation
  https://react-hook-form.com/get-started#Applyvalidation
  https://react-hook-form.com/api#register

  Material UI's <Select> does not integrate well with React-Hook-Form's validation, which is why we are using a styled HTML select here
 */
const ValidationSelect: React.FC<Props> = ({
  isValid,
  validationMessage,
  className,
  disabled,
  children,
  defaultValue,
  inputRef,
  name,
}) => {
  const classes = useStyles()
  return (
    <ErrorToolTip
      PopperProps={{
        disablePortal: true,
      }}
      open={!isValid}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={validationMessage}
      arrow
    >
      <div className={classes.selectWrapper}>
        <select
          name={name}
          ref={inputRef}
          defaultValue={defaultValue}
          className={clsx(classes.select, className)}
          disabled={disabled}
        >
          {children}
        </select>
        <ExpandMoreIcon className={classes.arrowIcon} />
      </div>
    </ErrorToolTip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      height: '2.5rem',
      backgroundColor: colors.blue.pa400,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
      fontSize: '1.13em',

      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      appearance: 'none',
      '&:hover, &:focus, &focusVisible': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: `solid 1px ${theme.palette.primary.main}`,
      },
    },
    selectWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '1rem',
    },
    arrowIcon: {
      width: '2.5rem',
      height: '2.5rem',
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
      backgroundColor: colors.blue.pa400,
    },
  })
)

export default ValidationSelect
