import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import ConfirmationModal from 'components/shared/ConfirmationModal'
import CohortForm from './CohortForm'

import { Cohort, CohortPayload, Promo } from 'store/promo/types'
import { AppState } from 'store'
import { isEqual } from 'lodash'
import { cohortByID, promoIsApproved, promos } from 'store/promo/selector'
import { allEventTypes } from 'store/events/selector'
import { EventType } from 'store/events/types'
import { MailCampaignSequence } from 'store/brand/types'
import { removeCohotThunk } from 'store/promo/thunks'
import useAlerts from 'hooks/useAlerts'
import { activeMailCampaignSequences } from 'store/brand/selector'
import { tryGetErrorMessage } from 'utils/errors'

interface CohortProps {
  cohort: Cohort
  index: number
  promoID: number
  removeCohort: (index: number) => void
}

const CohortFormContainer: React.FC<CohortProps> = ({
  cohort,
  index,
  promoID,
  removeCohort,
}) => {
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.audience'
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { errorAlert, infoAlert } = useAlerts()

  const promoApproved = useSelector<AppState, boolean>((state) =>
    promoIsApproved(state.promos, promoID)
  )

  const campaignSequences = useSelector<AppState, MailCampaignSequence[]>(
    (state) => activeMailCampaignSequences(state.brands),
    isEqual
  )

  const allPromos = useSelector<AppState, Promo[]>(
    (state) => promos(state.promos),
    isEqual
  )

  const eventTypes = useSelector<AppState, EventType[]>(
    (state) => allEventTypes(state.events),
    isEqual
  )

  const cohortPayload = useSelector<AppState, CohortPayload | null>((state) =>
    cohortByID(state.promos, cohort.cohortID)
  )

  const canEdit = !promoApproved

  const showConfirmationModal = useCallback(() => {
    setConfirmModalOpen(true)
  }, [])

  const onConfirm = useCallback(async () => {
    if (cohort.cohortID && promoID) {
      setConfirmModalOpen(false)
      const resp = await dispatch(removeCohotThunk(promoID, cohort.cohortID))
      if (!resp) {
        infoAlert('Audience removed!')
        return
      }
      errorAlert('Failed to remove Audience: ' + tryGetErrorMessage(resp))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoID, cohort.cohortID, infoAlert, errorAlert])

  const onCancel = useCallback(() => {
    setConfirmModalOpen(false)
  }, [setConfirmModalOpen])

  return (
    <Box>
      <Container
        disableGutters
        className={clsx(classes.row, classes.paddedHeader)}
      >
        <Typography variant="body2" className={classes.formLabel}>
          {t(`${tRef}.targetAudience`, { number: index + 1 })}
        </Typography>

        <>
          {removeCohort && !cohort.cohortID && canEdit && (
            <IconButton
              className={classes.removeButton}
              onClick={() => removeCohort(index)}
            >
              <RemoveCircleIcon className={classes.removeIcon} />
            </IconButton>
          )}
          {cohort.cohortID && canEdit && (
            <IconButton
              className={classes.removeButton}
              onClick={() => showConfirmationModal()}
            >
              <RemoveCircleIcon className={classes.removeIcon} />
            </IconButton>
          )}
        </>
      </Container>
      {cohort && (
        <>
          <Box className={classes.audienceContainer}>
            <CohortForm
              cohort={cohort}
              index={index}
              promoID={promoID}
              canEdit={canEdit}
              removeCohort={removeCohort}
              campaignSequences={campaignSequences}
              promos={allPromos}
              eventTypes={eventTypes}
              existingCohort={cohortPayload}
            />
          </Box>
          {confirmModalOpen && (
            <ConfirmationModal
              displayText={t(`${tRef}.deleteConfirmation`)}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowPadded: {
      marginTop: '1rem',
    },
    formLabel: {
      fontSize: '0.9rem',
      color: colors.blue.pa100,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    paddedHeader: {
      marginBottom: '1rem',
    },
    audienceContainer: {
      borderLeft: `solid 3px ${colors.blue.pa100}`,
      paddingLeft: '1rem',
      marginBottom: '1rem',
    },
    removeIcon: {
      color: colors.red[600],
      width: '1.2rem',
      height: '1.2rem',
    },
    removeButton: {
      marginLeft: '1rem',
      width: '1.2rem',
      height: '1.2rem',
    },
  })
)

export default CohortFormContainer
