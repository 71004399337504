import * as ram from '@talentinc/redux-axios-middleware'
import { EventType } from './types'

export enum EventActions {
  FETCH_EVENT_TYPES = 'FETCH_EVENT_TYPES',
  FETCH_EVENT_TYPES_SUCCESS = 'FETCH_EVENT_TYPES_SUCCESS',
  FETCH_EVENT_TYPES_FAIL = 'FETCH_EVENT_TYPES_FAIL',
}

interface FetchEventTypes extends ram.AxiosMiddlewareActionCreator {
  type: EventActions.FETCH_EVENT_TYPES
}

interface FetchEventTypesSuccess
  extends ram.AxiosMiddlewareActionSuccess<EventType[], FetchEventTypes> {
  type: EventActions.FETCH_EVENT_TYPES_SUCCESS
}

export interface FetchEventTypesFail
  extends ram.AxiosMiddlewareActionFail<FetchEventTypes> {
  type: EventActions.FETCH_EVENT_TYPES_FAIL
}

export const fetchEventTypes = (): FetchEventTypes => ({
  type: EventActions.FETCH_EVENT_TYPES,
  payload: {
    request: {
      url: '/v2/events/types',
    },
  },
})

export type EventAction =
  | FetchEventTypes
  | FetchEventTypesSuccess
  | FetchEventTypesFail
