import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PageShell from 'pages/PageShell'

const HomePage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <PageShell pageTitle="Home">
      <Box>
        <Typography variant="h3">{t('pages.Home.welcome')}</Typography>
      </Box>
    </PageShell>
  )
}

export default HomePage
