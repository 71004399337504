import produce from 'immer'
import * as stateUtils from '@talentinc/state-utils'
import { initialPromoState, PromoState } from './types'
import { PromoAction, PromoActions } from './actions'

export default function promosReaducer(
  state: PromoState = initialPromoState,
  action: PromoAction
): PromoState {
  return produce(state, (draft) => {
    switch (action.type) {
      case PromoActions.CREATE_PROMO:
      case PromoActions.FETCH_PROMOS:
      case PromoActions.FETCH_EMAIL_TEMPLATES:
      case PromoActions.CREATE_CAMPAIGN:
      case PromoActions.CREATE_COHORT:
      case PromoActions.TEST_EMAIL_TEMPLATE:
        draft.meta[action.type] = stateUtils.setStartState(
          state.meta[action.type]
        )
        break

      case PromoActions.CREATE_PROMO_SUCCESS:
      case PromoActions.APPROVE_PROMO_SUCCESS: {
        const promo = action.payload.data
        draft.meta.CREATE_PROMO = stateUtils.setSuccessState(
          state.meta.CREATE_PROMO
        )
        draft.promos[promo.id] = promo
        break
      }

      case PromoActions.CANCEL_PROMO_SUCCESS:
      case PromoActions.UPDATE_PROMO_SUCCESS: {
        const { previousAction } = action.meta
        const promo = action.payload.data
        draft.meta.UPDATE_PROMO[previousAction.promoID] =
          stateUtils.setSuccessState(
            state.meta.UPDATE_PROMO[previousAction.promoID]
          )
        draft.promos[promo.id] = promo
        break
      }
      case PromoActions.CREATE_PROMO_FAIL:
      case PromoActions.FETCH_PROMOS_FAIL:
      case PromoActions.FETCH_EMAIL_TEMPLATES_FAIL:
      case PromoActions.CREATE_CAMPAIGN_FAIL:
      case PromoActions.TEST_EMAIL_TEMPLATE_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setErrorState(
          state.meta[previousActionType],
          action.error
        )
        break
      }

      case PromoActions.FETCH_PROMOS_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((promo) => {
          draft.promos[promo.id] = promo
        })
        break
      }
      case PromoActions.FETCH_PROMO_EXCLUSION_LISTS:
      case PromoActions.APPROVE_PROMO:
      case PromoActions.FETCH_PROMO_SIZE_ESTIMATE:
      case PromoActions.FETCH_PROMO_COHORTS:
      case PromoActions.FETCH_CAMPAIGNS:
      case PromoActions.UPDATE_PROMO:
      case PromoActions.CANCEL_PROMO: {
        draft.meta[action.type][action.promoID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case PromoActions.FETCH_PROMO_COHORTS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.promoID]
          )

        const results = action.payload.data
        results.forEach((cohort) => {
          draft.cohorts[cohort.id] = cohort
          if (!draft.cohortsByPromoID[previousAction.promoID]) {
            draft.cohortsByPromoID[previousAction.promoID] = new Set<number>()
          }
          draft.cohortsByPromoID[previousAction.promoID].add(cohort.id)
        })
        break
      }
      case PromoActions.FETCH_PROMO_EXCLUSION_LISTS_FAIL:
      case PromoActions.APPROVE_PROMO_FAIL:
      case PromoActions.FETCH_PROMO_SIZE_ESTIMATE_FAIL:
      case PromoActions.FETCH_PROMO_COHORTS_FAIL:
      case PromoActions.FETCH_CAMPAIGNS_FAIL:
      case PromoActions.UPDATE_PROMO_FAIL:
      case PromoActions.CANCEL_PROMO_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.promoID] =
          stateUtils.setErrorState(
            state.meta[previousActionType][action.meta.previousAction.promoID],
            action.error
          )
        break
      }
      case PromoActions.REMOVE_PROMO_COHORT: {
        draft.meta[action.type][action.cohortID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case PromoActions.REMOVE_PROMO_COHORT_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.cohortID]
          )

        delete draft.cohorts[previousAction.cohortID]

        const remainingCohorts = Array.from(
          draft.cohortsByPromoID[previousAction.promoID]
        ).filter((c) => c !== previousAction.cohortID)

        draft.cohortsByPromoID[previousAction.promoID] = new Set<number>(
          remainingCohorts
        )
        break
      }
      case PromoActions.FETCH_EMAIL_TEMPLATES_SUCCESS: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType] = stateUtils.setSuccessState(
          state.meta[previousActionType]
        )
        action.payload.data.forEach((template) => {
          draft.emailTemplates[template.id] = template
        })
        break
      }
      case PromoActions.FETCH_CAMPAIGNS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.promoID]
          )

        const results = action.payload.data
        results.forEach((campaign) => {
          draft.campaigns[campaign.id] = campaign
          if (!draft.campaignsByPromoID[previousAction.promoID]) {
            draft.campaignsByPromoID[previousAction.promoID] = new Set<number>()
          }
          draft.campaignsByPromoID[previousAction.promoID].add(campaign.id)
        })
        break
      }
      case PromoActions.CREATE_CAMPAIGN_SUCCESS: {
        const campaign = action.payload.data
        const { previousAction } = action.meta
        draft.meta.CREATE_CAMPAIGN = stateUtils.setSuccessState(
          state.meta.CREATE_CAMPAIGN
        )
        draft.campaigns[campaign.id] = campaign
        if (!draft.campaignsByPromoID[previousAction.promoID]) {
          draft.campaignsByPromoID[previousAction.promoID] = new Set<number>()
        }
        draft.campaignsByPromoID[previousAction.promoID].add(
          campaign.id as number
        )
        break
      }
      case PromoActions.APPROVE_CAMPAIGN_SUCCESS:
      case PromoActions.UPDATE_CAMPAIGN_SUCCESS: {
        const { previousAction } = action.meta
        const campaign = action.payload.data
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            state.meta.UPDATE_CAMPAIGN[previousAction.campaignID]
          )
        draft.campaigns[previousAction.campaignID] = campaign
        break
      }
      case PromoActions.REMOVE_PROMO_CAMPAIGN:
      case PromoActions.UPDATE_CAMPAIGN: {
        draft.meta[action.type][action.campaignID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }

      case PromoActions.REMOVE_PROMO_CAMPAIGN_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.campaignID]
          )

        delete draft.campaigns[previousAction.campaignID]

        const remainingCampaigns = Array.from(
          draft.campaignsByPromoID[previousAction.promoID]
        ).filter((c) => c !== previousAction.campaignID)

        draft.campaignsByPromoID[previousAction.promoID] = new Set<number>(
          remainingCampaigns
        )
        break
      }
      case PromoActions.REMOVE_PROMO_CAMPAIGN_FAIL:
      case PromoActions.UPDATE_CAMPAIGN_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.campaignID] =
          stateUtils.setErrorState(
            state.meta[previousActionType][
              action.meta.previousAction.campaignID
            ],
            action.error
          )
        break
      }
      case PromoActions.FETCH_COHORT_SIZE_ESTIMATE:
      case PromoActions.UPDATE_COHORT: {
        draft.meta[action.type][action.cohortID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case PromoActions.FETCH_COHORT_SIZE_ESTIMATE_FAIL:
      case PromoActions.REMOVE_PROMO_COHORT_FAIL:
      case PromoActions.UPDATE_COHORT_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.cohortID] =
          stateUtils.setErrorState(
            state.meta[previousActionType][action.meta.previousAction.cohortID],
            action.error
          )
        break
      }
      case PromoActions.UPDATE_COHORT_SUCCESS: {
        const { previousAction } = action.meta
        const cohort = action.payload.data
        draft.meta.UPDATE_COHORT[previousAction.promoID] =
          stateUtils.setSuccessState(
            state.meta.UPDATE_COHORT[previousAction.cohortID]
          )
        draft.cohorts[previousAction.cohortID] = cohort
        break
      }
      case PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS:
      case PromoActions.APPROVE_CAMPAIGN:
      case PromoActions.FETCH_CAMPAIGN_QUEUE_STATS: {
        draft.meta[action.type][action.campaignID] = stateUtils.setStartState(
          stateUtils.initialLoadedLoadingErrorState
        )
        break
      }
      case PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS_FAIL:
      case PromoActions.APPROVE_CAMPAIGN_FAIL:
      case PromoActions.FETCH_CAMPAIGN_QUEUE_STATS_FAIL: {
        const previousActionType = action.meta.previousAction.type
        draft.meta[previousActionType][action.meta.previousAction.campaignID] =
          stateUtils.setErrorState(
            state.meta[previousActionType][
              action.meta.previousAction.campaignID
            ],
            action.error
          )
        break
      }
      case PromoActions.FETCH_CAMPAIGN_QUEUE_STATS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta.FETCH_CAMPAIGN_QUEUE_STATS[previousAction.campaignID] =
          stateUtils.setSuccessState(
            state.meta.FETCH_CAMPAIGN_QUEUE_STATS[previousAction.campaignID]
          )
        draft.campaignSendStats[previousAction.campaignID] = action.payload.data
        break
      }
      case PromoActions.FETCH_PROMO_SIZE_ESTIMATE_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta.FETCH_PROMO_SIZE_ESTIMATE[previousAction.promoID] =
          stateUtils.setSuccessState(
            state.meta.FETCH_PROMO_SIZE_ESTIMATE[previousAction.promoID]
          )
        draft.promoSizeEstimates[previousAction.promoID] = action.payload.data
        break
      }
      case PromoActions.FETCH_COHORT_SIZE_ESTIMATE_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta.FETCH_COHORT_SIZE_ESTIMATE[previousAction.cohortID] =
          stateUtils.setSuccessState(
            state.meta.FETCH_COHORT_SIZE_ESTIMATE[previousAction.cohortID]
          )
        draft.cohortSizeEstimates[previousAction.cohortID] = action.payload.data
        break
      }
      case PromoActions.FETCH_PROMO_EXCLUSION_LISTS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta[previousAction.type][previousAction.promoID] =
          stateUtils.setSuccessState(
            draft.meta[previousAction.type][previousAction.promoID]
          )

        draft.exclusionLists[previousAction.promoID] = action.payload.data

        break
      }
      case PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS_SUCCESS: {
        const { previousAction } = action.meta
        draft.meta.FETCH_CAMPAIGN_DELIVERY_STATS[previousAction.campaignID] =
          stateUtils.setSuccessState(
            state.meta.FETCH_CAMPAIGN_DELIVERY_STATS[previousAction.campaignID]
          )
        draft.campaignDeliveryStats[previousAction.campaignID] =
          action.payload.data
        break
      }
      case PromoActions.TEST_EMAIL_TEMPLATE_SUCCESS: {
        draft.meta.TEST_EMAIL_TEMPLATE = stateUtils.setSuccessState(
          state.meta.TEST_EMAIL_TEMPLATE
        )
        break
      }
    }
  })
}
