import { HashMap } from '@talentinc/state-utils'
import {
  ActionTypes,
  AudienceTypes,
  Behavior,
  Cohort,
  CohortPayload,
  CohortPayloadBase,
  CohortSources,
  EngagementTypes,
  PromoFilterFields,
  PromoFilterSetting,
} from './types'
import produce from 'immer'

//generates a CohortPayloadBase to be used by the api
export function createCohortPayload(
  cohort: Cohort,
  promoID: number
): CohortPayloadBase {
  const payload: CohortPayloadBase = {
    promo_id: promoID,
    name: cohort.name || '',
    filters: [],
  }

  //create filters for lead/customer
  payload.filters.push({
    include: true,
    type:
      cohort.audienceType === AudienceTypes.Customer
        ? EngagementTypes.CustomerCreated
        : EngagementTypes.LeadCreated,
    name: `Promo ${promoID} ${AudienceTypes.Customer} Filter`,
    settings: [
      {
        field: PromoFilterFields.StartDate,
        value: cohort.startDate
          ? cohort.startDate.toISOString()
          : new Date().toISOString(),
      },
      {
        field: PromoFilterFields.EndDate,
        value: cohort.endDate
          ? cohort.endDate.toISOString()
          : new Date().toISOString(),
      },
    ],
  })

  /**
   the `include` field below is tricky on this filter
   true = Purchasers WILL NOT be excluded
   false = Purchasers WILL be excluded
   */
  payload.filters.push({
    include: cohort.audienceType === AudienceTypes.Customer,
    type: EngagementTypes.ExcludePurchasers,
    name: `Promo ${promoID} Exclude Purchaser Filter`,
    settings: [],
  })

  //create lead score filter
  payload.filters.push({
    include: true,
    type: EngagementTypes.LeadScore,
    name: `Promo ${promoID} Lead Score Filter`,
    settings: [
      {
        field: PromoFilterFields.MinLeadScore,
        value: cohort.leadScoreStart?.toString() || '',
      },
      {
        field: PromoFilterFields.MaxLeadScore,
        value: cohort.leadScoreEnd?.toString() || '',
      },
    ],
  })

  //create mail campaign sequence filter
  payload.filters.push({
    include: true,
    type: EngagementTypes.MailCampaignSequence,
    name: `Promo ${promoID} Mail Campaign Sequence Filter`,
    settings: [
      {
        field: PromoFilterFields.MailCampaignSequenceID,
        value: cohort.mailCampaignSequence?.toString() || '',
      },
    ],
  })

  //create filters for each behavior by engagement type
  cohort.behaviors.forEach((behavior, bIndex) => {
    const engagementType = behavior.engagementType
    if (engagementType) {
      switch (engagementType) {
        case EngagementTypes.DeliveredPromoEmail:
        case EngagementTypes.ClickedPromoEmail:
        case EngagementTypes.OpenedPromoEmail:
          payload.filters.push({
            include: behavior.actionType === ActionTypes.Did,
            type: engagementType,
            name: `Promo ${promoID} Action Filter ${bIndex}`,
            settings: [
              {
                field: PromoFilterFields.PromoID,
                value: behavior.engagementKey || '',
              },
            ],
          })
          break
        case EngagementTypes.AramisEvent:
          payload.filters.push({
            include: behavior.actionType === ActionTypes.Did,
            type: engagementType,
            name: `Promo ${promoID} Aramis Event Filter ${bIndex}`,
            settings: [
              {
                field: PromoFilterFields.EventTypeID,
                value: behavior.engagementKey || '',
              },
              {
                field: PromoFilterFields.System,
                value: behavior.system || '',
              },
              {
                field: PromoFilterFields.StartDate,
                value: behavior.startDate
                  ? behavior.startDate.toISOString()
                  : new Date().toISOString(),
              },
              {
                field: PromoFilterFields.EndDate,
                value: behavior.endDate
                  ? behavior.endDate.toISOString()
                  : new Date().toISOString(),
              },
            ],
          })
          break
      }
    }
  })

  return payload
}

//takes an existing CohortPayload and updates it with new values from a Cohort
//preserving the existing ID's
export function updatePayloadWithCohort(
  cohort: Cohort,
  existingPayload: CohortPayload
): CohortPayload {
  let payload: CohortPayload = { ...existingPayload }
  const behaviorsMap: HashMap<Behavior> = {}
  if (cohort.source === CohortSources.Database) {
    //building a map of new cohort behaviors
    cohort.behaviors.forEach((behavior) => {
      if (behavior.behaviorID) {
        behaviorsMap[behavior.behaviorID] = behavior
      }
    })

    //objects are auto frozen and therefore immutable.
    //using immer's produce  here to generate an updated copy of the payload
    const finalPayload = produce(existingPayload, (draft) => {
      for (let i = 0; i < draft.filters.length; i++) {
        const filter = draft.filters[i]
        switch (filter.type) {
          case EngagementTypes.CustomerCreated:
          case EngagementTypes.LeadCreated:
            draft.filters[i] = {
              ...filter,
              type:
                cohort.audienceType === AudienceTypes.Customer
                  ? EngagementTypes.CustomerCreated
                  : EngagementTypes.LeadCreated,
            }
            for (let j = 0; j < filter.settings.length; j++) {
              const setting = filter.settings[j]
              switch (setting.field) {
                case PromoFilterFields.StartDate:
                  if (cohort.startDate) {
                    draft.filters[i].settings[j] = {
                      ...setting,
                      value: cohort.startDate?.toISOString(),
                    }
                  }
                  break
                case PromoFilterFields.EndDate:
                  if (cohort.endDate) {
                    draft.filters[i].settings[j] = {
                      ...setting,
                      value: cohort.endDate?.toISOString(),
                    }
                  }
                  break
              }
            }
            break
          case EngagementTypes.ExcludePurchasers:
            draft.filters[i].include =
              cohort.audienceType !== AudienceTypes.Customer
            break
          case EngagementTypes.LeadScore:
            for (let j = 0; j < filter.settings.length; j++) {
              const setting = filter.settings[j]
              switch (setting.field) {
                case PromoFilterFields.MinLeadScore:
                  if (cohort.leadScoreStart) {
                    draft.filters[i].settings[j] = {
                      ...setting,
                      value: cohort.leadScoreStart.toString(),
                    } as PromoFilterSetting
                  }
                  break
                case PromoFilterFields.MaxLeadScore:
                  if (cohort.leadScoreEnd) {
                    draft.filters[i].settings[j] = {
                      ...setting,
                      value: cohort.leadScoreEnd.toString(),
                    } as PromoFilterSetting
                  }
                  break
              }
            }
            break
          case EngagementTypes.MailCampaignSequence:
            for (let j = 0; j < filter.settings.length; j++) {
              const setting = filter.settings[j]
              if (setting.field === PromoFilterFields.MailCampaignSequenceID) {
                draft.filters[i].settings[j] = {
                  ...setting,
                  value: cohort.mailCampaignSequence?.toString() || '',
                } as PromoFilterSetting
              }
            }

            break
        }
      }
    })
    payload = finalPayload
  }

  return payload
}
