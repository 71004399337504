import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import useAlerts from 'hooks/useAlerts'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import { fetchPromoExclusionLists } from 'store/promo/actions'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import LinearProgress from '@material-ui/core/LinearProgress'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { ExclusionList } from 'store/promo/types'
import {
  exclusionListsForPromo,
  exclusionListsLoadedOnce,
} from 'store/promo/selector'
import { AppState } from 'store'
import { uploadExclustionListThunk } from 'store/promo/thunks'
import { tryGetErrorMessage } from 'utils/errors'

interface Props {
  promoID: number
}

type ExclusionListUploadInputs = {
  file: File
  promoID: number
}

const ExclusionLists: React.FC<Props> = ({ promoID }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.form.audienceEsclusion'
  const { t } = useTranslation()

  const exclusionListsLoaded = useSelector<AppState, boolean>((state) =>
    exclusionListsLoadedOnce(state.promos, promoID)
  )
  const exclusionLists = useSelector<AppState, ExclusionList[]>((state) =>
    exclusionListsForPromo(state.promos, promoID)
  )

  useEffect(() => {
    if (!exclusionListsLoaded) {
      dispatch(fetchPromoExclusionLists(promoID))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusionListsLoaded])

  if (!exclusionListsLoaded)
    return <LinearProgress className={classes.linearProgress} />
  if (exclusionLists.length === 0) return null
  return (
    <Box>
      <InputLabel className={classes.paddedHeader}>
        {t(`${tRef}.header`)}
      </InputLabel>
      <Container disableGutters className={classes.exclusionListsContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant="body2"
                  className={clsx(classes.formLabel, classes.csvNameLabel)}
                >
                  {t(`${tRef}.name`)}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant="body2"
                  className={clsx(classes.formLabel, classes.csvSizeLabel)}
                >
                  {t(`${tRef}.listSize`)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exclusionLists.map((list) => (
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.csvName}
                  >
                    {list.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography variant="body2">{list.size}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Box>
  )
}

const ExclusionListsContainer: React.FC<Props> = ({ promoID }) => {
  return (
    <Box>
      <ExclusionLists promoID={promoID} />
      <ExclusionListForm promoID={promoID} />
    </Box>
  )
}

const ExclusionListForm: React.FC<Props> = ({ promoID }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tRef = 'pages.Campaigns.sections.promos.form.audience'

  const { register, handleSubmit, watch, reset } =
    useForm<ExclusionListUploadInputs>()
  const fileList = watch('file') as unknown as FileList
  const { infoAlert, errorAlert } = useAlerts()

  const onSubmit = useCallback(
    async (data) => {
      const files = data.file as FileList
      if (files && files.length > 0) {
        const error = await dispatch(
          uploadExclustionListThunk(files[0], promoID)
        )
        if (!error) {
          reset()
          infoAlert('Changes saved!')
          return
        }
        errorAlert('Upload failed: ' + tryGetErrorMessage(error))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promoID, infoAlert, errorAlert]
  )

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="promoID" value={promoID} ref={register} />
        <Box className={classes.fieldContainer}>
          <Container disableGutters className={classes.row}>
            <Button
              variant="outlined"
              component="label"
              className={classes.leftButton}
            >
              {!(fileList && fileList.length > 0) && t(`${tRef}.uploadCSV`)}
              {fileList && fileList.length > 0 && t(`${tRef}.changeFile`)}
              <input type="file" name="file" hidden ref={register} />
            </Button>
            {fileList && fileList.length > 0 && (
              <Button variant="contained" type="submit">
                {t(`${tRef}.uploadFileName`, { fileName: fileList[0].name })}
              </Button>
            )}
          </Container>
        </Box>
      </form>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    paddedHeader: {
      marginBottom: '1rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    leftButton: {
      marginRight: '1rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    csvFieldContainer: {
      marginRight: '1rem',
    },
    formLabel: {
      fontSize: '0.9rem',
      color: colors.blue.pa100,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    csvNameLabel: {
      marginBottom: '0.5rem',
    },
    csvSizeLabel: {
      marginBottom: '0.55rem',
    },
    csvName: {
      fontSize: '1.2rem',
    },
    exclusionListsContainer: {
      borderLeft: `solid 3px ${colors.blue.pa100}`,
      paddingLeft: '1rem',
      marginBottom: '1rem',
    },
    tableCell: {
      padding: '0rem 1rem 0rem 0rem',
      border: 'none',
    },
    linearProgress: {
      width: '10rem',
      margin: '2rem',
    },
  })
)

export default ExclusionListsContainer
