import * as ram from '@talentinc/redux-axios-middleware'
import { AxiosError } from 'axios'
import {
  CampaignPayload,
  CohortPayload,
  CampaignPayloadBase,
  EmailTemplate,
  Promo,
  PromoBase,
  TestEmailTemplatePayload,
  UpdateCampaignPayload,
  CohortPayloadBase,
  CampaignSendStat,
  UserListEstimate,
  ExclusionList,
  CampaignDeliveryStat,
} from './types'

export enum PromoActions {
  CREATE_PROMO = 'CREATE_PROMO',
  CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS',
  CREATE_PROMO_FAIL = 'CREATE_PROMO_FAIL',
  FETCH_PROMOS = 'FETCH_PROMOS',
  FETCH_PROMOS_SUCCESS = 'FETCH_PROMOS_SUCCESS',
  FETCH_PROMOS_FAIL = 'FETCH_PROMOS_FAIL',
  UPLOAD_INCLUSION_LIST = 'UPLOAD_INCLUSION_LIST',
  UPLOAD_INCLUSION_LIST_SUCCESS = 'UPLOAD_ INCLUSION_LIST_SUCCESS',
  UPLOAD_INCLUSION_LIST_FAIL = 'UPLOAD_INCLUSION_LIST_FAIL',
  UPLOAD_EXCLUSION_LIST = 'UPLOAD_EXCLUSION_LIST',
  UPLOAD_EXCLUSION_LIST_SUCCESS = 'UPLOAD_EXCLUSION_LIST_SUCCESS',
  UPLOAD_EXCLUSION_LIST_FAIL = 'UPLOAD_EXCLUSION_LIST_FAIL',
  FETCH_PROMO_COHORTS = 'FETCH_PROMO_COHORTS',
  FETCH_PROMO_COHORTS_SUCCESS = 'FETCH_PROMO_COHORTS_SUCCESS',
  FETCH_PROMO_COHORTS_FAIL = 'FETCH_PROMO_COHORTS_FAIL',
  REMOVE_PROMO_COHORT = 'REMOVE_PROMO_COHORT',
  REMOVE_PROMO_COHORT_SUCCESS = 'REMOVE_PROMO_COHORT_SUCCESS',
  REMOVE_PROMO_COHORT_FAIL = 'REMOVE_PROMO_COHORT_FAIL',
  FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES',
  FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS',
  FETCH_EMAIL_TEMPLATES_FAIL = 'FETCH_EMAIL_TEMPLATES_FAIL',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL',
  FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS',
  FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNS_FAIL = 'FETCH_CAMPAIGNS_FAIL',
  REMOVE_PROMO_CAMPAIGN = 'REMOVE_PROMO_CAMPAIGN',
  REMOVE_PROMO_CAMPAIGN_SUCCESS = 'REMOVE_PROMO_CAMPAIGN_SUCCESS',
  REMOVE_PROMO_CAMPAIGN_FAIL = 'REMOVE_PROMO_CAMPAIGN_FAIL',
  APPROVE_CAMPAIGN = 'APPROVE_CAMPAIGN',
  APPROVE_CAMPAIGN_SUCCESS = 'APPROVE_CAMPAIGN_SUCCESS',
  APPROVE_CAMPAIGN_FAIL = 'APPROVE_CAMPAIGN_FAIL',
  APPROVE_PROMO = 'APPROVE_PROMO',
  APPROVE_PROMO_SUCCESS = 'APPROVE_PROMO_SUCCESS',
  APPROVE_PROMO_FAIL = 'APPROVE_PROMO_FAIL',
  TEST_EMAIL_TEMPLATE = 'TEST_EMAIL_TEMPLATE',
  TEST_EMAIL_TEMPLATE_SUCCESS = 'TEST_EMAIL_TEMPLATE_SUCCESS',
  TEST_EMAIL_TEMPLATE_FAIL = 'TEST_EMAIL_TEMPLATE_FAIL',
  UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAIL = 'UPDATE_CAMPAIGN_FAIL',
  UPDATE_PROMO = 'UPDATE_PROMO',
  UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS',
  UPDATE_PROMO_FAIL = 'UPDATE_PROMO_FAIL',
  CREATE_COHORT = 'CREATE_COHORT',
  CREATE_COHORT_SUCCESS = 'CREATE_COHORT_SUCCESS',
  CREATE_COHORT_FAIL = 'CREATE_COHORT_FAIL',
  UPDATE_COHORT = 'UPDATE_COHORT',
  UPDATE_COHORT_SUCCESS = 'UPDATE_COHORT_SUCCESS',
  UPDATE_COHORT_FAIL = 'UPDATE_COHORT_FAIL',
  CANCEL_PROMO = 'CANCEL_PROMO',
  CANCEL_PROMO_SUCCESS = 'CANCEL_PROMO_SUCCESS',
  CANCEL_PROMO_FAIL = 'CANCEL_PROMO_FAIL',
  FETCH_CAMPAIGN_QUEUE_STATS = 'FETCH_CAMPAIGN_QUEUE_STATS',
  FETCH_CAMPAIGN_QUEUE_STATS_SUCCESS = 'FETCH_CAMPAIGN_QUEUE_STATS_SUCCESS',
  FETCH_CAMPAIGN_QUEUE_STATS_FAIL = 'FETCH_CAMPAIGN_QUEUE_STATS_FAIL',
  FETCH_PROMO_SIZE_ESTIMATE = 'FETCH_PROMO_SIZE_ESTIMATE',
  FETCH_PROMO_SIZE_ESTIMATE_SUCCESS = 'FETCH_PROMO_SIZE_ESTIMATE_SUCCESS',
  FETCH_PROMO_SIZE_ESTIMATE_FAIL = 'FETCH_PROMO_SIZE_ESTIMATE_FAIL',
  FETCH_COHORT_SIZE_ESTIMATE = 'FETCH_COHORT_SIZE_ESTIMATE',
  FETCH_COHORT_SIZE_ESTIMATE_SUCCESS = 'FETCH_COHORT_SIZE_ESTIMATE_SUCCESS',
  FETCH_COHORT_SIZE_ESTIMATE_FAIL = 'FETCH_COHORT_SIZE_ESTIMATE_FAIL',
  FETCH_PROMO_EXCLUSION_LISTS = 'FETCH_PROMO_EXCLUSION_LISTS',
  FETCH_PROMO_EXCLUSION_LISTS_SUCCESS = 'FETCH_PROMO_EXCLUSION_LISTS_SUCCESS',
  FETCH_PROMO_EXCLUSION_LISTS_FAIL = 'FETCH_PROMO_EXCLUSION_LISTS_FAIL',
  FETCH_CAMPAIGN_DELIVERY_STATS = 'FETCH_CAMPAIGN_DELIVERY_STATS',
  FETCH_CAMPAIGN_DELIVERY_STATS_SUCCESS = 'FETCH_CAMPAIGN_DELIVERY_STATS_SUCCESS',
  FETCH_CAMPAIGN_DELIVERY_STATS_FAIL = 'FETCH_CAMPAIGN_DELIVERY_STATS_FAIL',
}

interface CreatePromo extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.CREATE_PROMO
}

export interface CreatePromoSuccess
  extends ram.AxiosMiddlewareActionSuccess<Promo, CreatePromo> {
  type: PromoActions.CREATE_PROMO_SUCCESS
}

interface CreatePromoFail extends ram.AxiosMiddlewareActionFail<CreatePromo> {
  type: PromoActions.CREATE_PROMO_FAIL
}

interface FetchPromos extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_PROMOS
}

interface FetchPromosSuccess
  extends ram.AxiosMiddlewareActionSuccess<Promo[], FetchPromos> {
  type: PromoActions.FETCH_PROMOS_SUCCESS
}

interface FetchPromosFail extends ram.AxiosMiddlewareActionFail<FetchPromos> {
  type: PromoActions.FETCH_PROMOS_FAIL
}

interface UploadInclusionList extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.UPLOAD_INCLUSION_LIST
  file: File
}

export interface UploadInclusionListSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, UploadInclusionList> {
  type: PromoActions.UPLOAD_INCLUSION_LIST_SUCCESS
}

interface UploadInclusionListFail
  extends Omit<ram.AxiosMiddlewareActionFail<UploadInclusionList>, 'error'> {
  type: PromoActions.UPLOAD_INCLUSION_LIST_FAIL
  error: AxiosError
}

interface UploadExclusionList extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.UPLOAD_EXCLUSION_LIST
  file: File
}

export interface UploadExclusionListSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, UploadExclusionList> {
  type: PromoActions.UPLOAD_EXCLUSION_LIST_SUCCESS
}

interface UploadExclusionListFail
  extends Omit<ram.AxiosMiddlewareActionFail<UploadExclusionList>, 'error'> {
  type: PromoActions.UPLOAD_EXCLUSION_LIST_FAIL
  error: AxiosError
}

interface FetchPromoCohorts extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_PROMO_COHORTS
  promoID: number
}

interface FetchPromoCohortsSuccess
  extends ram.AxiosMiddlewareActionSuccess<CohortPayload[], FetchPromoCohorts> {
  type: PromoActions.FETCH_PROMO_COHORTS_SUCCESS
}

interface FetchPromoCohortsFail
  extends ram.AxiosMiddlewareActionFail<FetchPromoCohorts> {
  type: PromoActions.FETCH_PROMO_COHORTS_FAIL
}

interface RemovePromoCohort extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.REMOVE_PROMO_COHORT
  promoID: number
  cohortID: number
}

interface RemovePromoCohortSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, RemovePromoCohort> {
  type: PromoActions.REMOVE_PROMO_COHORT_SUCCESS
}

interface RemovePromoCohortFail
  extends ram.AxiosMiddlewareActionFail<RemovePromoCohort> {
  type: PromoActions.REMOVE_PROMO_COHORT_FAIL
}

interface FetchEmailTemplates extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_EMAIL_TEMPLATES
}

interface FetchEmailTemplatesSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    EmailTemplate[],
    FetchEmailTemplates
  > {
  type: PromoActions.FETCH_EMAIL_TEMPLATES_SUCCESS
}

interface FetchEmailTemplatesFail
  extends ram.AxiosMiddlewareActionFail<FetchEmailTemplates> {
  type: PromoActions.FETCH_EMAIL_TEMPLATES_FAIL
}

interface FetchCampaigns extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_CAMPAIGNS
  promoID: number
}

interface FetchCampaignsSuccess
  extends ram.AxiosMiddlewareActionSuccess<CampaignPayload[], FetchCampaigns> {
  type: PromoActions.FETCH_CAMPAIGNS_SUCCESS
}

interface FetchCampaignsFail
  extends ram.AxiosMiddlewareActionFail<FetchCampaigns> {
  type: PromoActions.FETCH_CAMPAIGNS_FAIL
}

interface CreateCampaign extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.CREATE_CAMPAIGN
  promoID: number
}

export interface CreateCampaignSuccess
  extends ram.AxiosMiddlewareActionSuccess<CampaignPayload, CreateCampaign> {
  type: PromoActions.CREATE_CAMPAIGN_SUCCESS
}

interface CreateCampaignFail
  extends ram.AxiosMiddlewareActionFail<CreateCampaign> {
  type: PromoActions.CREATE_CAMPAIGN_FAIL
}

interface RemovePromoCampaign extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.REMOVE_PROMO_CAMPAIGN
  promoID: number
  campaignID: number
}

interface RemovePromoCampaignSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, RemovePromoCampaign> {
  type: PromoActions.REMOVE_PROMO_CAMPAIGN_SUCCESS
}

interface RemovePromoCampaignFail
  extends ram.AxiosMiddlewareActionFail<RemovePromoCampaign> {
  type: PromoActions.REMOVE_PROMO_CAMPAIGN_FAIL
}

interface ApproveCampaign extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.APPROVE_CAMPAIGN
  promoID: number
  campaignID: number
}

export interface ApproveCampaignSuccess
  extends ram.AxiosMiddlewareActionSuccess<CampaignPayload, ApproveCampaign> {
  type: PromoActions.APPROVE_CAMPAIGN_SUCCESS
}

interface ApproveCampaignFail
  extends ram.AxiosMiddlewareActionFail<ApproveCampaign> {
  type: PromoActions.APPROVE_CAMPAIGN_FAIL
}

interface ApprovePromo extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.APPROVE_PROMO
  promoID: number
}

export interface ApprovePromoSuccess
  extends ram.AxiosMiddlewareActionSuccess<Promo, ApprovePromo> {
  type: PromoActions.APPROVE_PROMO_SUCCESS
}

export interface ApprovePromoFail
  extends ram.AxiosMiddlewareActionFail<ApprovePromo> {
  type: PromoActions.APPROVE_PROMO_FAIL
}

interface TestEmailTemplate extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.TEST_EMAIL_TEMPLATE
  templateID: number
}

interface TestEmailTemplateSuccess
  extends ram.AxiosMiddlewareActionSuccess<null, TestEmailTemplate> {
  type: PromoActions.TEST_EMAIL_TEMPLATE_SUCCESS
}

export interface TestEmailTemplateFail
  extends ram.AxiosMiddlewareActionFail<TestEmailTemplate> {
  type: PromoActions.TEST_EMAIL_TEMPLATE_FAIL
}

interface UpdateCampaign extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.UPDATE_CAMPAIGN
  promoID: number
  campaignID: number
}

interface UpdateCampaignSuccess
  extends ram.AxiosMiddlewareActionSuccess<CampaignPayload, UpdateCampaign> {
  type: PromoActions.UPDATE_CAMPAIGN_SUCCESS
}

export interface UpdateCampaignFail
  extends ram.AxiosMiddlewareActionFail<UpdateCampaign> {
  type: PromoActions.UPDATE_CAMPAIGN_FAIL
}

interface UpdatePromo extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.UPDATE_PROMO
  promoID: number
}

interface UpdatePromoSuccess
  extends ram.AxiosMiddlewareActionSuccess<Promo, UpdatePromo> {
  type: PromoActions.UPDATE_PROMO_SUCCESS
}

export interface UpdatePromoFail
  extends ram.AxiosMiddlewareActionFail<UpdatePromo> {
  type: PromoActions.UPDATE_PROMO_FAIL
}

interface CreateCohort extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.CREATE_COHORT
  promoID: number
}

export interface CreateCohortSuccess
  extends ram.AxiosMiddlewareActionSuccess<CohortPayload, CreateCohort> {
  type: PromoActions.CREATE_COHORT_SUCCESS
}

interface CreateCohortFail extends ram.AxiosMiddlewareActionFail<CreateCohort> {
  type: PromoActions.CREATE_COHORT_FAIL
}

interface UpdateCohort extends ram.AxiosMiddlewareActionCreator {
  type: typeof PromoActions.UPDATE_COHORT
  promoID: number
  cohortID: number
}

export interface UpdateCohortSuccess
  extends ram.AxiosMiddlewareActionSuccess<CohortPayload, UpdateCohort> {
  type: PromoActions.UPDATE_COHORT_SUCCESS
}

interface UpdateCohortFail extends ram.AxiosMiddlewareActionFail<UpdateCohort> {
  type: PromoActions.UPDATE_COHORT_FAIL
}

interface CancelPromo extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.CANCEL_PROMO
  promoID: number
}

interface CancelPromoSuccess
  extends ram.AxiosMiddlewareActionSuccess<Promo, CancelPromo> {
  type: PromoActions.CANCEL_PROMO_SUCCESS
}

interface CancelPromoFail extends ram.AxiosMiddlewareActionFail<CancelPromo> {
  type: PromoActions.CANCEL_PROMO_FAIL
}

interface FetchCampaignQueueStats extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_CAMPAIGN_QUEUE_STATS
  promoID: number
  campaignID: number
}

interface FetchCampaignQueueStatsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    CampaignSendStat,
    FetchCampaignQueueStats
  > {
  type: PromoActions.FETCH_CAMPAIGN_QUEUE_STATS_SUCCESS
}

interface FetchCampaignQueueStatsFail
  extends ram.AxiosMiddlewareActionFail<FetchCampaignQueueStats> {
  type: PromoActions.FETCH_CAMPAIGN_QUEUE_STATS_FAIL
}

interface FetchPromoSizeEstimate extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_PROMO_SIZE_ESTIMATE
  promoID: number
}

interface FetchPromoSizeEstimateSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    UserListEstimate,
    FetchPromoSizeEstimate
  > {
  type: PromoActions.FETCH_PROMO_SIZE_ESTIMATE_SUCCESS
}

interface FetchPromoSizeEstimateFail
  extends ram.AxiosMiddlewareActionFail<FetchPromoSizeEstimate> {
  type: PromoActions.FETCH_PROMO_SIZE_ESTIMATE_FAIL
}

interface FetchCohortSizeEstimate extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_COHORT_SIZE_ESTIMATE
  promoID: number
  cohortID: number
}

interface FetchCohortSizeEstimateSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    UserListEstimate,
    FetchCohortSizeEstimate
  > {
  type: PromoActions.FETCH_COHORT_SIZE_ESTIMATE_SUCCESS
}

interface FetchCohortSizeEstimateFail
  extends ram.AxiosMiddlewareActionFail<FetchCohortSizeEstimate> {
  type: PromoActions.FETCH_COHORT_SIZE_ESTIMATE_FAIL
}

interface FetchPromoExclusionLists extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_PROMO_EXCLUSION_LISTS
  promoID: number
}

interface FetchPromoExclusionListsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    ExclusionList[],
    FetchPromoExclusionLists
  > {
  type: PromoActions.FETCH_PROMO_EXCLUSION_LISTS_SUCCESS
}

interface FetchPromoExclusionListsFail
  extends ram.AxiosMiddlewareActionFail<FetchPromoExclusionLists> {
  type: PromoActions.FETCH_PROMO_EXCLUSION_LISTS_FAIL
}

interface FetchCampaignDeliveryStats extends ram.AxiosMiddlewareActionCreator {
  type: PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS
  promoID: number
  campaignID: number
}

interface FetchCampaignDeliveryStatsSuccess
  extends ram.AxiosMiddlewareActionSuccess<
    CampaignDeliveryStat,
    FetchCampaignDeliveryStats
  > {
  type: PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS_SUCCESS
}

interface FetchCampaignDeliveryStatsFail
  extends ram.AxiosMiddlewareActionFail<FetchCampaignDeliveryStats> {
  type: PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS_FAIL
}

export const fetchPromos = (): FetchPromos => ({
  type: PromoActions.FETCH_PROMOS,
  payload: {
    request: {
      url: '/v2/mail-promo',
    },
  },
})

export const createPromo = (data: PromoBase): CreatePromo => ({
  type: PromoActions.CREATE_PROMO,
  payload: {
    request: { url: '/v2/mail-promo', method: 'POST', data: { ...data } },
  },
})

export const approvePromo = (promoID: number): ApprovePromo => ({
  type: PromoActions.APPROVE_PROMO,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/approve`,
      method: 'POST',
    },
  },
})

export const updatePromo = (promoID: number, data: PromoBase): UpdatePromo => ({
  type: PromoActions.UPDATE_PROMO,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}`,
      method: 'PATCH',
      data: { ...data },
    },
  },
})

export const fetchPromoCohorts = (promoID: number): FetchPromoCohorts => ({
  type: PromoActions.FETCH_PROMO_COHORTS,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/cohorts`,
    },
  },
})

export const removePromoCohort = (
  promoID: number,
  cohortID: number
): RemovePromoCohort => ({
  type: PromoActions.REMOVE_PROMO_COHORT,
  promoID,
  cohortID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/cohorts/${cohortID}`,
      method: 'DELETE',
    },
  },
})

export const uploadInclusionList = (
  file: File,
  promoID: number,
  excludePurchasers: boolean
): UploadInclusionList => {
  const data = new FormData()
  // FormData.set does not work in ancient Chrome versions
  data.append('file', file)
  data.append('exclude_purchasers', excludePurchasers ? 'true' : 'false')
  return {
    type: PromoActions.UPLOAD_INCLUSION_LIST,
    payload: {
      request: {
        url: `/v2/mail-promo/${promoID}/csv/include`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    },
    file,
  }
}

export const uploadExclusionList = (
  file: File,
  promoID: number
): UploadExclusionList => {
  const data = new FormData()
  // FormData.set does not work in ancient Chrome versions
  data.append('file', file)
  return {
    type: PromoActions.UPLOAD_EXCLUSION_LIST,
    payload: {
      request: {
        url: `/v2/mail-promo/${promoID}/csv/exclude`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    },
    file,
  }
}

export const fetchCampaigns = (promoID: number): FetchCampaigns => ({
  type: PromoActions.FETCH_CAMPAIGNS,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates`,
    },
  },
})

export const createCampaign = (
  promoID: number,
  data: CampaignPayloadBase
): CreateCampaign => ({
  type: PromoActions.CREATE_CAMPAIGN,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates`,
      method: 'POST',
      data: { ...data },
    },
  },
})

export const removePromoCampaign = (
  promoID: number,
  campaignID: number
): RemovePromoCampaign => ({
  type: PromoActions.REMOVE_PROMO_CAMPAIGN,
  promoID,
  campaignID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates/${campaignID}`,
      method: 'DELETE',
    },
  },
})

export const approveCampaign = (
  promoID: number,
  campaignID: number
): ApproveCampaign => ({
  type: PromoActions.APPROVE_CAMPAIGN,
  promoID,
  campaignID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates/${campaignID}/approve`,
      method: 'POST',
    },
  },
})

export const updateCampaign = (
  promoID: number,
  campaignID: number,
  data: UpdateCampaignPayload
): UpdateCampaign => ({
  type: PromoActions.UPDATE_CAMPAIGN,
  promoID,
  campaignID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates/${campaignID}`,
      method: 'PATCH',
      data: { ...data },
    },
  },
})

export const fetchEmailTemplates = (): FetchEmailTemplates => ({
  type: PromoActions.FETCH_EMAIL_TEMPLATES,
  payload: {
    request: {
      url: '/v2/mail-template',
    },
  },
})

export const testEmailTemplate = (
  templateID: number,
  data: TestEmailTemplatePayload
): TestEmailTemplate => ({
  type: PromoActions.TEST_EMAIL_TEMPLATE,
  templateID,
  payload: {
    request: {
      url: `/v2/mail-template/${templateID}/testsend`,
      method: 'POST',
      data: { ...data },
    },
  },
})

export const createCohort = (
  promoID: number,
  data: CohortPayloadBase
): CreateCohort => ({
  type: PromoActions.CREATE_COHORT,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/cohorts`,
      method: 'POST',
      data: { ...data },
    },
  },
})
export const updateCohort = (
  promoID: number,
  cohortID: number,
  data: CohortPayload
): UpdateCohort => ({
  type: PromoActions.UPDATE_COHORT,
  promoID,
  cohortID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/cohorts/${cohortID}`,
      method: 'PUT',
      data: { ...data },
    },
  },
})

export const cancelPromo = (promoID: number): CancelPromo => ({
  type: PromoActions.CANCEL_PROMO,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}`,
      method: 'DELETE',
    },
  },
})

export const fetchCampaignQueueStats = (
  promoID: number,
  campaignID: number
): FetchCampaignQueueStats => ({
  type: PromoActions.FETCH_CAMPAIGN_QUEUE_STATS,
  promoID,
  campaignID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates/${campaignID}/queued`,
    },
  },
})

export const fetchPromoSizeEstimate = (
  promoID: number
): FetchPromoSizeEstimate => ({
  type: PromoActions.FETCH_PROMO_SIZE_ESTIMATE,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/estimate`,
    },
  },
})

export const fetchCohortSizeEstimate = (
  promoID: number,
  cohortID: number
): FetchCohortSizeEstimate => ({
  type: PromoActions.FETCH_COHORT_SIZE_ESTIMATE,
  promoID,
  cohortID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/cohorts/${cohortID}/estimate`,
    },
  },
})

export const fetchPromoExclusionLists = (
  promoID: number
): FetchPromoExclusionLists => ({
  type: PromoActions.FETCH_PROMO_EXCLUSION_LISTS,
  promoID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/csv/exclude`,
    },
  },
})

export const fetchCampaignDeliveryStats = (
  promoID: number,
  campaignID: number
): FetchCampaignDeliveryStats => ({
  type: PromoActions.FETCH_CAMPAIGN_DELIVERY_STATS,
  promoID,
  campaignID,
  payload: {
    request: {
      url: `/v2/mail-promo/${promoID}/dates/${campaignID}/stats`,
    },
  },
})

export type FinishPromoError = ApproveCampaignFail | ApprovePromoFail

export type PromoAction =
  | CreatePromo
  | CreatePromoSuccess
  | CreatePromoFail
  | FetchPromos
  | FetchPromosSuccess
  | FetchPromosFail
  | UploadInclusionList
  | UploadInclusionListSuccess
  | UploadInclusionListFail
  | FetchPromoCohorts
  | FetchPromoCohortsSuccess
  | FetchPromoCohortsFail
  | RemovePromoCohort
  | RemovePromoCohortSuccess
  | RemovePromoCohortFail
  | FetchEmailTemplates
  | FetchEmailTemplatesSuccess
  | FetchEmailTemplatesFail
  | FetchCampaigns
  | FetchCampaignsSuccess
  | FetchCampaignsFail
  | CreateCampaign
  | CreateCampaignSuccess
  | CreateCampaignFail
  | RemovePromoCampaign
  | RemovePromoCampaignSuccess
  | RemovePromoCampaignFail
  | ApproveCampaign
  | ApproveCampaignSuccess
  | ApproveCampaignFail
  | ApprovePromo
  | ApprovePromoSuccess
  | ApprovePromoFail
  | TestEmailTemplate
  | TestEmailTemplateSuccess
  | TestEmailTemplateFail
  | UpdatePromo
  | UpdatePromoSuccess
  | UpdatePromoFail
  | UpdateCampaign
  | UpdateCampaignSuccess
  | UpdateCampaignFail
  | CreateCohort
  | CreateCohortSuccess
  | CreateCohortFail
  | UpdateCohort
  | UpdateCohortSuccess
  | UpdateCohortFail
  | UploadExclusionList
  | UploadExclusionListSuccess
  | UploadExclusionListFail
  | CancelPromo
  | CancelPromoSuccess
  | CancelPromoFail
  | FetchCampaignQueueStats
  | FetchCampaignQueueStatsSuccess
  | FetchCampaignQueueStatsFail
  | FetchPromoSizeEstimate
  | FetchPromoSizeEstimateSuccess
  | FetchPromoSizeEstimateFail
  | FetchCohortSizeEstimate
  | FetchCohortSizeEstimateSuccess
  | FetchCohortSizeEstimateFail
  | FetchPromoExclusionLists
  | FetchPromoExclusionListsSuccess
  | FetchPromoExclusionListsFail
  | FetchCampaignDeliveryStats
  | FetchCampaignDeliveryStatsSuccess
  | FetchCampaignDeliveryStatsFail
