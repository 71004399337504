import { AppThunkAction } from 'store'
import { APIError } from 'utils/axios'
import {
  addStickerPrices,
  createOrUpdateDiscount,
  createUserDiscountToken,
  CreateUserDiscountTokenSuccess,
  deleteDiscountToken,
  deleteStickerPrice,
  deleteUserDiscountToken,
  fetchDiscountBrands,
  fetchDiscounts,
  fetchStickerPrices,
  removeDiscountPlanCodes,
  updateDiscountInfo,
} from './actions'
import { discountTokenWithPaymentPlans } from './selectors'
import {
  DiscountTokenPayload,
  DiscountTokenPayloadBase,
  DiscountTokenTypes,
  PatchDiscountTokenPayload,
  StickerPrice,
  UserDiscountToken,
} from './types'

export const createDiscountThunk =
  (data: DiscountTokenPayload): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(createOrUpdateDiscount([data]))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const updateDiscountThunk =
  (
    data: DiscountTokenPayload,
    discountToken: string
  ): AppThunkAction<null | APIError> =>
  async (dispatch, getState): Promise<null | APIError> => {
    try {
      const patchTokenPayload: PatchDiscountTokenPayload =
        discountToken !== data.discount_token
          ? {
              ...data,
              discount_token: discountToken,
              new_discount_token: data.discount_token, //setting this field allows us to change the discount token name
            }
          : { ...data, discount_token: discountToken }
      const originalToken = discountTokenWithPaymentPlans(
        getState().discounts,
        discountToken
      )
      //updating the top level details of the discount token
      await dispatch(updateDiscountInfo([patchTokenPayload], discountToken))

      if (data.type === DiscountTokenTypes.PerPlanCode) {
        //in order to add new/update existing plan codes we simply call the create endpoint again
        await dispatch(createOrUpdateDiscount([data]))
      }

      //next, check if any plan codes were removed and delete them
      if (originalToken?.discount_plan_codes) {
        const remainingTokens = new Set<string>(
          data.discount_plan_codes.map((plan) => plan.plan_code)
        )
        const planCodesToRemove = originalToken.discount_plan_codes.filter(
          (planCode) => !remainingTokens.has(planCode.plan_code)
        )
        if (planCodesToRemove.length > 0) {
          await dispatch(
            removeDiscountPlanCodes(discountToken, [
              {
                discount_token: discountToken,
                discount_plan_codes: planCodesToRemove,
              },
            ])
          )
        }
      }

      //if the token was changed reload all tokens
      if (discountToken !== data.discount_token) {
        dispatch(fetchDiscounts())
      }

      //finaly, fetch updated brand urls for the updated token
      dispatch(fetchDiscountBrands(data.discount_token))

      return null
    } catch (e) {
      return e as APIError
    }
  }

export const deleteDiscountTokenThunk =
  (discountToken: string): AppThunkAction<null | APIError> =>
  async (dispatch, getState): Promise<null | APIError> => {
    try {
      const originalToken = discountTokenWithPaymentPlans(
        getState().discounts,
        discountToken
      )

      //for global or Single Use tokens, just delete the top level token
      if (
        originalToken?.type === DiscountTokenTypes.Global ||
        originalToken?.type === DiscountTokenTypes.User
      ) {
        await dispatch(
          deleteDiscountToken(discountToken, [
            {
              discount_token: discountToken,
              type: originalToken?.type,
            },
          ])
        )
        return null
      }

      //for per plan code tokens, removeing all the assigned plan codes will have the same effect
      if (
        originalToken?.type === DiscountTokenTypes.PerPlanCode &&
        originalToken?.discount_plan_codes &&
        originalToken.discount_plan_codes.length > 0
      ) {
        await dispatch(
          removeDiscountPlanCodes(discountToken, [
            {
              discount_token: discountToken,
              discount_plan_codes: originalToken.discount_plan_codes,
            },
          ])
        )
      }

      return null
    } catch (e) {
      return e as APIError
    }
  }

export const createUserDiscountThunk =
  (
    discountToken: string,
    data: DiscountTokenPayloadBase
  ): AppThunkAction<UserDiscountToken | null> =>
  async (dispatch) => {
    const token = await dispatch(
      createUserDiscountTokenThunk(discountToken, data)
    )
    if ('anon_token' in token) {
      return token
    }
    return null
  }

export const createUserDiscountTokenThunk =
  (
    discountToken: string,
    data: DiscountTokenPayloadBase
  ): AppThunkAction<UserDiscountToken | APIError> =>
  async (dispatch): Promise<UserDiscountToken | APIError> => {
    try {
      const result = (await dispatch(
        createUserDiscountToken(discountToken, [data])
      )) as unknown as CreateUserDiscountTokenSuccess
      return result.payload.data[0]
    } catch (e) {
      return e as APIError
    }
  }

export const deleteUserDiscountThunk =
  (
    discountToken: string,
    userDiscountToken: string
  ): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(deleteUserDiscountToken(discountToken, userDiscountToken))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const deleteStickerPriceThunk =
  (planCode: string): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(deleteStickerPrice(planCode))
      dispatch(fetchStickerPrices)
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const addStickerPricesThunk =
  (stickerPrices: StickerPrice[]): AppThunkAction<null | APIError> =>
  async (dispatch): Promise<null | APIError> => {
    try {
      await dispatch(addStickerPrices(stickerPrices))
      return null
    } catch (e) {
      return e as APIError
    }
  }

export const addStickerPricesAndRefresh =
  (stickerPrices: StickerPrice[]): AppThunkAction<APIError | null> =>
  async (dispatch) => {
    const result = await dispatch(addStickerPricesThunk(stickerPrices))
    if (!result) {
      dispatch(fetchStickerPrices())
      return null
    }
    return result
  }

export const deleteStickerPricesAndRefresh =
  (planCode: string): AppThunkAction<APIError | null> =>
  async (dispatch) => {
    const result = await dispatch(deleteStickerPriceThunk(planCode))
    if (!result) {
      dispatch(fetchStickerPrices())
      return null
    }
    return result
  }
