import DiscountsContainer from 'components/Discounts/DiscountsContainer'
import PageShell from 'pages/PageShell'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DiscountsPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <PageShell pageTitle={t('pages.Discounts.pageTitle')}>
      <DiscountsContainer />
    </PageShell>
  )
}

export default DiscountsPage
