import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import useAlerts from 'hooks/useAlerts'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Grid from '@material-ui/core/Grid'

import FormModalWrapper from 'components/shared/FormModalWrapper'
import Loader from 'components/shared/Loader'

import { AppState } from 'store'
import {
  DiscountPaymentPlan,
  DiscountToken,
  DiscountTokenPayloadBase,
  UserDiscountToken,
} from 'store/discounts/types'
import {
  discountLoadedOnce,
  discountTokenWithPaymentPlans,
  generatedUserTokens,
} from 'store/discounts/selectors'

import { fetchDiscount } from 'store/discounts/actions'
import {
  createUserDiscountThunk,
  deleteUserDiscountThunk,
} from 'store/discounts/thunk'
import { tryGetErrorMessage } from 'utils/errors'

interface Props {
  discountToken: string | null
  onClose: () => void
}
const AddUserTokenModalContainer: React.FC<Props> = ({
  discountToken,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const tRef = 'components.Discounts.AddUserTokenModal'

  const { infoAlert, errorAlert } = useAlerts()
  const [generatedCode, setGeneratedCode] = useState('')

  const loadedOnceDiscount = useSelector<AppState, boolean>((state) =>
    discountLoadedOnce(state.discounts, discountToken)
  )

  const discount = useSelector<AppState, DiscountToken | null>(
    (state) => discountTokenWithPaymentPlans(state.discounts, discountToken),
    isEqual
  )

  const userDiscountTokens = useSelector<AppState, string[]>(
    (state) => generatedUserTokens(state.discounts, discountToken),
    isEqual
  )

  useEffect(() => {
    if (!loadedOnceDiscount && discountToken) {
      dispatch(fetchDiscount(discountToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountToken, loadedOnceDiscount])

  const handleClose = useCallback(async () => {
    setGeneratedCode('')
    onClose()
  }, [onClose])

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generatedCode)
    infoAlert('Copied to clipboard!')
  }, [generatedCode, infoAlert])

  const getDiscountAmount = (discount: DiscountToken | null) => {
    if (discount && discount.discount_percent && discount.discount_percent > 0)
      return `${discount.discount_percent * 100}%`
    if (discount && discount.discount_plan_codes) {
      const sortedPlanCodes = discount.discount_plan_codes
        .slice()
        .sort((a, b) => {
          if (!a.discount_value || !b.discount_value) return 0
          return a.discount_value - b.discount_value
        })
      const minValue = sortedPlanCodes[0].discount_value || 0
      const maxValue =
        sortedPlanCodes[sortedPlanCodes.length - 1].discount_value || 0
      if (minValue === maxValue) {
        return `$${minValue}`
      }
      return `$${minValue} - $${maxValue}`
    }
    return ''
  }

  const onGenerateTokenClick = useCallback(async () => {
    if (discount) {
      const payload: DiscountTokenPayloadBase = {
        discount_token: discount.discount_token,
        discount_plan_codes: discount.discount_plan_codes || [],
      }
      const result = await dispatch(
        createUserDiscountThunk(discount.discount_token, payload)
      )
      if (result) {
        setGeneratedCode((result as unknown as UserDiscountToken).anon_token)
        infoAlert('User Discount Token created!')
        return
      }
      errorAlert(
        'Failed to create User Discount Token: ' + tryGetErrorMessage(result)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, infoAlert, errorAlert])

  const onDeleteTokenClick = useCallback(
    async (userDiscountToken: string) => {
      if (discountToken) {
        const result = await dispatch(
          deleteUserDiscountThunk(discountToken, userDiscountToken)
        )
        if (!result) {
          infoAlert('User Discount Token deleted!')
          if (userDiscountToken === generatedCode) {
            setGeneratedCode('')
          }
          return
        }
        errorAlert(
          'Failed to delete User Discount Token: ' + tryGetErrorMessage(result)
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoAlert, errorAlert, discountToken, generatedCode]
  )

  const showLoader = discount && !loadedOnceDiscount

  if (!discountToken) return null
  return (
    <FormModalWrapper
      onClose={handleClose}
      open={true}
      title={t(`${tRef}.title`)}
      disableFullWidth={true}
    >
      {showLoader && <Loader />}
      {!showLoader && discount && (
        <AddUserTokenModal
          onGenerateClick={onGenerateTokenClick}
          discountAmount={getDiscountAmount(discount)}
          paymentPlans={discount.discount_payment_plans || []}
          discountToken={discountToken || ''}
          generatedToken={generatedCode}
          daysToLive={discount.days_to_live || 0}
          recentlyGeneratedTokens={userDiscountTokens}
          onCopyToClipboardClick={copyToClipboard}
          onDeleteTokenClick={onDeleteTokenClick}
        />
      )}
    </FormModalWrapper>
  )
}

interface ModalProps {
  discountToken: string
  generatedToken: string
  recentlyGeneratedTokens: string[]
  daysToLive: number
  discountAmount: string
  onGenerateClick: () => void
  paymentPlans: DiscountPaymentPlan[]
  onCopyToClipboardClick: () => void
  onDeleteTokenClick: (token: string) => void
}

const AddUserTokenModal: React.FC<ModalProps> = ({
  discountToken,
  generatedToken,
  recentlyGeneratedTokens,
  daysToLive,
  discountAmount,
  onGenerateClick,
  paymentPlans,
  onCopyToClipboardClick,
  onDeleteTokenClick,
}) => {
  const classes = useStyles()
  const tRef = 'components.Discounts.AddUserTokenModal'
  const { t } = useTranslation()

  return (
    <Box className={classes.formContainer}>
      <Container disableGutters className={classes.formGroup}>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.fields.discountCode.label`)}</InputLabel>
          <Typography variant="body2" className={classes.fieldData}>
            {discountToken}
          </Typography>
        </Box>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.fields.discountAmount.label`)}</InputLabel>
          <Typography variant="body2" className={classes.fieldData}>
            {discountAmount}
          </Typography>
        </Box>
        <Box className={classes.fieldContainer}>
          <InputLabel>{t(`${tRef}.fields.expires.label`)}</InputLabel>
          <Typography variant="body2" className={classes.fieldData}>
            {t(`${tRef}.fields.expires.value`, { daysToLive })}
          </Typography>
        </Box>
      </Container>

      <Container disableGutters className={classes.generateRow}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={onGenerateClick}
        >
          {t(`${tRef}.generate`)}
        </Button>
        <TextField disabled={true} value={generatedToken} />
        {generatedToken !== '' && (
          <IconButton onClick={onCopyToClipboardClick}>
            <FileCopyIcon />
          </IconButton>
        )}
      </Container>

      {recentlyGeneratedTokens.length > 0 && (
        <Accordion className={classes.accordian}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">
              {t(`${tRef}.recentlyGenerated`, {
                number: recentlyGeneratedTokens.length,
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid className={classes.grid}>
              {recentlyGeneratedTokens.map((token) => (
                <Box key={token}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.selectedItemsRow}
                  >
                    <Grid container item xs={9}>
                      <Typography
                        variant="body2"
                        className={classes.planNameResult}
                      >
                        {token}
                      </Typography>
                    </Grid>
                    <Grid container item xs={3}>
                      <Button
                        variant="text"
                        color="secondary"
                        className={classes.addButton}
                        onClick={() => onDeleteTokenClick(token)}
                      >
                        {t(`${tRef}.delete`)}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {paymentPlans && (
        <Accordion className={classes.accordian}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">
              {t(`${tRef}.affectedPlanCodes`, {
                number: paymentPlans.length,
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid className={clsx(classes.grid, classes.scrollable)}>
              {paymentPlans.map((price) => (
                <Box key={price.plan_code}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.selectedItemsRow}
                  >
                    <Grid container item xs={9}>
                      <Typography
                        variant="body2"
                        className={classes.planNameResult}
                      >
                        {price.plan_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.planCodeResult}
                      >
                        {`(${price.plan_code})`}
                      </Typography>
                    </Grid>
                    <Grid container item xs={3}>
                      <Typography
                        variant="body2"
                        className={classes.priceResult}
                      >
                        {`$${price.sticker_price} (${price.currency_code})`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      marginTop: '1rem',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      marginBottom: '1rem',
    },
    generateRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    button: {
      marginRight: '1rem',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    fieldData: {
      fontWeight: 'bold',
      marginTop: '0.5rem',
    },
    accordian: {
      marginBottom: '1rem',
      width: '40rem',
    },
    planNameResult: {
      marginRight: '0.25rem',
      fontSize: '1rem',
    },
    planCodeResult: {
      marginRight: '1rem',
    },
    selectedItemsRow: {
      alignItems: 'center',
    },
    grid: {
      width: '100%',
    },
    priceResult: {
      fontWeight: 'bold',
    },
    addButton: {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      height: '1.5rem',
    },
    scrollable: {
      maxHeight: '10rem',
      overflow: 'auto',
    },
  })
)

export default AddUserTokenModalContainer
