import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface Props {
  variant?: 'contained' | 'text' | 'outlined'
  color?: 'primary' | 'secondary'
  className?: string
  disabled?: boolean
  onClick?: () => void
  loading: boolean
  type?: 'submit'
}

const LoaderButton: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles()
  return (
    <Button
      variant={props.variant}
      color={props.color}
      className={props.className}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      endIcon={
        props.loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )
      }
      type={props.type}
    >
      {props.children}
    </Button>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonProgress: {
      color: theme.palette.secondary.main,
    },
  })
)

export default LoaderButton
