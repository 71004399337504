import React from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import LightToolTip from 'components/shared/LightToolTip'

import { CampaignSendStat } from 'store/promo/types'

interface Props {
  stats: CampaignSendStat[]
  title: string
}

const SendStatsList: React.FC<Props> = ({ stats, title }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const tRef = 'pages.Campaigns.sections.promos.SendStatsToolTip'
  return (
    <Box>
      <Typography variant="h2" className={classes.headerText}>
        {title}
      </Typography>
      {stats.map((stat) => (
        <Box key={stat.templateName}>
          {stat.message_counts && (
            <Container disableGutters className={classes.container}>
              <Typography variant="h3" className={classes.headerText}>
                {stat.templateName || ''}
              </Typography>
              <Grid container direction="column" className={classes.grid}>
                <Grid container item direction="row" xs={12}>
                  <Grid item xs={7}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.status, classes.completedText)}
                    >
                      {t(`${tRef}.ready`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      className={classes.completedText}
                    >
                      {stat.message_counts.good}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.status, classes.completedText)}
                    >
                      {t(`${tRef}.inFlight`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      className={classes.completedText}
                    >
                      {stat.message_counts.inflight}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body2"
                      className={clsx(classes.status, classes.completedText)}
                    >
                      {t(`${tRef}.delayed`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      className={classes.completedText}
                    >
                      {stat.message_counts.delayed}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          )}
        </Box>
      ))}
    </Box>
  )
}

const SendStatsTooltip: React.FC<Props> = ({ title, stats, children }) => {
  return (
    <LightToolTip title={<SendStatsList stats={stats} title={title} />}>
      <div>{children}</div>
    </LightToolTip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      fontWeight: 450,
    },
    completedText: {
      color: theme.palette.secondary.main,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    grid: {
      marginLeft: '1rem',
    },
    headerText: {
      color: colors.gray[400],
    },
  })
)

export default SendStatsTooltip
